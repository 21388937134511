import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState, useRef, useEffect } from "react";
import {
  FiPlus,
  FiCamera,
  FiUploadCloud,
  FiRefreshCw,
  FiLoader,
} from "react-icons/fi";
import Webcam from "react-webcam";
import AWS from "aws-sdk";
import { Toast } from "primereact/toast";
import axios from "axios";
import Cookies from "js-cookie";
import RecognitionList from "./components/RecognitionList";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: process.env.REACT_APP_REGION,
});

const s3 = new AWS.S3();

const Recognition = () => {
  const [dialog, setDialog] = useState(false);
  const [image, setImage] = useState(null); // State to store the captured image
  const [imageLink, setImageLink] = useState("");
  const [data, setData] = useState([]);
  const [processor, setProcessor] = useState(false);
  const [list, setList] = useState([]);
  const webcamRef = useRef(null); // Ref to access the webcam
  const toastRef = useRef(null);
  const user_uuid = Cookies.get("user_id");

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
    setImage(null);
    setData([]);
    setProcessor(false);
  };

  const [error, setError] = useState(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const uploadImage = () => {
    setProcessor(true);
    if (!image) return;
    setError(null);

    const blob = dataURItoBlob(image);
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: `${Date.now()}.png`,
      Body: blob,
      ContentType: "image/png",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        setError(
          "Failed to upload image. Please check the CORS configuration and bucket permissions."
        );
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: err, // Use err.message to display the error
          life: 3000,
        });
      } else {
        // Make the API call using Axios in the else block
        setImageLink(data.Location);
      }
    });
  };

  useEffect(() => {
    if (imageLink) {
      axios
        .post(`${process.env.REACT_APP_AWS_URL1}/driverRegister`, {
          first_name: data.first_name,
          last_name: data.last_name,
          S3URL: imageLink,
          user_uuid: user_uuid,
        })
        .then((response) => {
          if (response.status === 200) {
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: "Data uploaded successfully",
              life: 3000,
            });
            setProcessor(false);
            // Close the dialog
            closeDialog();
          } else {
            throw new Error("API call failed with status: " + response.status);
          }
        })
        .catch(() => {
          // Handle API call errors
          setError("Failed to process the image upload. Please try again.");
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: error, // Use error.message to display the error
            life: 3000,
          });
        });
    }
  }, [imageLink]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/driverlist/${user_uuid}`)
      .then((res) => {
        const formattedData = res.data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
          key: index + 1,
        }));
        setList(formattedData);
      })
      .catch((err) => {
        console.log("err:::", err);
      });
  }, [processor]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const dialogHeader = (
    <div className="text-xl font-semibold">Driver Details</div>
  );

  const dialogFooter = (
    <div className="mt-3 flex justify-end space-x-2">
      <button
        className="flex items-center rounded-lg bg-gray-700 px-4 py-1 text-white transition duration-300 hover:bg-gray-600"
        onClick={closeDialog}
      >
        Close
      </button>
      <button
        className={`${
          !data.first_name && !data.last_name && !image
            ? "cursor-not-allowed bg-gray-600"
            : "bg-green-500 hover:bg-green-600"
        } flex items-center rounded-lg px-4 py-1  text-white transition duration-300`}
        onClick={uploadImage}
        disabled={!data?.first_name && !data?.last_name && !image}
      >
        {processor ? (
          <>
            Uploading... <FiLoader className="inline-block animate-spin" />
          </>
        ) : (
          <>
            <FiUploadCloud className="mr-2" />
            Upload
          </>
        )}
      </button>
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />

      <div className="flex items-center justify-between rounded-lg p-4">
        <h4 className="text-xl font-semibold text-gray-800">
          Driver Recognition
        </h4>
        <button
          className="mt-0.5 flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog}
          aria-label="Calibrate Driver Recognition"
        >
          <FiPlus className="mr-2" />
          Add Driver
        </button>
      </div>
      <RecognitionList data={list} />
      <Dialog
        visible={dialog}
        onHide={closeDialog}
        style={{ width: "60vw", height: "auto" }} // Increased width to accommodate the camera
        header={dialogHeader}
        footer={dialogFooter}
        className="rounded-lg shadow-lg"
      >
        <div className="flex flex-col items-center space-y-4">
          <div className="flex w-full justify-between space-x-4">
            <InputText
              placeholder="Enter driver's first name"
              className="w-full rounded-lg border py-1 pl-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="first_name"
              onChange={handleChange}
            />
            <InputText
              placeholder="Enter driver's last name"
              className="w-full rounded-lg border py-1 pl-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="last_name"
              onChange={handleChange}
            />
          </div>
          {!image ? ( // Show the webcam if no image is captured
            <>
              <div className="relative w-full max-w-lg  border-2 border-dashed border-gray-300 py-4">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="80%"
                  height="auto"
                  className="mx-auto"
                />
                <button
                  className="absolute bottom-4 left-1/2 flex -translate-x-1/2 transform items-center rounded-lg bg-green-500 px-2 py-1 text-white transition duration-300 hover:bg-green-600"
                  onClick={capture}
                >
                  <FiCamera className="mr-2" />
                  Capture Image
                </button>
              </div>
              <p>OR</p>
              <div className="relative w-full max-w-lg">
                <input
                  type="file"
                  name="image"
                  id="image"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        setImage(event.target.result);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                <label
                  htmlFor="image"
                  className="flex w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 py-2 text-white transition duration-300 hover:bg-blue-600"
                >
                  <FiUploadCloud className="mr-2" />
                  Upload Image
                </label>
              </div>
            </>
          ) : (
            // Show the captured image
            <>
              <div className="relative w-full max-w-lg">
                <img
                  src={image}
                  alt="Captured"
                  className="w-full rounded-lg shadow-md"
                />
                <button
                  className="absolute bottom-4 left-1/2 flex -translate-x-1/2 transform items-center rounded-lg bg-blue-500 px-4 py-2 text-white transition duration-300 hover:bg-blue-600"
                  onClick={() => setImage(null)} // Option to retake the image
                >
                  <FiRefreshCw className="mr-2" />
                  Retake
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default Recognition;
