import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import Cookies from "js-cookie";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { FiAlertCircle, FiLoader, FiSearch } from "react-icons/fi";
import { AppContext } from "context/AppContext";
import { CiCircleInfo } from "react-icons/ci";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

const Generate = ({ close }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const token = Cookies.get("token");
  const user_uuid = Cookies.get("user_uuid");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [titleValid, setTitleValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);
  const [vehicleValid, setVehicleValid] = useState(true);
  const [contactValid, setContactValid] = useState(true);
  const toastRef = useRef(null);
  const [selectedContactsName, setSelectedContactNames] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loader, setLoader] = useState(false);
  const { updateReports } = useContext(AppContext);
  const [filteredVehicles, setFilteredVehicles] = useState(null);
  const [vehicleSearchTerm, setVehicleSearchTerm] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicles(res.data.data);
        setFilteredVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const today = new Date();

    // Validate title
    if (title.trim() === "") {
      setTitleValid(false);
      setLoader(false);
      return;
    } else {
      setTitleValid(true);
    }

    // Validate and convert start and end dates with time to epoch
    const startDate = selectedStartDate ? new Date(selectedStartDate) : null;
    const endDate = selectedEndDate ? new Date(selectedEndDate) : null;

    // Validate date selection
    if (!startDate || !endDate) {
      setStartDateValid(!startDate);
      setEndDateValid(!endDate);
      toastRef.current.show({
        severity: "warn",
        summary: "Invalid Dates",
        detail: "Please select both start and end dates and times.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    // Validate date range
    if (startDate > endDate) {
      setStartDateValid(false);
      setEndDateValid(false);
      toastRef.current.show({
        severity: "error",
        summary: "Invalid Date Range",
        detail: "Start date and time cannot be after end date and time.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    // Check if dates are in the future
    if (endDate > today) {
      setEndDateValid(false);
      toastRef.current.show({
        severity: "warn",
        summary: "Invalid End Date",
        detail: "End date and time cannot be in the future.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    // Validate date range within 30 days
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 30) {
      setEndDateValid(false);
      toastRef.current.show({
        severity: "error",
        summary: "Invalid Date Range",
        detail: "Date range cannot exceed 30 days.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    // Convert dates to epoch timestamps (including time)
    const startEpoch = Math.floor(startDate.getTime() / 1000);
    const endEpoch = Math.floor(endDate.getTime() / 1000);

    // Validate vehicle and contacts
    if (!selectedVehicle) {
      setVehicleValid(false);
      toastRef.current.show({
        severity: "warn",
        summary: "Vehicle Selection",
        detail: "Please select a vehicle.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    if (!selectedContacts || selectedContacts.length === 0) {
      setContactValid(false);
      toastRef.current.show({
        severity: "warn",
        summary: "Contact Selection",
        detail: "Please select at least one recipient.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    // Proceed with report generation
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/insertReport`,
        {
          title: title,
          fromDate: startEpoch,
          toDate: endEpoch,
          vehicle_id: selectedVehicle,
          events: [],
          contact: selectedContacts,
          reports_type: 0,
          report_schedule_type: 0,
          user_uuid: user_uuid,
        },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        close();
        updateReports();
        setLoader(false);
        // Optionally open report or handle success
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to generate report";
        toastRef.current.show({
          severity: "error",
          summary: "Report Generation Error",
          detail: errorMessage,
          life: 3000,
        });
        setLoader(false);
      });
  };

  const vehicleOptions = () => {
    if (!vehicles) return [];

    return vehicles
      .filter(
        (vehicle) =>
          vehicle.vehicle_name
            .toLowerCase()
            .includes(vehicleSearchTerm.toLowerCase()) ||
          vehicle.vehicle_reg_number
            .toLowerCase()
            .includes(vehicleSearchTerm.toLowerCase())
      )
      .map((el) => ({
        key: el.vehicle_id,
        label: el.vehicle_name + " [" + el.vehicle_reg_number + "]",
        value: el.vehicle_id,
      }));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getContact`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setContacts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const contactOptions = () => {
    const optionsArray = [];
    const emailContacts = contacts?.filter(
      (item) => item.contact_type === "Email"
    );
    emailContacts?.forEach((el) => {
      optionsArray.push({
        key: el.contact_id,
        label: el.contact_name,
        code: el.contact_id,
      });
    });
    return optionsArray;
  };

  const panelHeader = (
    <div className="border-b border-gray-200 px-3 py-2">
      <div className="relative">
        <FiSearch className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400" />
        <InputText
          value={vehicleSearchTerm}
          onChange={(e) => setVehicleSearchTerm(e.target.value)}
          placeholder="Search vehicles..."
          className="w-full rounded-md border border-gray-200 bg-gray-50 py-2 pl-9 pr-4 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );

  const customItemTemplate = (option) => {
    return (
      <div className="border-b border-gray-100 px-4 py-3 last:border-b-0 hover:bg-gray-50">
        <div className="text-sm text-gray-700">{option.label}</div>
      </div>
    );
  };

  const customFilterFunction = (option, filterValue) => {
    if (filterValue.trim().length === 0) {
      return true;
    }

    const filterText = filterValue.toLowerCase();
    return (
      option.label.toLowerCase().includes(filterText) ||
      option.year.toString().includes(filterText)
    );
  };

  return (
    <div className="shadow-1xl mx-auto max-w-xl space-y-6 rounded-2xl bg-white p-6">
      <Toast ref={toastRef} className="toast-custom" position="top-right" />

      <div className="flex items-center justify-between border-b pb-4">
        <h2 className="text-2xl font-bold text-gray-800">Generate Report</h2>
        <div className="flex items-center text-sm text-red-500">
          <FiAlertCircle className="mr-2" />
          All Fields Required
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          {/* Title Input */}
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Report Title
            </label>
            <InputText
              id="title"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleValid(e.target.value.trim() !== "");
              }}
              className={`h-11 w-full rounded-lg border px-3 text-sm focus:outline-none focus:ring-2 ${
                !titleValid
                  ? "border-red-500 focus:ring-red-200"
                  : "border-gray-300 focus:border-blue-500 focus:ring-blue-200"
              }`}
              placeholder="Enter report title"
            />
            {!titleValid && (
              <p className="mt-1 text-xs text-red-500">Title is required</p>
            )}
          </div>

          {/* Date Selections */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                From (Date and Time)
              </label>
              <Calendar
                inputId="start_date"
                value={selectedStartDate}
                onChange={(e) => {
                  setSelectedStartDate(e.value);
                  setStartDateValid(!!e.value);
                }}
                showTime
                showSeconds
                hourFormat="24"
                dateFormat="dd/mm/yy"
                className={`h-11 w-full rounded-lg border px-3 py-2 text-sm ${
                  !startDateValid
                    ? "border-red-500 focus:ring-red-200"
                    : "border-gray-300 focus:border-blue-500 focus:ring-blue-200"
                }`}
                placeholder="Select start date"
              />
              {!startDateValid && (
                <p className="mt-1 text-xs text-red-500">
                  Start date is required
                </p>
              )}
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                To (Date and Time)
              </label>
              <Calendar
                inputId="end_date"
                value={selectedEndDate}
                onChange={(e) => {
                  setSelectedEndDate(e.value);
                  setEndDateValid(!!e.value);
                }}
                showTime
                showSeconds
                hourFormat="24"
                dateFormat="dd/mm/yy"
                className={`h-11 w-full rounded-lg border px-3 py-2 text-sm ${
                  !endDateValid
                    ? "border-red-500 focus:ring-red-200"
                    : "border-gray-300 focus:border-blue-500 focus:ring-blue-200"
                }`}
                placeholder="Select end date"
              />
              {!endDateValid && (
                <p className="mt-1 text-xs text-red-500">
                  End date is required
                </p>
              )}
            </div>
          </div>

          {/* Vehicle and Contact Selection */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Select Vehicle
              </label>
              <Dropdown
                value={selectedVehicle}
                options={vehicleOptions()}
                onChange={(e) => {
                  setSelectedVehicle(e.value);
                  setVehicleValid(!!e.value);
                }}
                optionLabel="label"
                placeholder="Choose Vehicle"
                className={`h-11 w-full rounded-lg border px-3 text-sm ${
                  !vehicleValid
                    ? "border-red-500 focus:ring-red-200"
                    : "border-gray-300 focus:border-blue-500 focus:ring-blue-200"
                }`}
                panelClassName="rounded-md shadow-lg border border-gray-200"
                itemTemplate={customItemTemplate}
                panelHeader={panelHeader}
                showClear
                filter
                filterBy="label"
                filterMatchMode="contains"
                filterFunction={customFilterFunction}
                filterPlaceholder="Search vehicles..."
                filterInputAutoFocus
              />
              {!vehicleValid && (
                <p className="mt-1 text-xs text-red-500">Vehicle is required</p>
              )}
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Select Recipients
              </label>
              <MultiSelect
                value={selectedContactsName}
                options={contactOptions()}
                onChange={(e) => {
                  setSelectedContactNames(e.value);
                  setSelectedContacts(e.target.value.map((ele) => ele.code));
                  setContactValid(e.target.value.length > 0);
                }}
                selectAllLabel="Select All Contacts"
                optionLabel="label"
                placeholder="Choose Recipients"
                className={`h-11 w-full rounded-lg border px-3 text-sm ${
                  !contactValid
                    ? "border-red-500 focus:ring-red-200"
                    : "border-gray-300 focus:border-blue-500 focus:ring-blue-200"
                }`}
              />
              {!contactValid && (
                <p className="mt-1 text-xs text-red-500">
                  Recipient is required
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            disabled={loader}
            className={`flex h-11 w-40 items-center justify-center rounded-lg text-sm font-semibold transition-all duration-300 ease-in-out ${
              loader
                ? "cursor-not-allowed bg-gray-300 text-gray-500"
                : "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            }`}
          >
            {loader ? (
              <>
                Generating <FiLoader className="ml-2 animate-spin" />
              </>
            ) : (
              "Generate Report"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Generate;
