import Cookies from "js-cookie";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useState, useRef } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { Toast } from "primereact/toast";
import { MdDeleteOutline } from "react-icons/md";
import { TabPanel, TabView } from "primereact/tabview";
import InactivityTriggerList from "./InactivityTriggerList";
import GeofenceList from "./GeofenceList";
import { Badge } from "primereact/badge";

const AlertTriggerList = ({
  triggersData,
  geofenceData,
  anyChange,
  inacData,
  contactsData,
  vehiData,
  setAnyChange,
}) => {
  const toastRef = useRef(null);
  const token = Cookies.get("token");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [triggerName, setTriggerName] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="my-2 flex justify-end">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEdit
          title="Edit"
          onClick={() => openEditDialog(rowData)}
          className="mr-2 cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.trigger_status === 2
              ? openDeleteDialog(rowData)
              : console.log();
          }}
          className={`mx-2 cursor-pointer ${
            rowData.trigger_status === 2 ? "text-red-600" : "text-gray-500"
          }`}
        />
      </div>
    );
  };

  //dialog functions

  const openEditDialog = (rowData) => {
    setEditData(rowData);
    setEditVisible(true);
  };

  const closeEditDialog = () => {
    setEditVisible(false);
  };

  const openDeleteDialog = (rowData) => {
    setDeleteId(rowData?.GeofenceData?.trigger_id);
    setTriggerName(rowData?.GeofenceData?.trigger_name);
    setDeleteVisible(true);
  };

  const closeDeleteDialog = () => {
    setDeleteVisible(false);
  };

  //options
  const statusOptions = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Deactive",
      value: 2,
    },
  ];

  const renderStatusCell = (rowData) => {
    const tagValue = rowData?.trigger_status === 1 ? "Active" : "Deactive";
    const tagSeverity = rowData?.trigger_status === 1 ? "success" : "danger";

    return <Tag value={tagValue} severity={tagSeverity} />;
  };

  const handleChange = (name, value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));
  };

  const vehiclesOptions = () => {
    return vehiData?.map((el) => ({
      label: el.vehicle_name,
      value: el.vehicle_id,
    }));
  };

  const contactsOptions = () => {
    let conatcts = contactsData?.filter(
      (item) => item.contact_type === "Phone"
    );
    return conatcts?.map((el) => ({
      label: el.contact_name,
      value: el.contact_info,
    }));
  };

  const emailOptions = () => {
    let conatcts = contactsData?.filter(
      (item) => item.contact_type === "Email"
    );
    return conatcts?.map((el) => ({
      label: el.contact_name,
      value: el.contact_info,
    }));
  };

  //handle Submit function

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      trigger_name: editData.trigger_name,
      trigger_description: editData.GeofenceData.trigger_description,
      vehicle_id: editData.vehicle_id,
      trigger_type: editData.trigger_type,
      phone: editData.phone,
      email: editData.email,
      trigger_status: editData.trigger_status,
      trigger_sub: editData.trigger_sub || "",
      geofence: editData.geofence || "",
    };

    if (editData) {
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL1}/updateAlertTrigger/${editData?.GeofenceData?.trigger_id}`,
          data,
          {
            headers: { Authorization: token },
          }
        )
        .then((res) => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 3000,
          });
          setAnyChange(!anyChange);
          closeEditDialog();
        })
        .catch((err) => {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail:
              `Trigger ${editData.trigger_name} failed to edit` ||
              err.response?.data.message,
            life: 3000,
          });
        });
    }
  };

  const mapVehicleName = (vehicleUuid) => {
    const vehicle = vehiData?.find((v) => v.vehicle_id === vehicleUuid);
    return vehicle ? vehicle.vehicle_name : "";
  };

  const handleDelete = () => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/deleteAlertTriggers/${deleteId}`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail:
            `Trigger ${triggerName} deleted successfully!` || res.data.message,
          life: 3000,
        });
        setAnyChange(!anyChange);
        setDeleteVisible(false);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail:
            `Trigger ${triggerName} failed to delete` ||
            err.response?.data.message,
          life: 3000,
        });
        setDeleteVisible(false);
      });
  };

  const heading1 = () => {
    return (
      <p className="flex items-center dark:text-gray-950 sm-max:ml-16">
        Alert Triggers&nbsp;
        <Badge
          className="bg-blue-500 text-gray-100"
          value={triggersData.length}
        ></Badge>
      </p>
    );
  };

  const heading2 = () => {
    return (
      <p className="dark:text-gray-950">
        Inactivity Triggers&nbsp;
        <Badge
          className="bg-blue-500 text-gray-100"
          value={inacData.length}
        ></Badge>
      </p>
    );
  };

  const heading3 = () => {
    return (
      <p className="dark:text-gray-950">
        Geofence Triggers&nbsp;
        <Badge
          className="bg-blue-500 text-gray-100"
          value={geofenceData.length}
        ></Badge>
      </p>
    );
  };

  return (
    <div>
      <Toast ref={toastRef} position="top-right" />
      <TabView className="mt-4  rounded-[20px] bg-gray-300">
        <TabPanel header={heading1()} className="">
          <DataTable
            value={triggersData}
            paginator
            dataKey="GeofenceData.trigger_id"
            header={header}
            rows={20}
            removableSort
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[20, 35, 50]}
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              "trigger_name",
              "trigger_type",
              "vehicle_name",
              "phone",
              "email",
              "vehicle_reg_number",
            ]}
            emptyMessage="No alert triggers found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            <Column
              field="serialNo"
              header="#"
              className="border-b pl-5 text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{
                minWidth: "4rem",
                paddingTop: "5px",
                paddingLeft: "2rem",
                paddingBottom: "5px",
              }}
              bodyStyle={{
                textAlign: "left",
                overflow: "visible",
                paddingTop: "0px",
                paddingLeft: "2rem",
                paddingBottom: "0px",
              }}
            ></Column>
            <Column
              field="trigger_name"
              header="Trigger Name"
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "8rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
              body={(rowData) => (
                <>
                  {rowData?.trigger_name?.charAt(0).toUpperCase() +
                    rowData?.trigger_name?.slice(1)}
                </>
              )}
            ></Column>
            <Column
              field="trigger_type"
              header="Trigger Type"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "8rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></Column>
            <Column
              field="contacts"
              header="Phone"
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "8rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
              body={(rowData) => {
                return (
                  <>
                    <Tag
                      className="my-0.5 bg-gray-200 py-0 font-semibold text-gray-800"
                      icon="pi pi-phone"
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>{rowData.phone}</span>
                    </Tag>
                  </>
                );
              }}
            />
            <Column
              field="contacts"
              header="Email"
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
              body={(rowData) => {
                return (
                  <>
                    <Tag
                      className="my-1 bg-gray-200 py-0 font-semibold text-gray-800"
                      icon="pi pi-envelope"
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>{rowData.email}</span>
                    </Tag>
                  </>
                );
              }}
            />
            <Column
              field="trigger_status"
              header="Status"
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "5rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
              body={renderStatusCell}
            ></Column>
            <Column
              field="vehicle_name"
              header="Vehicle Name"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
            />
            <Column
              field="vehicle_reg_number"
              header="Registration No."
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "8rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></Column>
            <Column
              body={actionBodyTemplate}
              header="Action"
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "8rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></Column>
          </DataTable>
        </TabPanel>
        <TabPanel header={heading2()}>
          <InactivityTriggerList inacData={inacData} />
        </TabPanel>
        {/* <TabPanel header={heading3()}>
          <GeofenceList
            data={geofenceData}
            mapVehicleName={mapVehicleName}
            vehiclesOptions={vehiclesOptions()}
            statusOptions={statusOptions}
            contactsOptions={contactsOptions}
            emailOptions={emailOptions}
            setAnyChange={setAnyChange}
            anyChange={anyChange}
          />
        </TabPanel> */}
      </TabView>
      {/* edit Dialog */}
      <Dialog
        visible={editVisible}
        onHide={closeEditDialog}
        style={{
          width: "40rem",
          height: "fit-content",
        }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <span className="p-float-label mt-6">
              <InputText
                id="trigger_type"
                name="trigger_type"
                value={editData?.trigger_type}
                disabled
                className="h-8 border py-1.5 pl-2 text-sm text-gray-800"
              />
              <label htmlFor="trigger_type" className="dark:text-gray-300">
                Trigger Type
              </label>
            </span>
            <span className={"p-float-label mt-6"}>
              <InputText
                id="trigger_name"
                name="trigger_name"
                onChange={(e) => {
                  handleChange("trigger_name", e.target.value);
                }}
                value={editData?.trigger_name}
                className="h-8 border py-1.5 pl-2 text-sm"
              />
              <label htmlFor="trigger_name" className="dark:text-gray-300">
                Trigger Name
              </label>
            </span>
            <span className={"p-float-label mt-6"}>
              <Dropdown
                id="vehicle_uuid"
                name="vehicle_uuid"
                optionLabel="label"
                optionValue="value"
                options={vehiclesOptions()}
                onChange={(e) => {
                  handleChange("vehicle_id", e.target.value);
                }}
                value={editData?.vehicle_id}
                className="h-8 border"
              />
              <label htmlFor="vehicle_uuid" className="dark:text-gray-300">
                Select Vehicle
              </label>
            </span>
            {/* <span className={"p-float-label mt-6"}>
              <InputText
                id="trigger_description"
                name="trigger_description"
                onChange={(e) => {
                  handleChange("trigger_description", e.target.value);
                }}
                className="h-8 border py-1.5 pl-2 text-sm"
                value={
                  editData?.trigger_description ||
                  editData?.GeofenceData?.trigger_description
                }
              />
              <label
                htmlFor="trigger_description"
                className="dark:text-gray-300"
              >
                Trigger Description
              </label>
            </span> */}
            <span className={"p-float-label mt-6"}>
              <Dropdown
                id="trigger_status"
                name="trigger_status"
                optionLabel="label"
                optionValue="value"
                options={statusOptions}
                onChange={(e) => {
                  handleChange("trigger_status", e.target.value);
                }}
                value={editData?.trigger_status}
                className="h-8 border"
              />
              <label htmlFor="trigger_status" className="dark:text-gray-300">
                Select Status
              </label>
            </span>
            <span className={"p-float-label mt-6"}>
              <Dropdown
                id="phone"
                name="phone"
                optionLabel="label"
                optionValue="value"
                options={contactsOptions()}
                onChange={(e) => {
                  handleChange("phone", e.target.value);
                }}
                value={editData?.phone}
                className="h-8 border"
              />
              <label htmlFor="phone" className="dark:text-gray-300">
                Phone
              </label>
            </span>
            <span className={"p-float-label mt-6"}>
              <Dropdown
                id="email"
                name="email"
                optionLabel="label"
                optionValue="value"
                options={emailOptions()}
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                value={editData?.email}
                className="h-8 border"
              />
              <label htmlFor="email" className="dark:text-gray-300">
                Email
              </label>
            </span>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteVisible}
        onHide={closeDeleteDialog}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={closeDeleteDialog}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {triggerName}?
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertTriggerList;
