import React, { useState, useEffect } from "react";
import { MdModeOfTravel, MdRadar } from "react-icons/md";
import { RiPinDistanceFill } from "react-icons/ri";
import { FaMobile, FaTruckLoading } from "react-icons/fa";
import { BsFuelPump } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { GoOrganization } from "react-icons/go";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { useParams } from "react-router-dom";
import GraphFuel from "./GraphFuel";
import GraphLoad from "./GraphLoad";
import { FaLocationPinLock } from "react-icons/fa6";
import GeofenceAlerts from "./GeofenceAlerts";

function VehicleSummary({ data, loadTimeData, fuelTimeData }) {
  const user_type = Cookies.get("user_type");
  const { vehicle_id } = useParams();
  const token = Cookies.get("token");
  const [dmsDialogVisible, setDMSDialogVisible] = useState(false);
  const [fuelDialogVisible, setFuelDialogVisible] = useState(false);
  const [loadDialogVisible, setLoadDialogVisible] = useState(false);
  const [geofenceDialog, setGeofenceDialog] = useState(false);
  const [videos, setVideos] = useState([]);
  const [cas, setCAS] = useState({});
  const [dms, setDMS] = useState({});
  const [singleIot, setSingleIot] = useState({});

  useEffect(() => {
    const deviceData = (type) => {
      return data.device_id?.filter((item) => item.device_type_id === type);
    };

    setCAS(deviceData("1"));
    setDMS(deviceData("3"));
    setSingleIot(deviceData("4"));

    axios
      .get(
        `${process.env.REACT_APP_AWS_URL1}/calibrationVideos/${vehicle_id}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setVideos(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [token, vehicle_id, data]);

  //Converting time into HH:MM:SS format
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  return (
    <div>
      <div className="mt-5 flex items-center justify-start gap-4 sm-max:flex-wrap">
        <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
          <div className="flex items-center justify-center">
            <RiPinDistanceFill
              style={{ fontSize: "1.5rem", marginRight: "8px" }}
            />
            <div>
              <p className="text-base">Total Distance</p>
              <h3 className="text-base font-bold">
                {data?.total_distance
                  ? parseFloat(data?.total_distance).toFixed(2)
                  : 0}{" "}
                kms
              </h3>
            </div>
          </div>
        </div>
        <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
          <div className="justify-left flex items-center">
            <IoTimeOutline style={{ fontSize: "1.5rem", marginRight: "8px" }} />
            <div>
              <p className="text-base">Total Duration</p>
              <h3 className="text-base font-bold">
                {data?.Total_duration
                  ? formatDuration(parseInt(data?.Total_duration))
                  : 0}
              </h3>
            </div>
          </div>
        </div>
        <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
          <div className="justify-left flex items-center">
            <MdModeOfTravel
              style={{ fontSize: "1.5rem", marginRight: "8px" }}
            />
            <div>
              <p className="text-base">Total Trips</p>
              <h3 className="text-base font-bold">
                {data?.total_trips ? data?.total_trips : 0}
              </h3>
            </div>
          </div>
        </div>
        {/* <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
          <div className="justify-left flex items-center">
            <GoAlert style={{ fontSize: "1.5rem", marginRight: "8px" }} />
            <div>
              <h3 className="text-base font-bold">
                {data?.total_alerts ? data?.total_alerts : 0}
              </h3>
              <p className="text-base">Total Alerts</p>
            </div>
          </div>
        </div> */}
        {user_type !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e" ? (
          <></>
        ) : (
          <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] m-3 mx-2 rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200 md:m-10 md:mx-3">
            <div className="justify-left flex items-center">
              <GoOrganization
                style={{ fontSize: "1.5rem", marginRight: "8px" }}
              />
              <div>
                <p className="text-base">Organization</p>
                <h3 className="text-base font-bold">{data?.organization}</h3>
              </div>
            </div>
          </div>
        )}
        {dms?.length > 0 || singleIot?.length > 0 ? (
          <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] self-stretch rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
            <div
              className="justify-left flex cursor-pointer items-center"
              onClick={
                videos.length > 0
                  ? () => setDMSDialogVisible(true)
                  : () => {
                      console.log();
                    }
              }
            >
              <FaMobile style={{ fontSize: "1.5rem", marginRight: "8px" }} />
              <div>
                <p className="text-base">DMS</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {cas?.length > 0 || singleIot?.length > 0 ? (
          <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] self-stretch rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200">
            <div className="justify-left flex cursor-pointer items-center">
              <MdRadar style={{ fontSize: "1.5rem", marginRight: "8px" }} />
              <div>
                <p className="text-base">CAS</p>
                {/* <h3 className="text-base font-bold">
                  {data?.total_alerts ? data?.total_alerts : 0}
                </h3> */}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] cursor-pointer self-stretch rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200"
          onClick={() => setFuelDialogVisible(true)}
        >
          <div className="justify-left flex items-center">
            <BsFuelPump style={{ fontSize: "1.5rem", marginRight: "8px" }} />
            <div>
              <p className="text-base">Fuel</p>
            </div>
          </div>
        </div>
        <div
          className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] cursor-pointer self-stretch rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200"
          onClick={() => setLoadDialogVisible(true)}
        >
          <div className="justify-left flex items-center">
            <FaTruckLoading
              style={{ fontSize: "1.5rem", marginRight: "8px" }}
            />
            <div>
              <p className="text-base">Load</p>
            </div>
          </div>
        </div>
        {/* <div
          className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] cursor-pointer self-stretch rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200"
          onClick={() => setGeofenceDialog(true)}
        >
          <div className="justify-left flex items-center">
            <FaLocationPinLock
              style={{ fontSize: "1.5rem", marginRight: "8px" }}
            />
            <div>
              <p className="text-base">Geofence Alerts</p>
            </div>
          </div>
        </div> */}
        {/* <div className="rgba-[0,0,0,0.8] rgba-[0,0,0,0.24] flex rounded-lg bg-white p-3 shadow-sm dark:bg-navy-700 dark:text-gray-200 md:m-10 md:mx-3">
          <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div className="justify-left flex items-center ">
              <FaMobile
                className={`h-4 w-4 ${dms?.length > 0 ? "text-green-600" : ""}`}
              />
              <div>
                <p
                  className={`px-1 text-sm ${
                    dms?.length > 0 ? "text-green-600" : ""
                  }`}
                >
                  {dms?.length > 0 ? dms[0]?.device_id : "DMS"}
                </p>
              </div>
            </div>
            <div className="justify-left flex items-center">
              <MdRadar
                className={`h-4 w-4 ${cas?.length > 0 ? "text-green-600" : ""}`}
              />
              <div>
                <p
                  className={`px-1 text-sm ${
                    cas?.length > 0 ? "text-green-600" : ""
                  }`}
                >
                  {cas?.length > 0 ? cas[0]?.device_id : "CAS"}
                </p>
              </div>
            </div>
            <div className="justify-left flex items-center">
              <BsFuelPump className={`h-4 w-4 `} />
              <div>
                <p className="px-1 text-sm ">Fuel</p>
              </div>
            </div>
            <div className="justify-left flex items-center">
              <FaTruckLoading className="h-4 w-4 " />
              <div>
                <p className="px-1 text-sm ">Load</p>
              </div>
            </div>
          </div>
          <div className="vl mx-3"></div>
          <div className="flex-col gap-3">
            <div className="justify-left flex items-center">
              <MdNetworkWifi
                className={`h-4 w-4 ${iot?.length > 0 ? "text-green-600" : ""}`}
              />
              <div>
                <p
                  className={`px-1 text-sm ${
                    iot?.length > 0 ? "text-green-600" : ""
                  }`}
                >
                  {iot?.length > 0 ? iot[0]?.device_id : "IoT"}
                </p>
              </div>
            </div>
            <div
              className="justify-left mt-1.5 flex cursor-pointer items-center"
              onClick={
                videos.length > 0
                  ? () => setDialogVisible(true)
                  : () => {
                      console.log();
                    }
              }
            >
              <BsPersonBoundingBox
                className={`h-4 w-4 ${
                  videos.length > 0 ? "text-green-600" : "text-gray-400"
                }`}
              />
              <div>
                <p
                  className={`px-1 text-sm ${
                    videos.length > 0 ? "text-green-600" : "text-gray-400"
                  }`}
                >
                  Calibration
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* Caliberation videos */}
      <Dialog
        header="Calibration video"
        visible={dmsDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => setDMSDialogVisible(false)}
      >
        {videos.length > 0 ? (
          <div className="grid grid-cols-2 gap-2 xl:grid-cols-2 2xl:grid-cols-2">
            {videos.map((item, key) => (
              <video controls autoPlay key={key}>
                <source
                  src={`${process.env.REACT_APP_S3_URL}/${item}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>
        ) : (
          <>
            <p>Media doesn't exist</p>
          </>
        )}
      </Dialog>

      {/* Fuel Graph */}
      <Dialog
        header="Statistical Representation"
        visible={fuelDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => setFuelDialogVisible(false)}
      >
        <GraphFuel data={fuelTimeData} />
      </Dialog>

      {/* Load Graph */}
      <Dialog
        header="Statistical Representation"
        visible={loadDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => setLoadDialogVisible(false)}
      >
        <GraphLoad data={loadTimeData} />
      </Dialog>
      <Dialog
        header="Geofence Alerts"
        visible={geofenceDialog}
        style={{ width: "30vw" }}
        onHide={() => setGeofenceDialog(false)}
      >
        <GeofenceAlerts />
      </Dialog>
    </div>
  );
}

export default VehicleSummary;
