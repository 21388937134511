import React, { useContext, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaTruck } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { BiUnlink } from "react-icons/bi";
import { IoIosInformationCircle, IoIosLink } from "react-icons/io";
import axios from "axios";
import Cookies from "js-cookie";
import { AppContext } from "context/AppContext";

export default function DevicesList({
  data,
  onEditDevice,
  deviceType,
  onDeleteDevice,
  // deviceUpdates,
  org,
}) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isAssignDialogVisible, setIsAssignDialogVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [rowId, setRowId] = useState();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [unassignDialogVisible, setUnassignDialogVisible] = useState(false);
  // const [updateData, setUpdateData] = useState({});
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState(0);
  const { updateDevice } = useContext(AppContext);
  const toastRef = useRef(null);
  const token = Cookies.get("token");
  const user_type = Cookies.get("user_type");

  //Global search logic
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue(""); // Clear the search input value
    const _filters = { ...filters };
    _filters["global"].value = null; // Clear the global filter value
    setFilters(_filters);
  };

  const handleDeviceTypeChange = (value) => {
    setSelectedDeviceTypes(value);
  };
  //Opens delete dialog
  const openDeleteDialog = (rowData) => {
    setSelectedDevice(rowData);
    setDeleteDialogVisible(true);
    setRowId(rowData);
  };
  //Opens delete dialog
  const openUnassignDialog = (rowData) => {
    setSelectedDevice(rowData);
    setUnassignDialogVisible(true);
    setRowId(rowData);
  };

  //Searchbox
  const renderHeader = () => {
    return (
      <div className="flex justify-between sm-max:flex-wrap">
        <div className="mt-1 flex flex-row items-center dark:text-gray-200 sm-max:flex-wrap-reverse sm-max:text-xs">
          <div className="pr-2 sm-max:pr-0">
            <input
              type="radio"
              id="all"
              value="All"
              checked={selectedDeviceTypes === 0}
              onChange={() => handleDeviceTypeChange(0)}
            />
            <label htmlFor="all" className="font-normal">
              &nbsp;All
            </label>
          </div>
          {deviceType?.map((item, key) => (
            <div className="px-2" key={key}>
              <input
                type="radio"
                id={`deviceType_${key}`}
                value={item.value}
                checked={selectedDeviceTypes === item.value}
                onChange={() => handleDeviceTypeChange(item.value)}
              />
              <label htmlFor={`deviceType_${key}`} className="font-normal">
                &nbsp;{item.label}
              </label>
            </div>
          ))}
        </div>
        <div className="my-3 flex justify-end  dark:bg-gray-950">
          <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
            <i className="pi pi-search pl-2 dark:text-gray-300" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
            />
            {globalFilterValue && (
              <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
                onClick={clearSearch}
              />
            )}
          </span>
        </div>
      </div>
    );
  };

  const openAssignDialog = (rowData) => {
    setIsAssignDialogVisible(true);
    setSelectedDevice(rowData);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        {/* {updateData.dmsapp_data === null ||
        updateData.uconfig_data === null ||
        updateData.version_number === null ? (
          <div className="relative mx-2 inline-block">
            <FaRegEdit
              title="Edit"
              onClick={() => openDialog(rowData)}
              className="cursor-pointer text-gray-700"
            />
            <p className="absolute -left-0.5 -top-1.5 text-yellow-500">*</p>
          </div>
        ) : (
          <FaRegEdit
            title="Edit"
            onClick={() => openDialog(rowData)}
            className="cursor-pointer text-gray-700"
          />
        )} */}
        <FaRegEdit
          title="Edit"
          onClick={() => openDialog(rowData)}
          className="cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.device_status === 2
              ? openDeleteDialog(rowData)
              : console.log();
          }}
          className={`mx-2 cursor-pointer ${
            rowData.device_status === 2 ? "text-red-600" : "text-gray-500"
          }`}
        />
        {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <>
            {rowData.org_id ? (
              <BiUnlink
                className="mx-2 cursor-pointer text-red-600"
                title="Unassign organization"
                onClick={() => openUnassignDialog(rowData)}
              />
            ) : (
              <IoIosLink
                className="mx-2 cursor-pointer font-light text-gray-700"
                title="Assign organization"
                onClick={() => openAssignDialog(rowData)}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const header = renderHeader();

  //Delete dialog
  const DeleteDeviceDialog = ({ visible, onHide }) => {
    const handleConfirmDelete = async () => {
      try {
        await onDeleteDevice(selectedDevice?.device_id);
        onHide();
      } catch (error) {
        onHide();
      }
    };
    // delete dialog
    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleConfirmDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {selectedDevice?.device_id}?
          </span>
        </div>
      </Dialog>
    );
  };
  //Unassign dialog
  const UnassignOrgDialog = ({ visible, onHide }) => {
    const handleConfirmUnassign = () => {
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL2}/deviceToOrganizationUnAssign/${selectedDevice?.device_id}`,
          null,
          {
            headers: { Authorization: token },
          }
        )
        .then(() => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: `Device ${selectedDevice?.device_id} unassigned successfully`,
            life: 3000,
          });
          onHide();
          updateDevice();
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            toastRef.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
          onHide();
        });
    };
    // delete dialog
    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Unassign"
        footer={
          <div>
            <Button
              label="Confirm"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleConfirmUnassign}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <IoIosInformationCircle className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to unassign {selectedDevice?.device_id} ?
          </span>
        </div>
      </Dialog>
    );
  };

  const orgOptions = () => {
    return org?.map((el) => ({
      label: el.orgName,
      value: el.orgId,
    }));
  };

  const handleAssign = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL2}/deviceToOrganizationAssign/${selectedDevice?.device_id}`,
        { org_id: selectedOrg },
        {
          headers: { Authorization: token },
        }
      )
      .then(() => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Device ${selectedDevice.device_id} is assigned successfully`,
          life: 3000,
        });
        closeAssignDialog();
        updateDevice();
      })
      .catch((err) => {
        if (err.response && err.response.data.message) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
        closeAssignDialog();
      });
  };
  // Opens edit dialog
  const openDialog = (rowData) => {
    setIsDialogVisible(true);
    // setUpdateData(rowData);
    setEditData(rowData);
    setRowId(rowData);
  };

  //Closes edit dialog
  const closeDialog = () => {
    setIsDialogVisible(false);
  };

  const stateOptions = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ["device_id", "device_type_id", "device_status"];
    const isAnyFieldEmpty = requiredFields.some((field) => !editData[field]);

    if (isAnyFieldEmpty) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields.",
      });
    } else {
      onEditDevice(rowId?.device_id, editData);
      // deviceUpdates(rowId?.device_id, updateData);
      closeDialog();
    }
  };

  const handleChange = (e, name) => {
    const value = e.target ? e.target.value : e.value;
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));
  };

  // const handleChangeUpdates = (event) => {
  //   const { name, value } = event.target;
  //   setUpdateData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // Status body
  const getStatusSeverity = (option) => {
    switch (option) {
      case 1:
        return "success";

      case 2:
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.device_status === 1 ? "Active" : "Deactive"}
        severity={getStatusSeverity(rowData.device_status)}
        className="h-5 rounded-sm text-xs font-normal"
      />
    );
  };

  const availabilityTemplate = (rowData) => {
    return (
      <FaTruck
        className={`h-5 w-5 ${
          rowData.linked === null ? "text-gray-600" : "text-green-600"
        }`}
        title={
          rowData.linked === null ? "Vehicle Not Linked" : "Vehicle Linked"
        }
      />
    );
  };

  const closeAssignDialog = () => {
    setIsAssignDialogVisible(false);
    setSelectedOrg([]);
  };

  //edit dialog
  return (
    <div className="card">
      <Dialog
        visible={isAssignDialogVisible}
        onHide={closeAssignDialog}
        style={{ width: "20%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit the device"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleAssign}>
          <span className="p-float-label mt-8">
            <Dropdown
              id="org"
              name="org"
              options={orgOptions()}
              optionLabel="label"
              optionValue="value"
              className={`p-dropdown h-9 border text-sm`}
              onChange={(e) => setSelectedOrg(e.value)}
              value={selectedOrg}
            />
            <label htmlFor="org" className="dark:text-gray-300">
              Select Organization
            </label>
          </span>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Assign
            </button>
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={isDialogVisible}
        onHide={closeDialog}
        style={{ width: "35%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit the device"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit} className="mx-auto">
          <div className="mx-auto mt-8 grid w-full grid-cols-2 md:grid-cols-2">
            <span className="p-float-label">
              <InputText
                id="device_id"
                name="device_id"
                value={editData?.device_id || ""}
                onChange={(e) => handleChange(e, "device_id")}
                className={`text-normal border border-gray-300 bg-gray-100 py-1 pl-2 text-sm font-semibold text-navy-900 ${
                  !editData?.device_id ? "p-invalid" : ""
                }`}
                disabled
              />
              <label htmlFor="device_id" className="dark:text-gray-300">
                Device ID
              </label>
            </span>
            <span className="p-float-label w-full">
              <Dropdown
                id="device_type"
                name="device_type_id"
                options={deviceType}
                optionLabel="label"
                optionValue="value"
                value={editData?.device_type_id || ""}
                className="p-dropdown h-8 border"
                onChange={(e) => handleChange(e, "device_type_id")}
              />
              <label htmlFor="device_type" className="dark:text-gray-300">
                Device Type
              </label>
            </span>
          </div>
          <div className="mx-auto mt-8 grid w-full grid-cols-2 md:grid-cols-2">
            <span className="p-float-label">
              <Dropdown
                id="status"
                name="device_status"
                options={stateOptions}
                optionLabel="label"
                optionValue="value"
                className="p-dropdown h-8 border"
                value={editData?.device_status || ""}
                onChange={(e) => handleChange(e, "device_status")}
              />
              <label htmlFor="status" className="dark:text-gray-300">
                Status
              </label>
            </span>
            {/* <span className="p-float-label">
              <InputText
                keyfilter="alphanum"
                id="version_number"
                name="version_number"
                value={updateData?.version_number || ""}
                onChange={handleChangeUpdates}
                className={`h-8 border py-1.5 pl-2 text-sm${
                  !editData?.device_id ? "p-invalid" : ""
                }`}
              />
              <label htmlFor="version_number" className="dark:text-gray-300">
                Version Number
              </label>
            </span>
            <span className="p-float-label">
              <InputText
                keyfilter="alpha"
                id="dmsapp_data"
                name="dmsapp_data"
                value={updateData?.dmsapp_data || ""}
                onChange={handleChangeUpdates}
                className={`h-8 border py-1.5 pl-2 text-sm ${
                  !editData?.dmsapp_data ? "p-invalid" : ""
                }`}
              />
              <label htmlFor="dmsapp_data" className="dark:text-gray-300">
                DMS data
              </label>
            </span>
            <span className="p-float-label">
              <InputText
                keyfilter="alpha"
                id="uconfig_data"
                name="uconfig_data"
                value={updateData?.uconfig_data || ""}
                onChange={handleChangeUpdates}
                className={`h-8 border py-1.5 pl-2 text-sm ${
                  !editData?.uconfig_data ? "p-invalid" : ""
                }`}
              />
              <label htmlFor="uconfig_data" className="dark:text-gray-300">
                Config data
              </label>
            </span> */}
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <DataTable
        value={
          selectedDeviceTypes === 0
            ? data
            : data.filter((item) => item.device_type_id === selectedDeviceTypes)
        }
        removableSort
        paginator
        header={header}
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={["device_id", "device_status", "matchedOrgName"]}
        emptyMessage="No devices found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} devices"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        ></Column>
        <Column
          field="device_id"
          header="Device ID"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          header="Device Type"
          sortField="device_type_id"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "6rem", padding: "5px" }}
          body={(rowData) => {
            const deviceTypeId = rowData.device_type_id;
            const matchedDevice = Array.isArray(deviceType)
              ? deviceType.find((type) => type.value === deviceTypeId)
              : null;
            return (
              <Tag
                className="mr-2 bg-gray-200 text-gray-800"
                icon="pi pi-tag"
                style={{
                  width: "fit-content",
                  height: "25px",
                }}
                value={matchedDevice ? matchedDevice.label : "Unknown"}
              />
            );
          }}
        ></Column>
        {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <Column
            field="matchedOrgName"
            header="Organization"
            className="border-b py-1 text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "12rem", textAlign: "left", padding: "5px" }}
          ></Column>
        )}
        <Column
          field="device_status"
          header="Status"
          body={statusBodyTemplate}
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
        ></Column>
        <Column
          field="DeviceLinked"
          header="Linked to vehicle"
          body={availabilityTemplate}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 2.5rem",
          }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "7rem", padding: "5px", textAlign: "center" }}
        ></Column>
        {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <Column
            body={actionBodyTemplate}
            header="Action"
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "9rem", padding: "5px" }}
          ></Column>
        )}
      </DataTable>
      <DeleteDeviceDialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
      />
      <UnassignOrgDialog
        visible={unassignDialogVisible}
        onHide={() => setUnassignDialogVisible(false)}
      />
    </div>
  );
}
