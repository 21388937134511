import React, { useState, useEffect, useRef } from "react";
// import { BsGrid, BsListUl } from "react-icons/bs";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Cookies from "js-cookie";
import { FiPlus } from "react-icons/fi";
// import PreloaderGrid from "components/skeleton-preloader/PreloaderGrid";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import SimList from "./components/SimList";
// import SimGrid from "./components/SimGrid";
import { Calendar } from "primereact/calendar";

const SimCards = () => {
  const token = Cookies.get("token");
  const [simcards, setSimCards] = useState({});
  const [data, setData] = useState([]);
  // const [isListView, setIsListView] = useState(
  //   localStorage.getItem("viewPreference") === "grid" ? false : true
  // );
  const [activationDate, setActivationDate] = useState(null);
  const [validity, setValidity] = useState(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    sim_number: false,
  });
  // const requiredFields = ["sim_number", "device_type", "user_uuid", "status"];
  const [addData, setAddData] = useState({
    sim_number: "",
    sim_data_pack: "",
    sim_tag: "",
    sim_amount: "",
  });
  const [loaded, setLoaded] = useState(false);
  const toastRef = useRef(null);
  const [userLookupData, setUserLookupData] = useState([]);

  //Fetching all data

  useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_AWS_URL1}/getAllSimCards`, {
    //     headers: { Authorization: `${token}` },
    //   })
    //   .then((res) => {
    //     const formattedData = res.data.data.map((item, index) => ({
    //       ...item,
    //       serialNo: index + 1,
    //       key: index + 1,
    //     }));

    //     setData(formattedData);
    //     setLoaded(true);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    try {
      axios
        .get(`${process.env.REACT_APP_AWS_URL1}/getAllSimCards`, {
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          if (res.status === 200) {
            const formattedData = res.data.data?.map((item, index) => ({
              ...item,
              serialNo: index + 1,
              key: index + 1,
            }));
            setData(formattedData);
            setLoaded(true);
          }
        });
    } catch (err) {
      // console.log(err);
    }
  }, [token, simcards]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getUsersById`, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        // console.log('get User by id::::' , res.data.data);
        let arrayData = [];
        res.data.data.map((ele) => {
          let adminUser = {
            label: ele.first_name + " " + ele.last_name,
            value: ele.user_id,
          };
          return arrayData.push(adminUser);
        });

        //TODO DONE: add the api call here and replace the below code

        setUserLookupData(arrayData);
      })
      .catch(() => {
        // console.log(err);
      });
  }, [token, simcards]);

  // Edit Devices
  const handleEditDevice = (simId, simData) => {
    const newActDate = new Date(simData.sim_activation_date);
    const newExpDate = new Date(simData.sim_validity);
    if (newActDate < newExpDate) {
      if (simData.sim_amount < 20000) {
        axios
          .put(
            `${process.env.REACT_APP_AWS_URL1}/updateSimCards/${simId}`,
            simData,
            { headers: { Authorization: `${token}` } }
          )
          .then(() => {
            // setSimCards(simData);
            setSimCards(simData);

            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: `Sim-card updated successfully`,
              life: 3000,
            });
          })
          .catch((err) => {
            if (err.response.data === 404) {
              toastRef.current.show({
                severity: "warn",
                summary: "Warning",
                detail: "Sim not found",
                life: 3000,
              });
            }
            if (err.response.data === 500) {
              toastRef.current.show({
                severity: "danger",
                summary: "Error",
                detail: "Failed to update Sim",
                life: 3000,
              });
            }
          });
      } else {
        toastRef.current.show({
          severity: "danger",
          summary: "Error",
          detail: "Please add the sim recharge amount within 20000",
          life: 3000,
        });
      }
    } else {
      toastRef.current.show({
        severity: "danger",
        summary: "Error",
        detail:
          "Failed to update Sim | Please verify that your expiry date is greater than activation date",
        life: 3000,
      });
    }
  };

  // Delete api call
  const handleDeleteDevice = (simId) => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/deleteSimCard/${simId}`,
        {},
        { headers: { Authorization: `${token}` } }
      )
      .then(() => {
        // setSimCards(data);
        // setSimCards((prevData) =>
        //   prevData.filter((item) => item.simId !== simId)
        // );

        const simCard = data.filter((item) => item.sim_id === simId);
        setSimCards(simCard);

        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Sim number ${simCard[0].sim_number} deleted successfully`,
          life: 3000,
        });
      })
      .catch(() => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete device. Please try again later.",
          life: 3000,
        });
      });
  };

  const resetFormData = () => {
    setAddData({
      sim_number: "",
    });
  };

  // const handleToggleView = () => {
  //   const newView = !isListView;
  //   setIsListView(newView);
  //   // Store the view preference in localStorage
  //   localStorage.setItem("viewPreference", newView ? "list" : "grid");
  // };

  //Add device dialog open
  const openDialog = () => {
    resetFormData();
    setIsDialogVisible(true);
  };

  //Add device dialog close
  const closeDialog = () => {
    resetFormData();
    setValidationErrors(false);
    setActivationDate(null);
    setValidity(null);
    setIsDialogVisible(false);
  };

  // Add customer api call
  const handleSubmit = (e) => {
    e.preventDefault();

    const newActDate = new Date(activationDate);
    const newExpDate = new Date(validity);
    if (newActDate < newExpDate) {
      if (addData.sim_amount < 20000) {
        axios
          .post(
            `${process.env.REACT_APP_AWS_URL1}/createSimCards`,
            {
              ...addData,
              sim_activation_date: activationDate,
              sim_validity: validity,
            },
            {
              headers: { Authorization: token },
            }
          )
          .then(() => {
            setSimCards(addData);
            // console.log("add data sim : ", addData);
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: `Sim ${addData.sim_number} Added successfully`,
              life: 3000,
            });
            closeDialog();
          })
          .catch(() => {
            toastRef.current.show({
              severity: "error",
              summary: "Error",
              detail: "Sim number Already exists.",
              life: 3000,
            });
          });
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please add the recharge amount within 20000",
          life: 3000,
        });
      }
    }

    // console.log("add data::::::::", addData);

    // Set validation errors for the required fields
    // const errors = {};
    // requiredFields.forEach((field) => {
    //   errors[field] = !addData[field].trim();
    // });
    // setValidationErrors(errors);
    else {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Kindly check that expiry date is greater than activation_date",
        life: 3000,
      });
    }
  };

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based, so add 1 and pad with '0' if needed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleValidation = (name, value) => {
    const errors = { ...validationErrors };
    errors[name] = !value.trim();
    setValidationErrors(errors);
  };

  // Check if all fields are valid
  // const isFormValid = () => {
  //   // Check if all required fields are filled
  //   const requiredFieldsFilled = requiredFields.every(
  //     (field) => !!addData[field].trim()
  //   );

  //   // If device type is "DMS" or "IoT," also check if "sim_number" is filled
  //   const isSimNumberRequired =
  //     addData.device_type === "DMS" || addData.device_type === "IoT";
  //   const simNumberFilled = !isSimNumberRequired || !!addData.sim_number.trim();

  //   return requiredFieldsFilled && simNumberFilled;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
    handleValidation(name, value);
  };

  //add device dialog
  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="flex justify-between">
        <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
          Sim Cards
        </h4>
        <Dialog
          visible={isDialogVisible}
          onHide={closeDialog}
          style={{ width: "40%", height: "fit-content" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Fill the details"
          modal
          className="p-fluid dark:bg-gray-900"
        >
          <form onSubmit={handleSubmit} className="mx-auto">
            <div className="flex justify-between gap-4">
              <div
                className={`mx-auto mt-6 w-full ${
                  validationErrors.sim_number ? "p-error" : ""
                }`}
              >
                <span className="p-float-label">
                  <InputText
                    id="sim_number"
                    name="sim_number"
                    onChange={handleChange}
                    className={`border py-1.5 pl-2 text-sm ${
                      validationErrors.sim_number ? "border-red-600" : ""
                    }`}
                  />
                  <label htmlFor="sim_number" className="dark:text-gray-300">
                    Sim Number
                  </label>
                </span>
                {validationErrors.sim_number && (
                  <small className="text-red-600">Sim number is required</small>
                )}
              </div>
              <div
                className={`mx-auto mt-6 w-full ${
                  validationErrors.sim_data_pack ? "p-error" : ""
                }`}
              >
                <span className="p-float-label">
                  <InputText
                    id="sim_data_pack"
                    name="sim_data_pack"
                    onChange={handleChange}
                    className={`border py-1.5 pl-2 text-sm ${
                      validationErrors.sim_data_pack ? "border-red-600" : ""
                    }`}
                  />
                  <label htmlFor="sim_data_pack" className="dark:text-gray-300">
                    Data Pack
                  </label>
                </span>
                {validationErrors.sim_data_pack && (
                  <small className="text-red-600">Data pack is required</small>
                )}
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div
                className={`mx-auto mt-6 w-full ${
                  validationErrors.sim_tag ? "p-error" : ""
                }`}
              >
                <span className="p-float-label">
                  <InputText
                    id="sim_tag"
                    name="sim_tag"
                    onChange={handleChange}
                    className={`border py-1.5 pl-2 text-sm ${
                      validationErrors.sim_tag ? "border-red-600" : ""
                    }`}
                  />
                  <label htmlFor="sim_tag" className="dark:text-gray-300">
                    Sim Tag
                  </label>
                </span>
                {validationErrors.sim_tag && (
                  <small className="text-red-600">Sim Tag is required</small>
                )}
              </div>
              <div className={`mx-auto mt-6 w-full`}>
                <span className="p-float-label">
                  <InputText
                    id="sim_amount"
                    name="sim_amount"
                    max={20000}
                    options={userLookupData}
                    className={`border py-1.5 pl-2 text-sm`}
                    onChange={handleChange}
                    value={addData?.sim_amount}
                  />
                  <label htmlFor="device_type" className="dark:text-gray-300">
                    Sim Amount
                  </label>
                </span>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 sm-max:grid-cols-2">
              <div className="flex-auto">
                <span className="p-float-label">
                  <Calendar
                    inputId="start_date"
                    value={activationDate ? new Date(activationDate) : null}
                    onChange={(e) => {
                      const formattedDate = formatDateToYYYYMMDD(e.value);
                      setActivationDate(formattedDate);
                    }}
                    className={`h-9 rounded-lg border border-gray-300 `}
                  />
                  <label htmlFor="start_date" className="dark:text-gray-300">
                    From
                  </label>
                </span>
              </div>
              <div className="flex-auto">
                <span className="p-float-label">
                  <Calendar
                    inputId="end_date"
                    value={validity ? new Date(validity) : null}
                    onChange={(e) => {
                      const formattedDate = formatDateToYYYYMMDD(e.value);
                      setValidity(formattedDate);
                    }}
                    className={`h-9 rounded-lg border border-gray-300 `}
                  />
                  <label htmlFor="start_date" className="dark:text-gray-300">
                    To
                  </label>
                </span>

                {/* {!endDateValid ? (
                  <small className="text-red-500">To date is required.</small>
                ) : (
                  <small className="text-gray-400 dark:text-gray-150">
                    Click to Select
                  </small>
                )} */}
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <button
                type="submit"
                className="ml-auto rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
              >
                Add Sim
              </button>
            </div>
          </form>
        </Dialog>
        {/* <div className="pt-2">
          <button
            className={`${
              isListView === true
                ? "list-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                : "list-btn bg-white px-2 py-1  dark:bg-gray-150 "
            }`}
            onClick={handleToggleView}
          >
            <BsListUl />
          </button>
          <button
            className={`${
              isListView === false
                ? "grid-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                : "grid-btn bg-white px-2 py-1  dark:bg-gray-150 "
            }`}
            onClick={handleToggleView}
          >
            <BsGrid />
          </button>
        </div> */}
      </div>
      <button
        className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        onClick={openDialog}
      >
        <FiPlus />
        &nbsp;New Simcard
      </button>
      {/* {!isListView && (
        <div className="opacity-100 transition-opacity duration-500">
          {loaded ? (
            <SimGrid
              data={data}
              // deviceType={devicesOptions()}
              onEditSim={handleEditDevice}
              onDeleteSim={handleDeleteDevice}
            />
          ) : (
            <div className="mt-6">
              <PreloaderGrid />
            </div>
          )}
        </div>
      )} */}

      <div className="opacity-100 transition-opacity duration-500">
        {loaded ? (
          <SimList
            data={data}
            onEditSim={handleEditDevice}
            onDeleteSim={handleDeleteDevice}
          />
        ) : (
          <div className="mt-6">
            <PreloaderList />
          </div>
        )}
      </div>
    </>
  );
};

export default SimCards;
