import axios from "axios";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  BiArrowBack,
  BiBarChart,
  BiCalendar,
  BiLogoSlackOld,
  BiTime,
} from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../../assets/img/logo.png";
import { Calendar } from "primereact/calendar";

const ReportsTriveni = () => {
  const token = Cookies.get("token");
  const { vehicle_id, fromDate, toDate } = useParams();
  const tableRef = useRef(null);

  const [activeTab, setActiveTab] = useState("overview");
  const [loading, setLoading] = useState(true);

  const [reportData, setReportData] = useState({
    date: new Date(),
    title: "",
    fromDate: fromDate,
    toDate: toDate,
    chartData: {},
    tableData: {},
    events: [],
    statistics: {
      totalTrips: 0,
      totalDistance: 0,
      totalDuration: 0,
      totalAlerts: 0,
      averageSpeed: 0,
      averageDistance: 0,
      averageDuration: 0,
      maxSpeed: 0,
      fuelConsumption: 0,
      co2Emissions: 0,
    },
  });

  const [speedData, setSpeedData] = useState({
    speedRanges: [],
    frequencies: [],
  });

  // Add new state for driver behavior scores
  const [driverBehavior, setDriverBehavior] = useState({
    categories: [],
    scores: [],
  });

  useEffect(() => {
    fetchReportData();
  }, [vehicle_id, token]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return { formattedDate: "", formattedTime: "" };

    // Convert epoch timestamp to milliseconds if needed
    // Epoch timestamps are usually in seconds, but JS Date expects milliseconds
    const date = new Date(
      String(timestamp).length === 10 ? timestamp * 1000 : timestamp
    );

    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", timestamp);
      return { formattedDate: "Invalid date", formattedTime: "Invalid time" };
    }

    return {
      formattedDate: date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      formattedTime: date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  const fetchReportData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/getReportsData/${vehicle_id}`,
        {
          fromDate: fromDate,
          toDate: toDate,
        },
        {
          headers: { Authorization: token },
        }
      );

      const data = response.data.data;

      // Process the data into the required format
      const processedData = {
        tableData: processRawData(data),
        date: new Date().toISOString(),
        title: `REPORT FOR ${data[0].VehicleReg}`,
        fromDate: formatTimestamp(fromDate).formattedDate, // Convert epoch to readable format
        toDate: formatTimestamp(toDate).formattedDate,
      };

      // Calculate statistics from the processed data
      const stats = calculateStatistics(processedData.tableData);

      // Process speed distribution data
      const speedDistribution = calculateSpeedDistribution(
        processedData.tableData
      );
      setSpeedData(speedDistribution);

      // Process driver behavior data
      const behaviorData = calculateDriverBehavior(processedData.tableData);
      setDriverBehavior(behaviorData);

      setReportData({
        ...processedData,
        statistics: stats,
        chartData: generateChartData(data),
        events: [],
      });
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateChartData = (data) => {
    // Group events by vehicle
    const chartData = {};
    data.forEach((trip) => {
      const vehicleReg = trip.VehicleReg;
      if (!chartData[vehicleReg]) {
        chartData[vehicleReg] = {
          TS: 0,
          ASV: 0,
          AUB: 0,
          CAO: 0,
          DIS: 0,
          HRA: 0,
          LCH: 0,
          SPB: 0,
          SUB: 0,
          TAL: 0,
          WRS: 0,
          ALCF: 0,
          ALCP: 0,
          ALCT: 0,
          ALM2: 0,
          ALM3: 0,
          DMSO: 0,
          DROW: 0,
          NODR: 0,
        };
      }

      // Sum up all alert types
      Object.keys(chartData[vehicleReg]).forEach((key) => {
        chartData[vehicleReg][key] += trip[key] || 0;
      });
    });

    return chartData;
  };

  // Alert calculation functions
  const calculateTotalAlerts = (trip) => {
    const alertFields = [
      "TS",
      "ASV",
      "AUB",
      "CAO",
      "DIS",
      "HRA",
      "LCH",
      "SPB",
      "SUB",
      "TAL",
      "WRS",
      "ALCF",
      "ALCP",
      "ALCT",
      "ALM2",
      "ALM3",
      "DMSO",
      "DROW",
      "NODR",
    ];
    return alertFields.reduce((total, field) => total + (trip[field] || 0), 0);
  };

  const calculateTotalDMSAlerts = (trip) => {
    const dmsFields = ["TS", "DIS", "DMSO", "DROW", "NODR"];
    return dmsFields.reduce((total, field) => total + (trip[field] || 0), 0);
  };

  const calculateTotalALCAlerts = (trip) => {
    const alcFields = ["ALCF", "ALCP", "ALCT"];
    return alcFields.reduce((total, field) => total + (trip[field] || 0), 0);
  };

  const calculateTotalCASAlerts = (trip) => {
    const casFields = [
      "ASV",
      "AUB",
      "CAO",
      "HRA",
      "LCH",
      "SPB",
      "SUB",
      "TAL",
      "WRS",
      "ALM2",
      "ALM3",
    ];
    return casFields.reduce((total, field) => total + (trip[field] || 0), 0);
  };

  // Modified processRawData function
  const processRawData = (rawData) => {
    const processedData = {};

    Object.values(rawData).forEach((trip) => {
      const vehicleReg = trip.VehicleReg;
      if (!processedData[vehicleReg]) {
        processedData[vehicleReg] = {
          tripData: {},
        };
      }

      // Add trip data with correctly calculated alerts
      processedData[vehicleReg].tripData[trip.trip_id] = {
        trip_id: trip.trip_id,
        device_id: vehicleReg,
        total_distance: trip.totalDistance,
        duration: trip.totalDuration,
        total_alerts: calculateTotalAlerts(trip),
        total_DMS_alerts: calculateTotalDMSAlerts(trip),
        total_CAS_alerts: calculateTotalCASAlerts(trip),
        total_ALC_alerts: calculateTotalALCAlerts(trip),
        average_speed: trip.avgSpd,
        max_speed: trip.maxSpd,
      };
    });
    return processedData;
  };

  // Function to calculate speed distribution
  const calculateSpeedDistribution = (tableData) => {
    const speedRanges = ["0-20", "21-40", "41-60", "61-80", "81-100", "100+"];
    const frequencies = new Array(speedRanges.length).fill(0);

    Object.values(tableData).forEach(({ tripData }) => {
      Object.values(tripData).forEach((trip) => {
        const speed = trip.average_speed;
        if (speed <= 20) frequencies[0]++;
        else if (speed <= 40) frequencies[1]++;
        else if (speed <= 60) frequencies[2]++;
        else if (speed <= 80) frequencies[3]++;
        else if (speed <= 100) frequencies[4]++;
        else frequencies[5]++;
      });
    });

    return { speedRanges, frequencies };
  };

  // Function to calculate driver behavior scores
  const calculateDriverBehavior = (tableData) => {
    const categories = [
      "Acceleration",
      "Braking",
      "Speeding",
      "Drowsiness",
      "Other Alerts",
    ];
    const scores = new Array(categories.length).fill(0);
    let totalTrips = 0;

    Object.values(tableData).forEach(({ tripData }) => {
      Object.values(tripData).forEach((trip) => {
        totalTrips++;
        // Calculate scores based on alert types
        scores[0] += trip.HRA || 0; // Acceleration
        scores[1] += trip.SPB || 0; // Braking
        scores[2] += trip.ASV || 0; // Speeding
        scores[3] += trip.DROW || 0; // Drowsiness
        scores[4] +=
          calculateTotalAlerts(trip) -
          ((trip.HRA || 0) +
            (trip.SPB || 0) +
            (trip.ASV || 0) +
            (trip.DROW || 0)); // Other alerts
      });
    });

    // Normalize scores
    return {
      categories,
      scores: scores.map((score) => (score / totalTrips) * 100),
    };
  };
  // Helper function to calculate average
  const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  // Speed Distribution Chart Configuration
  const getSpeedDistributionChart = () => ({
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
          columnWidth: "55%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "Speed Distribution",
        align: "center",
        style: { fontSize: "18px", fontWeight: 600 },
      },
      xaxis: {
        categories: speedData.speedRanges,
        title: {
          text: "Speed Range (km/h)",
        },
      },
      yaxis: {
        title: {
          text: "Number of Trips",
        },
      },
      colors: ["#008FFB"],
    },
    series: [
      {
        name: "Trips",
        data: speedData.frequencies,
      },
    ],
  });

  // Time-based Heatmap Configuration
  const getTimeHeatmapChart = () => {
    const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Generate sample heatmap data
    const generateHeatmapData = () => {
      return days.map((day, i) => ({
        name: day,
        data: Array.from({ length: 24 }, () => ({
          x: hours[Math.floor(Math.random() * hours.length)],
          y: Math.floor(Math.random() * 50),
        })),
      }));
    };

    return {
      options: {
        chart: {
          type: "heatmap",
          height: 350,
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Activity Heatmap by Time of Day",
          align: "center",
          style: { fontSize: "18px", fontWeight: 600 },
        },
        xaxis: {
          type: "category",
          categories: hours,
        },
        theme: {
          mode: "light",
          palette: "palette1",
        },
      },
      series: generateHeatmapData(),
    };
  };

  // Driver Behavior Radar Chart Configuration
  const getDriverBehaviorChart = () => ({
    options: {
      chart: {
        type: "radar",
        height: 350,
        toolbar: {
          show: true,
        },
      },
      title: {
        text: "Driver Behavior Analysis",
        align: "center",
        style: { fontSize: "18px", fontWeight: 600 },
      },
      xaxis: {
        categories: driverBehavior.categories,
      },
      yaxis: {
        show: false,
      },
      markers: {
        size: 4,
        hover: {
          size: 6,
        },
      },
    },
    series: [
      {
        name: "Driver Score",
        data: driverBehavior.scores,
      },
    ],
  });

  // Fuel Efficiency Trend Chart Configuration
  const getFuelEfficiencyChart = () => {
    // Generate sample dates for the last 30 days
    const dates = Array.from({ length: 30 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toISOString().split("T")[0];
    }).reverse();

    // Generate sample fuel efficiency data
    const fuelData = dates.map(() => ({
      efficiency: Math.random() * 5 + 8, // 8-13 km/L range
      cost: Math.random() * 50 + 150, // 150-200 cost range
    }));

    return {
      options: {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: true,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
        },
        stroke: {
          curve: "smooth",
          width: [2, 2],
        },
        title: {
          text: "Fuel Efficiency & Cost Trends",
          align: "center",
          style: { fontSize: "18px", fontWeight: 600 },
        },
        xaxis: {
          categories: dates,
          type: "datetime",
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
        yaxis: [
          {
            title: {
              text: "Fuel Efficiency (km/L)",
            },
          },
          {
            opposite: true,
            title: {
              text: "Fuel Cost",
            },
          },
        ],
        legend: {
          position: "top",
        },
      },
      series: [
        {
          name: "Fuel Efficiency",
          type: "line",
          data: fuelData.map((d) => d.efficiency),
        },
        {
          name: "Fuel Cost",
          type: "line",
          data: fuelData.map((d) => d.cost),
        },
      ],
    };
  };

  const calculateStatistics = (tableData) => {
    let totalTrips = 0;
    let totalDistance = 0;
    let totalDuration = 0;
    let totalAlerts = 0;
    let maxSpeed = 0;
    let totalSpeed = 0;
    let speedReadings = 0;

    Object.values(tableData).forEach(({ tripData }) => {
      Object.values(tripData).forEach((trip) => {
        totalTrips++;
        totalDistance += trip.total_distance;
        totalDuration += parseInt(trip.duration);
        totalAlerts += trip.total_alerts;
        if (trip.max_speed > maxSpeed) maxSpeed = trip.max_speed;
        if (trip.average_speed) {
          totalSpeed += trip.average_speed;
          speedReadings++;
        }
      });
    });

    const averageSpeed = speedReadings > 0 ? totalSpeed / speedReadings : 0;
    const fuelConsumption = totalDistance * 0.1; // Estimated fuel consumption
    const co2Emissions = fuelConsumption * 2.31; // Estimated CO2 emissions

    return {
      totalTrips,
      totalDistance: totalDistance.toFixed(2),
      totalDuration,
      totalAlerts,
      averageSpeed: averageSpeed.toFixed(2),
      averageDistance: (totalDistance / totalTrips).toFixed(2),
      averageDuration: Math.floor(totalDuration / totalTrips),
      maxSpeed: maxSpeed.toFixed(2),
      fuelConsumption: fuelConsumption.toFixed(2),
      co2Emissions: co2Emissions.toFixed(2),
    };
  };

  const getAlertsDistributionData = () => {
    // Sample alert types that might occur in a fleet management system
    const alertsData = {
      "Harsh Braking": 45,
      Speeding: 78,
      "Rapid Acceleration": 32,
      "Sharp Turning": 28,
      "Idle Time Exceeded": 15,
      "Fatigue Warning": 8,
      "Lane Departure": 22,
      "Collision Warning": 5,
      "Maintenance Due": 12,
      "Geofence Violation": 18,
    };

    return {
      options: {
        chart: {
          type: "pie",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        labels: Object.keys(alertsData),
        title: {
          text: "Alerts Distribution",
          align: "center",
          style: { fontSize: "18px", fontWeight: 600 },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "14px",
        },
        colors: [
          "#FF4560",
          "#008FFB",
          "#FEB019",
          "#00E396",
          "#775DD0",
          "#3F51B5",
          "#546E7A",
          "#D7263D",
          "#1E88E5",
          "#00ACC1",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: Object.values(alertsData),
    };
  };

  const getVehicleDistributionData = () => {
    const vehicleData = Object.entries(reportData.tableData).map(
      ([vehicle, data]) => ({
        name: vehicle,
        value: Object.keys(data.tripData).length,
      })
    );

    return {
      options: {
        chart: {
          type: "pie",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
        },
        labels: vehicleData.map((item) => item.name),
        title: {
          text: "Trip Distribution by Vehicle",
          align: "center",
          style: { fontSize: "18px", fontWeight: 600 },
        },
        legend: {
          position: "bottom",
          fontSize: "14px",
        },
        colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: vehicleData.map((item) => item.value),
    };
  };

  const getBarChartOptions = (categories) => {
    // Create a mapping for event codes to full names
    const eventLabels = {
      TS: "TS : Trip Start",
      ASV: "ASV : Accident Saved",
      AUB: "AUB : Automatic Braking",
      CAO: "CAO : CAS Overspeed",
      DIS: "DIS : Distraction",
      HRA: "HRA : Harsh Acceleration",
      LCH: "LCH : Lane Change",
      SPB: "SPB : Speed Bump",
      SUB: "SUB : Sudden Brake",
      TAL: "TAL : Tailgating",
      WRS: "WRS : Wrong Start",
      ALCF: "ALCF : Alcohol Fail",
      ALCP: "ALCP : Alcohol Pass",
      ALCT: "ALCT : Alcohol Timeout",
      ALM2: "ALM2 : Alarm 2",
      ALM3: "ALM3 : Alarm 3",
      DMSO: "DMSO: DMS Overspeed",
      DROW: "DROW: Drowsiness",
      NODR: "NODR : No Driver",
    };

    const colors = [
      "#FF4560",
      "#00E396",
      "#FEB019",
      "#008FFB",
      "#775DD0",
      "#F86624",
      "#2E294E",
      "#00B4D8",
      "#FF7F50",
      "#9B5DE5",
      "#F15BB5",
      "#4CAF50",
      "#FB8C00",
      "#9C27B0",
      "#3F51B5",
      "#03A9F4",
      "#E91E63",
      "#CDDC39",
      "#795548",
    ];

    return {
      chart: {
        type: "bar",
        background: "#fff",
        toolbar: {
          show: true,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          borderRadius: 4,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "12px",
        formatter: function (seriesName, opts) {
          const category = categories[opts.seriesIndex];
          return eventLabels[category] || category;
        },
        markers: {
          width: 8,
          height: 8,
          radius: 2,
        },
        itemMargin: {
          vertical: 5,
          horizontal: 10,
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: "Count",
          style: {
            fontSize: "12px",
          },
        },
        labels: {
          formatter: function (val) {
            return Math.floor(val);
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.floor(val) + " events";
          },
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const category = w.globals.labels[dataPointIndex];
          return (
            '<div class="p-2">' +
            '<span class="font-bold">' +
            eventLabels[category] +
            "</span><br>" +
            "Count: " +
            Math.floor(series[0][dataPointIndex]) +
            "</div>"
          );
        },
      },
      grid: {
        show: true,
        borderColor: "#f1f1f1",
        padding: {
          top: 10,
          right: 10,
          bottom: 20,
          left: 10,
        },
      },
    };
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const downloadPDF = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: `${reportData.title}_${
      formatTimestamp(reportData.date).formattedDate
    }`,
  });

  const vehicleDistribution = getVehicleDistributionData();
  const alertsDistribution = getAlertsDistributionData();

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div ref={tableRef} className="container mx-auto px-4 py-8">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 rounded-xl bg-white p-6 shadow-lg"
        >
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <div className="flex items-center space-x-4">
              <img src={Logo} alt="Logo" className="h-12" />
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  {reportData.title}
                </h1>
                <p className="text-gray-500">
                  Report Generated on{" "}
                  {formatTimestamp(reportData.date).formattedDate}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="text-right">
                <div className="flex items-center space-x-2">
                  <BiCalendar className="h-4 w-4 text-gray-500" />
                  <span className="text-gray-600">
                    {formatTimestamp(reportData.fromDate).formattedDate} -{" "}
                    {formatTimestamp(reportData.toDate).formattedDate}
                  </span>
                </div>
                {/* <div className="mt-1 flex items-center space-x-2">
                  <BiTime className="h-4 w-4 text-gray-500" />
                  <span className="text-gray-600">
                    {formatTimestamp(reportData.date).formattedTime}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </motion.div>

        {/* Navigation Tabs */}
        <div className="mb-8 rounded-xl bg-white p-4 shadow-lg">
          <div className="flex space-x-4 overflow-x-auto">
            {["overview", "charts", "details"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`rounded-lg px-4 py-2 font-medium transition-all ${
                  activeTab === tab
                    ? "bg-blue-500 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {activeTab === "overview" && (
          <>
            {/* Enhanced Statistics Cards */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
            >
              <StatCard
                title="Total Trips"
                value={reportData.statistics.totalTrips}
                icon="🚗"
                // trend="+12% from last period"
                color="blue"
              />
              <StatCard
                title="Total Distance"
                value={`${reportData.statistics.totalDistance} KM`}
                icon="📏"
                // trend="+8% from last period"
                color="green"
              />
              {/* <StatCard
                title="Average Speed"
                value={`${reportData.statistics.averageSpeed} KM/h`}
                icon="⚡"
                trend="-3% from last period"
                color="yellow"
              /> */}
              <StatCard
                title="Total Alerts"
                value={reportData.statistics.totalAlerts}
                icon="⚠️"
                // trend="-15% from last period"
                color="red"
              />
              {/* <StatCard
                title="CO2 Emissions"
                value={`${reportData.statistics.co2Emissions} kg`}
                icon="🌱"
                trend="-5% from last period"
                color="green"
              /> */}
              {/* <StatCard
                title="Fuel Consumption"
                value={`${reportData.statistics.fuelConsumption} L`}
                icon="⛽"
                trend="-7% from last period"
                color="orange"
              /> */}
              {/* <StatCard
                title="Max Speed"
                value={`${reportData.statistics.maxSpeed} KM/h`}
                icon="🏎️"
                trend="Highest recorded"
                color="purple"
              /> */}
              <StatCard
                title="Avg Duration"
                value={formatDuration(reportData.statistics.averageDuration)}
                icon="⏱"
                // trend="+5% from last period"
                color="indigo"
              />
            </motion.div>

            {/* Charts Grid */}
            <div className="mb-8 grid grid-cols-1 gap-6 lg:grid-cols-2">
              {/* <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="rounded-xl bg-white p-6 shadow-lg"
              >
                <ReactApexChart
                  options={vehicleDistribution.options}
                  series={vehicleDistribution.series}
                  type="pie"
                  height={400}
                />
              </motion.div> */}
              {/* <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                className="rounded-xl bg-white p-6 shadow-lg"
              >
                <ReactApexChart
                  options={alertsDistribution.options}
                  series={alertsDistribution.series}
                  type="pie"
                  height={400}
                />
              </motion.div> */}
            </div>
          </>
        )}

        {activeTab === "charts" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-6"
          >
            {/* Original Bar Charts */}
            {Object.entries(reportData.chartData).map(
              ([vehicle, data], index) => (
                <div key={index} className="rounded-xl bg-white p-6 shadow-lg">
                  <h3 className="mb-4 flex items-center text-xl font-semibold">
                    <BiBarChart className="mr-2 text-blue-600" />
                    {vehicle} - Event Distribution
                  </h3>
                  <ReactApexChart
                    options={getBarChartOptions(Object.keys(data))}
                    series={[
                      {
                        name: "Events",
                        data: Object.values(data),
                      },
                    ]}
                    type="bar"
                    height={400}
                  />
                </div>
              )
            )}

            {/* Speed Distribution Chart */}
            {/* <div className="rounded-xl bg-white p-6 shadow-lg">
              <ReactApexChart
                options={getSpeedDistributionChart().options}
                series={getSpeedDistributionChart().series}
                type="bar"
                height={350}
              />
            </div> */}

            {/* Time-based Heatmap */}
            {/* <div className="rounded-xl bg-white p-6 shadow-lg">
              <ReactApexChart
                options={getTimeHeatmapChart().options}
                series={getTimeHeatmapChart().series}
                type="heatmap"
                height={350}
              />
            </div> */}

            {/* Driver Behavior Analysis */}
            {/* <div className="rounded-xl bg-white p-6 shadow-lg">
              <ReactApexChart
                options={getDriverBehaviorChart().options}
                series={getDriverBehaviorChart().series}
                type="radar"
                height={350}
              />
            </div> */}

            {/* Fuel Efficiency Trends */}
            {/* <div className="rounded-xl bg-white p-6 shadow-lg">
              <ReactApexChart
                options={getFuelEfficiencyChart().options}
                series={getFuelEfficiencyChart().series}
                type="line"
                height={350}
              />
            </div> */}
          </motion.div>
        )}

        {activeTab === "details" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="overflow-hidden rounded-xl bg-white shadow-lg"
          >
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-4">
              <h2 className="text-lg font-semibold text-white">
                Detailed Trip Analysis
              </h2>
            </div>
            <div className="p-6">
              {Object.entries(reportData.tableData).map(
                ([vehicle, data], index) => (
                  <div key={index} className="mb-8">
                    <div className="mb-4 flex items-center justify-between">
                      <h3 className="flex items-center text-lg font-semibold">
                        <BiLogoSlackOld className="mr-2 text-blue-600" />
                        {vehicle}
                      </h3>
                      <span className="text-sm text-gray-500">
                        {Object.keys(data.tripData).length} trips recorded
                      </span>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="w-full text-sm">
                        <thead>
                          <tr className="bg-gray-50">
                            <th className="px-4 py-3 text-left font-semibold">
                              #
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Trip ID
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              VehicleReg
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Distance
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Duration
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Total Alerts
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Total DMS Alerts
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Total CAS Alerts
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Total ALC Alerts
                            </th>
                            <th className="px-4 py-3 text-left font-semibold">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(data.tripData).map((trip, idx) => (
                            <tr
                              key={idx}
                              className="border-b transition-colors hover:bg-gray-50"
                            >
                              <td className="px-4 py-3">{idx + 1}</td>
                              <td className="max-w-xs px-4 py-3 font-medium">
                                {trip.trip_id}
                              </td>
                              <td className="px-4 py-3">{trip.device_id}</td>
                              <td className="px-4 py-3">
                                {trip.total_distance?.toFixed(2)} KM
                              </td>
                              <td className="px-4 py-3">
                                {formatDuration(parseInt(trip.duration))}
                              </td>
                              <td className="px-4 py-3">
                                <span
                                  className={`rounded-full px-2 py-1 text-xs ${"bg-gray-100 text-gray-800"}`}
                                >
                                  {trip.total_alerts} alrts
                                </span>
                              </td>
                              <td className="px-4 py-3">
                                <span
                                  className={`rounded-full px-2 py-1 text-xs ${"bg-indigo-300 text-indigo-800"}`}
                                >
                                  {trip.total_DMS_alerts} alrts
                                </span>
                              </td>
                              <td className="px-4 py-3">
                                <span
                                  className={`rounded-full px-2 py-1 text-xs ${"bg-green-100 text-green-800"}`}
                                >
                                  {trip.total_CAS_alerts} alrts
                                </span>
                              </td>
                              <td className="px-4 py-3">
                                <span
                                  className={`rounded-full px-2 py-1 text-xs ${"bg-yellow-100 text-yellow-800"}`}
                                >
                                  {trip.total_ALC_alerts} alrts
                                </span>
                              </td>
                              <td className="px-4 py-3">
                                <Link
                                  to={`/trips/completed-trip/${trip.trip_id}`}
                                  className="font-medium text-blue-600 hover:text-blue-800"
                                >
                                  View Details →
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              )}
            </div>
          </motion.div>
        )}
      </div>

      {/* Floating Action Buttons */}
      <div className="fixed bottom-8 right-8 flex space-x-4">
        {/* <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={downloadPDF}
          className="flex items-center space-x-2 rounded-xl bg-blue-600 px-6 py-3 text-white shadow-lg transition-colors hover:bg-blue-700"
        >
          <BiDownload className="text-xl" />
          <span>Download Report</span>
        </motion.button> */}
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Link
            to="/reports"
            className="flex items-center space-x-2 rounded-xl bg-gray-800 px-6 py-3 text-white shadow-lg transition-colors hover:bg-gray-900"
          >
            <BiArrowBack className="text-xl" />
            <span>Back to Reports</span>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

// Enhanced Stat Card Component
const StatCard = ({ title, value, icon, trend, color }) => {
  const getColorClasses = (color) => {
    const classes = {
      blue: "bg-blue-50 text-blue-600",
      green: "bg-green-50 text-green-600",
      red: "bg-red-50 text-red-600",
      yellow: "bg-yellow-50 text-yellow-600",
      purple: "bg-purple-50 text-purple-600",
      indigo: "bg-indigo-50 text-indigo-600",
      orange: "bg-orange-50 text-orange-600",
    };
    return classes[color] || classes.blue;
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="rounded-xl bg-white p-6 shadow-lg transition-all duration-200"
    >
      <div className="mb-4 flex items-center justify-between">
        <span className={`rounded-lg p-2 text-2xl ${getColorClasses(color)}`}>
          {icon}
        </span>
        <span className="text-xs text-gray-500">{trend}</span>
      </div>
      <div>
        <p className="mb-1 text-sm text-gray-500">{title}</p>
        <p className="text-2xl font-bold text-gray-800">{value}</p>
      </div>
    </motion.div>
  );
};

export default ReportsTriveni;
