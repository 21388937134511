import React, { useState } from "react";
import VehicleTrips from "./VehicleTrips";
import VehicleSummary from "./VehicleSummary";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Calendar } from "primereact/calendar";
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";
import { CiCircleInfo } from "react-icons/ci";

const VehicleView = () => {
  const token = Cookies.get("token");
  const { vehicle_id } = useParams();
  const [excelData, setExcelData] = useState([null]);
  const [summaryData, setSummaryData] = useState([]);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  oneWeekAgo.setHours(23, 59, 59, 999);
  const epochStartInSeconds = Math.floor(oneWeekAgo.getTime() / 1000);
  const [selectedStartDate, setSelectedStartDate] =
    useState(epochStartInSeconds);
  const today = new Date();
  // Set the time to 23:59:59 (last second of the day)
  today.setHours(23, 59, 59, 999);
  const epochTodayInSeconds = Math.floor(today.getTime() / 1000);
  const [selectedEndDate, setSelectedEndDate] = useState(epochTodayInSeconds);
  const [vehiDetails, setVehiDetails] = useState([]);
  const [allFuelLoadData, setAllFuelLoadData] = useState({
    fuelTime: [],
    loadTime: [],
  });

  const [fuelTimeData, setFuelTimeData] = useState({
    time: [],
    fuel: [],
  });
  const [loadTimeData, setLoadTimeData] = useState({
    time: [],
    load: [],
  });

  function convertTimestampToDateTimeFormat(timestamp) {
    const date = new Date(timestamp * 1000); // Convert to milliseconds

    // Extract date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    // Extract time components
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0)
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Pad single digit minutes with a zero

    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
  }

  //Converting time into HH:MM:SS format
  const formatDuration = (durationInSeconds) => {
    // Calculate total minutes, rounding down
    const minutes = Math.floor(durationInSeconds / 60);

    // Return minutes only
    return minutes;
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/vehicleSummary/${vehicle_id}`,
        { from_epoch: selectedStartDate, to_epoch: selectedEndDate },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setSummaryData(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const vehiDetails = res.data.data?.filter(
          (item) => item.vehicle_id === vehicle_id
        );
        setVehiDetails(vehiDetails);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/loadfuelvehicleid/${vehicle_id}`,
        {
          from_epoch: selectedStartDate,
          to_epoch: selectedEndDate,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        const fuelTime = response.data.data.fuelData.map((data) => {
          const formatTimestamp = (timestamp) => {
            const date = new Date(timestamp * 1000);
            // Add 5 hours and 30 minutes
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);

            return `${date.getFullYear()}-${String(
              date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(
              2,
              "0"
            )}T${String(date.getHours()).padStart(2, "0")}:${String(
              date.getMinutes()
            ).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
          };
          const formattedTime = formatTimestamp(data.timestamp);
          return {
            ...data,
            timestamp: formattedTime,
          };
        });

        const loadTime = response.data.data.loadData.map((data) => {
          const formatTimestamp = (timestamp) => {
            const date = new Date(timestamp * 1000);
            // Add 5 hours and 30 minutes
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);

            return `${date.getFullYear()}-${String(
              date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(
              2,
              "0"
            )}T${String(date.getHours()).padStart(2, "0")}:${String(
              date.getMinutes()
            ).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
          };
          const formattedTime = formatTimestamp(data.timestamp);
          return {
            ...data,
            timestamp: formattedTime,
          };
        });

        setAllFuelLoadData((prevData) => {
          return {
            ...prevData,
            fuelTime: fuelTime,
            loadTime: loadTime,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [token, vehicle_id, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    // setFuelTimeData
    const fuel = allFuelLoadData?.fuelTime.map((data) => {
      return data.current_fuel;
    });
    const time = allFuelLoadData?.fuelTime.map((data) => {
      return data.timestamp;
    });
    const load = allFuelLoadData?.loadTime.map((data) => {
      return data.actual_load / 1000;
    });
    const loadtime = allFuelLoadData?.loadTime.map((data) => {
      return data.timestamp;
    });
    // time:[],
    // fuel:[]
    setFuelTimeData({
      fuel: fuel,
      time: time,
    });
    setLoadTimeData({
      time: loadtime,
      load: load,
    });
  }, [allFuelLoadData]);

  const handleDownload = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/getAllvehiTrips/${vehicle_id}`,
        {
          from_epoch: selectedStartDate,
          to_epoch: selectedEndDate,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setExcelData(res.data.data);

      if (!excelData) {
        console.log("Data is null or undefined");
        return;
      }

      // Ensure data is an array before proceeding
      if (!Array.isArray(excelData)) {
        console.log("Data is not an array");
        return;
      }

      // Assuming data is an array of objects
      const formattedData = excelData?.map((item) => ({
        Registration_Number: item.vehicle_reg_number,
        Type: item.vehicle_type_name,
        Trip_id: item.trip_id,
        Start_Time: convertTimestampToDateTimeFormat(item.trip_start_time),
        Stop_Time: convertTimestampToDateTimeFormat(item.trip_end_time),
        Distance: item.total_distance,
        Duration: formatDuration(item.duration),
        Alerts: item.total_alerts,
        Average_Speed: item.avg_spd.toFixed(2),
        Max_Speed: item.max_spd.toFixed(2),
      }));

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(formattedData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const dataExcel = new Blob([excelBuffer], { type: fileType });
      const fileName = summaryData?.vehicle_reg_number + fileExtension;

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(dataExcel);
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between sm-max:flex-col sm-max:justify-start">
        <div className="flex items-center justify-start gap-4">
          <h4 className="text-dark rounded-md border-[1.5px] border-gray-600 bg-gray-500 px-2 py-1 text-sm font-semibold text-[#fefefe]  dark:text-white">
            {vehiDetails[0]?.vehicle_reg_number
              ? vehiDetails[0]?.vehicle_reg_number
              : "--"}
          </h4>
          <h4 className="text-dark text-normal font-semibold text-gray-700 dark:text-white">
            {vehiDetails[0]?.vehicle_name}
          </h4>
        </div>
        <div className="mt-4 flex items-center justify-between gap-4 sm-max:mt-6">
          <p className="flex items-center gap-1 text-gray-700 sm-max:hidden">
            <CiCircleInfo />
            Select date range
          </p>
          <span className="p-float-label flex items-center">
            <Calendar
              inputId="start_date"
              value={
                selectedStartDate ? new Date(selectedStartDate * 1000) : null
              }
              className="p-dropdown h-8 rounded-lg border border-gray-300"
              onChange={(e) => {
                const selectedDate = e.value;
                const epochTimeInSeconds = selectedDate
                  ? Math.floor(selectedDate.getTime() / 1000)
                  : null;
                setSelectedStartDate(epochTimeInSeconds);
              }}
              dateFormat="dd/mm/yy"
            />
            <label
              htmlFor="start_date"
              className=" text-gray-600 dark:text-gray-150"
            >
              From
            </label>
          </span>
          <span className="p-float-label flex items-center">
            <Calendar
              inputId="end_date"
              value={selectedEndDate ? new Date(selectedEndDate * 1000) : null}
              className={`p-dropdown h-8 rounded-lg border border-gray-300 `}
              onChange={(e) => {
                const selectedDate = e.value;
                // Set the time to 23:59:59 (last second of the day)
                selectedDate.setHours(23, 59, 59, 999);
                const epochTimeInSeconds = Math.floor(
                  selectedDate.getTime() / 1000
                );
                setSelectedEndDate(epochTimeInSeconds);
              }}
              dateFormat="dd/mm/yy"
            />

            <label
              htmlFor="end_date"
              className="text-gray-600 dark:text-gray-150"
            >
              To
            </label>
          </span>
        </div>
      </div>
      <VehicleSummary
        data={summaryData}
        fuelTimeData={fuelTimeData}
        loadTimeData={loadTimeData}
      />
      <div className="-mt-4 sm-max:mt-2 mb-4 flex justify-end">
        <button
          className="flex items-center rounded-md border-[1px] border-gray-700 bg-white px-2 py-0.5 dark:bg-gray-800 dark:text-gray-100"
          onClick={handleDownload}
        >
          Download as excel&nbsp;
          <FaFileExcel className="text-gray-600" />
        </button>
      </div>
      <VehicleTrips startDate={selectedStartDate} endDate={selectedEndDate} />
    </>
  );
};

export default VehicleView;
