/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { Knob } from "primereact/knob";
import { TabPanel, TabView } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";
import { Badge } from "primereact/badge";
import Cookies from "js-cookie";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";
import axios from "axios";
// import Iframe from "react-iframe";
import { Dialog } from "primereact/dialog";
import simplify from "simplify-js";
import pin from "../../../../assets/img/pin.gif";
import drowsinessIcon from "../../../../assets/img/icons/drowsiness.png";
import accCutIcon from "../../../../assets/img/icons/accCut.png";
import { FaClock, FaMapPin } from "react-icons/fa";
import accidentCasIcon from "../../../../assets/img/icons/accidentCas.png";
import accidentdmsIcon from "../../../../assets/img/icons/accidentdms.png";
import alarm2Icon from "../../../../assets/img/icons/alarm2.png";
import alarm3Icon from "../../../../assets/img/icons/alarm3.png";
import automaticBrakingIcon from "../../../../assets/img/icons/automaticBraking.png";
import cvnIcon from "../../../../assets/img/icons/cvn.png";
import defaultIcon from "../../../../assets/img/icons/default.png";
import distractionIcon from "../../../../assets/img/icons/distraction.png";
import harshAccIcon from "../../../../assets/img/icons/harshAcc.png";
import laneChngIcon from "../../../../assets/img/icons/laneChng.png";
import nodriverIcon from "../../../../assets/img/icons/nodriver.png";
import overspeedDMSIcon from "../../../../assets/img/icons/overspdDMS.png";
import overspeedIcon from "../../../../assets/img/icons/overspeed.png";
import spdBumpIcon from "../../../../assets/img/icons/spdBump.png";
import suddenBrkIcon from "../../../../assets/img/icons/suddenBrk.png";
import tailgatingIcon from "../../../../assets/img/icons/tailgating.png";
import tripstartIcon from "../../../../assets/img/icons/tripstart.png";
import { ScrollPanel } from "primereact/scrollpanel";
import { io } from "socket.io-client";
import { AppContext } from "context/AppContext";
import { IoIosSpeedometer, IoMdSpeedometer, IoMdTime } from "react-icons/io";
import { RiPinDistanceLine, RiVerifiedBadgeFill } from "react-icons/ri";
import {
  MdAccessTime,
  MdLocationPin,
  MdFullscreen,
  MdSpeed,
} from "react-icons/md";
import LazyLoad from "react-lazy-load";

const containerStyle = {
  width: "100%",
  height: "350px",
  borderRadius: "10px",
  border: "2px solid #8994BC",
};

// Convert React Icons component to SVG string
const svgString = renderToStaticMarkup(
  <FaMapPin
    style={{
      textAlign: "center",
      color: "#23c723",
      fontSize: "25px",
      fontWeight: "600",
    }}
  />
);
// Convert SVG string to data URL
const svgUrl = `data:image/svg+xml;base64,${window.btoa(svgString)}`;
// customized marker icons
const markerIcons = {
  green: {
    url: pin,
    // scaledSize: new window.google.maps.Size(50, 40),
  },
  //   blue: {
  //     url: markerImage,
  //     // scaledSize: new window.google.maps.Size(30, 30),
  //   },
  blue: {
    url: svgUrl, // Replace with the actual path to your image
    // scaledSize: new window.google.maps.Size(32, 32), // Adjust the size as needed
  },
};

const TripInfoItem = ({ title, value, icon }) => (
  <div className="border-gray-200 pt-2 dark:border-cyan-800">
    <dt className="flex items-center font-medium text-gray-900 dark:text-white">
      {title}
      &nbsp;{icon}
    </dt>
    <dd className="mt-2 text-sm text-gray-700">{value}</dd>
  </div>
);

const OngoingTripView = () => {
  const token = Cookies.get("token");
  const { trip_id } = useParams();

  // const [isLoading, setIsLoading] = useState(true);
  const [path, setPath] = useState([]);
  const [cvnPath, setCVNPath] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tripData, setTripData] = useState([]);
  const [center, setCenter] = useState({ lat: 18.551803, lng: 73.81196 });
  const [startPoint, setStartPoint] = useState({
    lat: 18.551803,
    lng: 73.81196,
  });
  const [endPoint, setEndPoint] = useState({ lat: 18.551803, lng: 73.81196 });
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [distance, setDistance] = useState("");
  const [maxSpd, setMaxSpd] = useState("");
  const [avgSpd, setAvgSpd] = useState();
  const [duration, setDuration] = useState("");
  // eslint-disable-next-line
  const [epochStart, setEpochStart] = useState();
  // eslint-disable-next-line
  const [epochEnd, setEpochEnd] = useState();
  // Set braking freq
  const [brakingFreq, setBrakingFreq] = useState();
  const [haltTime, setHaltTime] = useState("");
  const [tripDurationInMins, settripDurationInMins] = useState(0);
  // for calculating the braking frequency
  const [haltTimeInMin, setHaltTimeInMin] = useState(0);
  // CAS faults
  const [accident, setAccident] = useState(0);
  const [harshacc, setHarshacc] = useState(0);
  // eslint-disable-next-line
  const [sleeptAlt, setSleepAlt] = useState(0);
  const [laneChng, setLaneChng] = useState(0);
  const [spdBump, setSpdBump] = useState(0);
  const [suddenBrk, setSuddenBrk] = useState(0);
  const [tailgating, setTailgating] = useState(0);
  const [overspeed, setOverspeed] = useState(0);
  const [accCutTipper, setAccCutTipper] = useState(0);
  const [cvn, setCVN] = useState(0);
  const [wrongCvn, setWrongCvn] = useState(0);
  const [load, setLoad] = useState(0);
  const [actualLoad, setActualLoad] = useState(0);
  const [fuel, setFuel] = useState(0);
  const [actualFuel, setActualFuel] = useState(0);
  const [accidentSaved, setAccidentSaved] = useState(0);

  // SET DMS data & Alerts
  // eslint-disable-next-line
  const [media, setMedia] = useState([]);
  const [drowsiness, setDrowsiness] = useState(0);
  const [distraction, setDistraction] = useState(0);
  const [dmsoverSpd, setDmsoverSpd] = useState(0);
  const [cameraTampering, setCameraTampering] = useState(0);
  // // eslint-disable-next-line
  // const [noSeatbelt, setNotSeatBelt] = useState(0);
  // // eslint-disable-next-line
  // const [usePhone, setUsePhone] = useState(0);
  // // eslint-disable-next-line
  // const [unknownDriver, setUnknownDriver] = useState(0);
  const [noDriver, setNoDriver] = useState(0);
  // eslint-disable-next-line
  // const [smoking, setSmoking] = useState(0);
  // eslint-disable-next-line
  // const [rashDrive, setRashDrive] = useState(0);
  // eslint-disable-next-line
  const [dmsAccident, setDmsAccident] = useState(0);
  const [tripStartAlert, setTripStartAlert] = useState(0);
  // eslint-disable-next-line

  const [vehicleTypeName, setVehicleTypeName] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);

  const [autoBrk, setAutoBrk] = useState(0);
  const [faultData, setFaultData] = useState([]);
  const [alarm1, setAlarm1] = useState(0);
  const [alarm2, setAlarm2] = useState(0);

  // Alchohol data set
  const [passAlc, setPassAlc] = useState(0);
  const [failAlc, setFailAlc] = useState(0);
  const [timeoutAlc, setTimeoutAlc] = useState(0);
  const [nonZeroAlc, setNonZeroAlc] = useState(0);

  // Set faultcount locations and data
  const [markers, setMarkers] = useState([]);
  console.log("markers::", markers);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [filterMarker, setFilterMarker] = useState([]);
  const alerts = [
    "Collision Avoidance System",
    "Sleep Alert",
    "Over Speed",
    "No Alarm",
    "Speed Pulses Absent",
    "Accelerator Bypassed",
    "Sensor Absent",
    "Gyroscope Absent",
    "HMI Absent",
    "RTC Error",
    "Brake Cylinder Error",
    "TPMS Absent",
    "OBD Absent",
    "RFID Absent",
    "Leddar Absent",
    "IOT Absent",
    "Fuel Board Absent",
    "DD Module Absent",
    "Alcohol Sensor Absent",
    "Temp Sensor Absent",
    "DMS Board Absent",
    "Load Board Absent",
    "Overload",
    "Fuel Theft",
  ];
  const [checkboxes, setCheckboxes] = useState({
    AUTOMATIC_BRAKING: false,
    ACCIDENT_SAVED: false,
    ACC_CUT: false,
    WRONG_CVN: false,
    Load: false,
    Harsh_Acceleration: false,
    Speed_Bump: false,
    Lane_Change: false,
    Sudden_Braking: false,
    Tailgating: false,
    Overspeeding: false,
    Alarm_2: false,
    Alarm_3: false,
    TRIP_START: false,
    DROWSINESS: false,
    DISTRACTION: false,
    OVERSPEEDING: false,
    NO_DRIVER: false,
    CAMERA_TAMPERING: false,
    ALCPass: false,
    ALCFail: false,
    ALCTimeout: false,
    ALCNonZero: false,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const videoRefs = useRef({});
  const [playingPairs, setPlayingPairs] = useState({});
  const videoContainerRefs = useRef([]);

  const toggleFullscreen = (index) => {
    const container = videoContainerRefs.current[index];
    if (container) {
      if (!document.fullscreenElement) {
        // Request fullscreen for the specific video
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          // Firefox
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          // Chrome, Safari, Opera
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          // IE/Edge
          container.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari, Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document.msExitFullscreen();
        }
      }
    } else {
      console.error("Video element not found!");
    }
  };
  // Play/pause specific video pair
  const handlePlayPause = (index) => {
    const mainVideo = videoRefs?.current[`main-${index}`];
    const dashVideo = videoRefs?.current[`dash-${index}`];

    // Check current playing state for this pair
    const isCurrentlyPlaying = playingPairs[index] || false;

    // Update state for this specific pair
    setPlayingPairs((prev) => ({
      ...prev,
      [index]: !isCurrentlyPlaying,
    }));

    // Control main video
    if (mainVideo) {
      if (isCurrentlyPlaying) {
        mainVideo?.pause();
      } else {
        mainVideo?.play();
      }
    }

    // Control dashcam video if available
    if (dashVideo) {
      if (isCurrentlyPlaying) {
        dashVideo.pause();
      } else {
        dashVideo.play();
      }
    }
  };
  const handleTabChange = (e) => {
    setActiveIndex(e.index);
  };
  const showVideo = () => {
    setActiveIndex(2);
  };

  // Get trip summary data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/tripDetails/${trip_id}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicleDetails(res.data.data);
        getTripSummary(res.data.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Socket IO
  useEffect(() => {
    // https://13.202.213.42:8080
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });
    socket.on("connect", () => {
      console.log("Connected to socker server");
    });
    socket.on(trip_id.toString(), (data) => {
      let resTripdata = data;
      updateLiveData(resTripdata);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDetails, trip_id]);

  const getTripSummary = (resTripdata) => {
    // setAvgSpd(resTripdata.avg_spd);
    // setDistance(resTripdata.distance);
    // setMaxSpd(resTripdata.max_spd);
    // setDuration(resTripdata.duration);
    const tripStartTime = new Date(resTripdata.startTime * 1000);
    const tripEndTime = new Date(resTripdata.endTime * 1000);
    setStartTime(tripStartTime.toLocaleString());
    setEndTime(tripEndTime.toLocaleString());
    setEpochStart(resTripdata.startTime);
    setEpochEnd(resTripdata.endTime);
    const tripDurationInSec = resTripdata.endTime - resTripdata.startTime;
    const tripDurationInMins = tripDurationInSec / 60;
    settripDurationInMins(Math.round(tripDurationInMins));

    // Set Map center
    if (resTripdata && resTripdata?.location_data) {
      const dataLength = resTripdata.location_data.length - 1;

      setCenter({
        lat: parseFloat(resTripdata.location_data[dataLength].latitude),
        lng: parseFloat(resTripdata.location_data[dataLength].longitude),
      });

      // Set start point
      setStartPoint({
        lat: parseFloat(resTripdata.location_data[0].latitude),
        lng: parseFloat(resTripdata.location_data[0].longitude),
      });

      setCoordinates(
        resTripdata.location_data.map((location) => ({
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
        }))
      );

      // Set end point
      setEndPoint({
        lat: parseFloat(resTripdata.location_data[dataLength].latitude),
        lng: parseFloat(resTripdata.location_data[dataLength].longitude),
      });
    }

    // Halt calculation
    const allTripData = resTripdata.location_data;
    let startHalt = 0;
    let endHalt = 0;
    let totalHalt = 0;
    allTripData?.forEach((tdata) => {
      if (tdata.speed <= 1 && startHalt === 0) {
        startHalt = tdata.timestamp;
      } else if (tdata.speed <= 1) {
        endHalt = tdata.timestamp;
      } else {
        startHalt = 0;
        endHalt = 0;
      }

      let timeDiff = endHalt - startHalt;

      if (timeDiff > 60) {
        totalHalt = totalHalt + timeDiff;
      }
    });
    const haltHr = Math.floor(totalHalt / 3600);
    const remainingSeconds = totalHalt % 3600;
    const haltMin = Math.floor(remainingSeconds / 60);
    const haltSec = remainingSeconds % 60;
    setHaltTime(`${haltHr} Hr : ${haltMin} Min : ${haltSec} Sec`);

    // set halt time in minutes for calculating braking freq
    const haltInMins = Math.floor(totalHalt / 60);
    setHaltTimeInMin(haltInMins);

    // Set all fault data
    setFaultData(resTripdata?.alerts);
  };

  const updateLiveData = (resTripdata) => {
    // setAvgSpd(resTripdata.avg_spd);
    // setDistance(resTripdata.distance);
    // setMaxSpd(resTripdata.max_spd);
    // setDuration(resTripdata.duration);
    const tripStartTime = new Date(resTripdata.startTime * 1000);
    const tripEndTime = new Date(resTripdata.location_data[0].timestamp * 1000);
    setEndTime(tripEndTime.toLocaleString());
    setEpochEnd(resTripdata.endTime);
    const tripDurationInSec = resTripdata.endTime - resTripdata.startTime;
    const tripDurationInMins = tripDurationInSec / 60;
    settripDurationInMins(Math.round(tripDurationInMins));

    const lastTimestamp =
      vehicleDetails?.location_data[vehicleDetails?.location_data?.length - 1]
        .Timestamp;

    if (
      resTripdata &&
      resTripdata?.location_data &&
      resTripdata?.location_data[0].timestamp > lastTimestamp
    ) {
      setCoordinates((prevData) => [
        ...prevData,
        {
          lat: parseFloat(resTripdata?.location_data[0]?.latitude),
          lng: parseFloat(resTripdata?.location_data[0]?.longitude),
        },
      ]);
      // Set end point
      setEndPoint({
        lat: parseFloat(resTripdata?.location_data[0]?.latitude),
        lng: parseFloat(resTripdata?.location_data[0]?.longitude),
      });
    }

    // Halt calculation
    const allTripData = resTripdata?.location_data;
    let startHalt = 0;
    let endHalt = 0;
    let totalHalt = 0;
    allTripData.forEach((tdata) => {
      if (tdata.speed <= 1 && startHalt === 0) {
        startHalt = parseInt(tdata.Timestamp);
      } else if (tdata.speed <= 1) {
        endHalt = parseInt(tdata.Timestamp);
      } else {
        let timeDiff = endHalt - startHalt;

        if (timeDiff > 60) {
          totalHalt = totalHalt + timeDiff;
        }
        startHalt = 0;
        endHalt = 0;
      }
    });

    const haltHr = Math.floor(totalHalt / 3600);
    const remainingSeconds = totalHalt % 3600;
    const haltMin = Math.floor(remainingSeconds / 60);
    const haltSec = remainingSeconds % 60;
    setHaltTime(`${haltHr} Hr : ${haltMin} Min : ${haltSec} Sec`);

    // set halt time in minutes for calculating braking freq
    const haltInMins = Math.floor(totalHalt / 60);
    setHaltTimeInMin(haltInMins);

    // Set all fault data
    if (resTripdata?.tripSummaryDetails[0].event !== "LOC") {
      if (faultData !== null) {
        setFaultData((prevData) => [
          ...prevData,
          resTripdata?.tripSummaryDetails[0],
        ]);
      } else {
        setFaultData([resTripdata?.tripSummaryDetails[0]]);
      }
    }
  };

  // Convert degrees to radians
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // useEffect(() => {
  //   // console.log(markers);
  //   console.log(filterMarker);
  // }, [markers, filterMarker]);

  // Set Address using google map api
  useEffect(() => {
    const getAddress = async (lat, lng, setAddress) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCBU08ZrxmwB_0xlKYf5GJ95yx_dWA2hb4`
        );

        const data = await response.json();
        if (data.results.length > 0) {
          setAddress(data.results[0].formatted_address);
        } else {
          setAddress("Loading address...");
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (startPoint.lat && startPoint.lng) {
      getAddress(startPoint.lat, startPoint.lng, setStartAddress);
      getAddress(endPoint.lat, endPoint.lng, setEndAddress);
    }
  }, [endPoint, startPoint]);

  // Get fault counts data
  // Get fault counts data
  useEffect(() => {
    // Set CAS Count
    let autoBrkCount = 0;
    let harshAccCount = 0;
    let sleepAltCount = 0;
    let laneChngCount = 0;
    let spdBumpCount = 0;
    let suddenBrkCount = 0;
    let tailgatingCount = 0;
    let overspeedCount = 0;
    let accSavedCount = 0;
    let alarm1Count = 0;
    let alarm2Count = 0;
    let accCutTipperCount = 0;
    let wrongCvnCount = 0;
    let cvnCount = 0;
    let fuelCount = 0;
    let loadCount = 0;

    let passAlcCount = 0;
    let failAlcCount = 0;
    let timeoutAlcCount = 0;
    let nonZeroAlcCount = 0;

    // DMS data
    let drowsinessCount = 0;
    let tripstartCount = 0;
    let distractionCount = 0;
    let overspdCount = 0;
    let cameraTamCount = 0;
    // let noSeatbeltCount = 0;
    // let usingMobCount = 0;
    // let unknownDriverCount = 0;
    let noDriverCount = 0;
    // let smokingCount = 0;
    // let rashDrivingCount = 0;
    let accidentCount = 0;

    // setFaultData(response.data.results);
    let parameters = [];
    let params = {};
    let myData = faultData;

    let mediaData = []; // dms media data

    myData?.forEach((item) => {
      let jsonDataa = JSON.parse(item.jsonData);
      if (item.event === "ACC") {
        if (jsonDataa.message === "7") {
          setAccCutTipper((prev) => prev + 1);
        }
      }
      // Braking data
      if (item.event === "BRK") {
        autoBrkCount++; // Set automatic braking count

        let ttcdiff = jsonDataa.data.on_ttc - jsonDataa.data.off_ttc;
        let acd = ttcdiff / jsonDataa.data.off_ttc;
        let accSvd = acd * 100;
        if (accSvd > 50 && accSvd < 100) {
          accSavedCount++; // Set accident saved count
        }
      }

      // Notification data
      if (item.event === "NTF" && jsonDataa.notification === 2) {
        harshAccCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 13) {
        sleepAltCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 5) {
        laneChngCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 4) {
        spdBumpCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 3) {
        suddenBrkCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 6) {
        tailgatingCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 7) {
        overspeedCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 16) {
        accCutTipperCount++;
      }
      if (item.event === "NTF" && jsonDataa.notification === 17) {
        wrongCvnCount++;
      }

      // Set Alarm data
      if (item.event === "ALM" && jsonDataa.data.alarm === 2) {
        alarm1Count++;
      }
      if (item.event === "ALM" && jsonDataa.data.alarm === 3) {
        alarm2Count++;
      }

      // DMS data
      if (item.event === "DMS") {
        let dmsTimeStamp = item.timestamp;
        let updatedmsTimeStamp = new Date(dmsTimeStamp * 1000);
        mediaData.push({
          dms: jsonDataa.data.media,
          dashcam: jsonDataa.data.dashcam,
          alert: jsonDataa.data.alert_type,
          timestamp: updatedmsTimeStamp.toLocaleString(),
        });
      }
      if (item.event === "DMS" && jsonDataa.data.alert_type === "DROWSINESS") {
        drowsinessCount++;
      }
      if (item.event === "DMS" && jsonDataa.data.alert_type === "TRIP_START") {
        tripstartCount++;
      }
      if (item.event === "DMS" && jsonDataa.data.alert_type === "DISTRACTION") {
        distractionCount++;
      }
      if (
        item.event === "DMS" &&
        jsonDataa.data.alert_type === "OVERSPEEDING"
      ) {
        overspdCount++;
      }
      // if (
      //   item.event === "DMS" &&
      //   jsonDataa.data.alert_type === "USING_PHONE"
      // ) {
      //   usingMobCount++;
      // }
      // if (
      //   item.event === "DMS" &&
      //   jsonDataa.data.alert_type === "NO_SEATBELT"
      // ) {
      //   noSeatbeltCount++;
      // }
      // if (
      //   item.event === "DMS" &&
      //   jsonDataa.data.alert_type === "UNKNOWN_DRIVER"
      // ) {
      //   unknownDriverCount++;
      // }
      if (item.event === "DMS" && jsonDataa.data.alert_type === "NO_DRIVER") {
        if (jsonDataa.data.speed < 1) {
          noDriverCount++;
        } else {
          cameraTamCount++;
        }
      }
      // if (item.event === "DMS" && jsonDataa.data.alert_type === "SMOKING") {
      //   smokingCount++;
      // }
      // if (
      //   item.event === "DMS" &&
      //   jsonDataa.data.alert_type === "RASH_DRIVING"
      // ) {
      //   rashDrivingCount++;
      // }
      if (item.event === "DMS" && jsonDataa.data.alert_type === "ACCIDENT") {
        accidentCount++;
      }

      // set CVN
      if (item.event === "CVN" && jsonDataa.data.status === 1) {
        cvnCount++;

        const cvnPathArray = [];
        // Set CVN path
        tripData.forEach((row) => {
          if (row.timestamp > jsonDataa.timestamp) {
            cvnPathArray.push({
              lat: parseFloat(row.lat),
              lng: parseFloat(row.lng),
            });
          }
        });
        // Set cvnPath to the array of lat/lng data
        setCVNPath(cvnPathArray);
      }

      // Set LOAD
      if (item.event === "LDS") {
        loadCount++;
        setActualLoad(jsonDataa.data?.actual_load);
      }

      // Set Fuel
      if (item.event === "FLS") {
        fuelCount++;
        setActualFuel(jsonDataa.data.percentage);
      }

      // Set Alchohol
      if (item.event === "ALC") {
        if (jsonDataa.data.result === 0) {
          failAlcCount++;
        }
        if (jsonDataa.data.result === 1) {
          passAlcCount++;
        }
        if (jsonDataa.data.result === 3) {
          timeoutAlcCount++;
        }
        if (jsonDataa.data.result === 6) {
          nonZeroAlcCount++;
        }
      }
    });

    // Set CAS Count
    setAutoBrk(autoBrkCount);
    setHarshacc(harshAccCount);
    setSleepAlt(sleepAltCount);
    setLaneChng(laneChngCount);
    setSpdBump(spdBumpCount);
    setSuddenBrk(suddenBrkCount);
    setTailgating(tailgatingCount);
    setOverspeed(overspeedCount);
    setAccidentSaved(accSavedCount);
    setAlarm1(alarm1Count);
    setAlarm2(alarm2Count);
    setAccCutTipper(accCutTipperCount);
    setWrongCvn(wrongCvnCount);
    setFuel(fuelCount);
    setLoad(loadCount);
    setCVN(cvnCount);

    // Set DMS count
    setMedia(mediaData);
    setDrowsiness(drowsinessCount);
    setDistraction(distractionCount);
    setDmsoverSpd(overspdCount);
    // setNotSeatBelt(noSeatbeltCount);
    // setUsePhone(usingMobCount);
    // setUnknownDriver(unknownDriverCount);
    setNoDriver(noDriverCount);
    setCameraTampering(cameraTamCount);
    // setSmoking(smokingCount);
    // setRashDrive(rashDrivingCount);
    setDmsAccident(accidentCount);
    setTripStartAlert(tripstartCount);

    setFailAlc(failAlcCount);
    setPassAlc(passAlcCount);
    setTimeoutAlc(timeoutAlcCount);
    setNonZeroAlc(nonZeroAlcCount);

    // loop to set markers
    if (myData && myData.length > 0) {
      for (let l = 0; l < myData.length; l++) {
        // parsing break json
        let parseJson = JSON.parse(myData[l].jsonData);
        //ACC Cut set data
        if (parseJson.event === "ACC") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          let params = {
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            title: 16,
            content: contentTime,
            speed: parseFloat(parseJson.td.spd),
            reason: parseJson.data?.reason,
            event: parseJson.event,
            icon: accidentCasIcon,
          };
          parameters.push(params);
        }
        if (parseJson.event === "BRK") {
          let ttcdiff = parseJson.data.on_ttc - parseJson.data.off_ttc;
          let acd = ttcdiff / parseJson.data.off_ttc;
          let accSvd = acd * 100;
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();

          // Set Accident save
          if (accSvd > 50 && accSvd < 100) {
            params = {
              id: parseJson.timestamp,
              lat: parseFloat(parseJson.td.lat),
              lng: parseFloat(parseJson.td.lng),
              title: "ACCIDENT_SAVED",
              content: contentTime,
              speed: parseFloat(parseJson.td.spd),
              event: parseJson.event,
              reason: parseJson.data.reason,
              brake_duration:
                parseJson.data.off_timestamp - parseJson.data.on_timestamp,
              on_speed: parseJson.data.on_speed,
              on_x_axis: parseJson.data.on_x_axis,
              on_y_axis: parseJson.data.on_y_axis,
              on_ttc: parseJson.data.on_ttc,
              on_rel_velocity: parseJson.data.on_rel_velocity,
              on_steering_angle: parseJson.data.on_steering_angle,
              off_speed: parseJson.data.off_speed,
              off_x_axis: parseJson.data.off_x_axis,
              off_y_axis: parseJson.data.off_y_axis,
              off_ttc: parseJson.data.off_ttc,
              off_rel_velocity: parseJson.data.off_rel_velocity,
              off_steering_angle: parseJson.data.off_steering_angle,
              brkspeed: parseJson.data.speed || parseJson.td.spd,
              icon: accidentCasIcon,
            };
            parameters.push(params);
          }

          params = {
            id: myData[l].id,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            title: "AUTOMATIC_BRAKING",
            content: contentTime,
            bypass: parseJson.data.bypass,
            speed: parseFloat(parseJson.td.spd),
            event: myData[l].event,
            reason: parseJson.data.reason,
            brake_duration:
              parseJson.data.off_timestamp - parseJson.data.on_timestamp,
            on_speed: parseJson.data.on_speed,
            on_x_axis: parseJson.data.on_x_axis,
            on_y_axis: parseJson.data.on_y_axis,
            on_ttc: parseJson.data.on_ttc,
            on_rel_velocity: parseJson.data.on_rel_velocity,
            on_steering_angle: parseJson.data.on_steering_angle,
            off_speed: parseJson.data.off_speed,
            off_x_axis: parseJson.data.off_x_axis,
            off_y_axis: parseJson.data.off_y_axis,
            off_ttc: parseJson.data.off_ttc,
            off_rel_velocity: parseJson.data.off_rel_velocity,
            off_steering_angle: parseJson.data.off_steering_angle,
            brkspeed: parseJson.data.speed || parseJson.td.spd,
            icon: automaticBrakingIcon,
            dash_video: parseJson?.data?.CAS_Dashcam,
          };
          parameters.push(params);
        }

        // DMS markers
        if (parseJson.event === "DMS") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          let dmsIcon = defaultIcon;
          if (parseJson.data.alert_type === "DROWSINESS") {
            dmsIcon = drowsinessIcon;
          } else if (parseJson.data.alert_type === "DISTRACTION") {
            dmsIcon = distractionIcon;
          } else if (parseJson.data.alert_type === "TRIP_START") {
            dmsIcon = tripstartIcon;
          } else if (parseJson.data.alert_type === "NO_DRIVER") {
            dmsIcon = nodriverIcon;
            params = {
              id: parseJson.id,
              lat: parseFloat(parseJson.td.lat),
              lng: parseFloat(parseJson.td.lng),
              title:
                parseJson.data.speed < 1
                  ? parseJson.data.alert_type
                  : "CAMERA_TAMPERING",
              content: contentTime,
              speed: parseJson.data.speed,
              event: parseJson.event,
              reason:
                parseJson.data.speed < 1
                  ? parseJson.data.alert_type
                  : "CAMERA_TAMPERING",
              alert_type:
                parseJson.data.speed < 1
                  ? parseJson.data.alert_type
                  : "CAMERA_TAMPERING",
              media: parseJson.data.media,
              dashcam: parseJson.data.dashcam,
              severity: parseJson.data.severity,
              icon: dmsIcon,
              rssi: parseJson.td.rssi,
            };
            parameters.push(params);
          } else if (parseJson.data.alert_type === "OVERSPEEDING") {
            dmsIcon = overspeedDMSIcon;
          } else if (parseJson.data.alert_type === "ACCIDENT") {
            dmsIcon = accidentdmsIcon;
          }
          if (parseJson.data.alert_type !== "NO_DRIVER") {
            params = {
              id: parseJson.id,
              lat: parseFloat(parseJson.td.lat),
              lng: parseFloat(parseJson.td.lng),
              title: parseJson.data.alert_type,
              content: contentTime,
              speed: parseJson.data.speed,
              event: parseJson.event,
              reason: parseJson.data.alert_type,
              alert_type: parseJson.data.alert_type,
              media: parseJson.data.media,
              dashcam: parseJson.data.dashcam,
              severity: parseJson.data.severity,
              icon: dmsIcon,
              rssi: parseJson.td.rssi,
            };
            parameters.push(params);
          }
        }

        // adding brk json to markers
        if (parseJson.notification !== undefined) {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          let ntfIcons = defaultIcon;
          if (parseJson.notification === 2) {
            ntfIcons = harshAccIcon;
          } else if (parseJson.notification === 3) {
            ntfIcons = suddenBrkIcon;
          } else if (parseJson.notification === 4) {
            ntfIcons = spdBumpIcon;
          } else if (parseJson.notification === 5) {
            ntfIcons = laneChngIcon;
          } else if (parseJson.notification === 6) {
            ntfIcons = tailgatingIcon;
          } else if (parseJson.notification === 7) {
            ntfIcons = overspeedIcon;
          } else if (parseJson.notification === 16) {
            ntfIcons = accCutIcon;
          } else if (parseJson.notification === 17) {
            ntfIcons = cvnIcon;
          }
          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            title: parseJson.notification,
            content: contentTime,
            speed: parseFloat(parseJson.td.spd),
            event: parseJson.event,
            reason: parseJson.notification,
            icon: ntfIcons,
          };
          parameters.push(params);
        }
        if (parseJson.event === "BRK") {
          params = {
            id: parseJson.id,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            title: parseJson.message,
            event: parseJson.event,
            reason: parseJson.data.reason,
            bypass: parseJson.data.bypass,
            speed: parseFloat(parseJson.td.spd),
            brake_duration:
              parseJson.data.off_timestamp - parseJson.data.on_timestamp,
          };

          parameters.push(params);
        }

        // ALM markers
        if (parseJson.event === "ALM") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          let almIcon = defaultIcon;
          if (parseJson.data.alarm === 2) {
            almIcon = alarm2Icon;
          } else {
            almIcon = alarm3Icon;
          }
          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            reason: parseJson.message,
            title: parseJson.message,
            speed: parseFloat(parseJson?.data?.speed || parseJson.td.spd),
            content: contentTime,
            event: parseJson.data.alarm === 2 ? "ALM2" : "ALM3",
            alarm_no: parseJson.data.alarm,
            x_axis: parseJson.data.x_axis,
            icon: almIcon,
          };
          parameters.push(params);
        }

        // Set CVN data
        if (parseJson.event === "CVN") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            reason: parseJson.message,
            title: parseJson.message,
            speed: Math.round(parseJson.td.spd),
            content: contentTime,
            event: parseJson.event,
            icon: cvnIcon,
          };
          parameters.push(params);
        }

        // Set Load data
        if (parseJson.event === "LDS") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            reason: parseJson.message,
            title: parseJson.message,
            speed: Math.round(parseJson.td.spd),
            content: contentTime,
            max_cap: parseJson.data?.max_cap + "Kg",
            percent: parseJson.data?.percentage + "%",
            actual_load: parseJson.data?.actual_load + "Kg",
            event: parseJson.event,
            icon: defaultIcon,
          };
          parameters.push(params);
        }

        // Set Fuel data
        if (parseJson.event === "FLS") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();
          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            reason: parseJson.message,
            title: parseJson.message,
            speed: Math.round(parseJson.td.spd),
            content: contentTime,
            current_fuel: parseJson.data.current_fuel?.toFixed(2) + "Ltr",
            theft_status: parseJson.data.Theft_Status,
            sensor_id: parseJson.data.Sensor_Id,
            low_fuel: parseJson.data.Low_Fuel,
            percent: parseJson.data.percentage?.toFixed(1) + "%",
            event: parseJson.event,
            icon: defaultIcon,
          };
          parameters.push(params);
        }
        // Set Alchohol data
        if (parseJson.event === "ALC") {
          let updatedTime = new Date(parseJson.timestamp * 1000);
          let contentTime = updatedTime.toLocaleString();

          params = {
            id: parseJson.timestamp,
            lat: parseFloat(parseJson.td.lat),
            lng: parseFloat(parseJson.td.lng),
            reason: parseJson.message,
            title: parseJson.data.result,
            speed: Math.round(parseJson.td.spd),
            content: contentTime,
            BAC: parseJson.data.BAC,
            result: parseJson.data.result,
            img: parseJson.data.img_url,
            vid: parseJson.data.vid_url,
            event: parseJson.event,
            icon: defaultIcon,
          };
          parameters.push(params);
        }
      }
    }
    setMarkers(parameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, trip_id, epochStart, epochEnd, faultData]);

  // Set braking frequency
  useEffect(() => {
    // Removing halt time from total trip duration
    const tripDurTime = tripDurationInMins - haltTimeInMin;
    if (autoBrk === 0) {
      setBrakingFreq(0);
    } else {
      const xTime = tripDurTime / autoBrk;
      setBrakingFreq(xTime.toFixed(2));
    }
  }, [autoBrk, tripDurationInMins, haltTimeInMin]);

  // Set the stroke color based on whether it's a special route
  const polylineOptions = {
    strokeWeight: 4,
  };
  const [cvnRoute, setCvnRoute] = useState(false);
  if (cvnRoute) {
    polylineOptions.strokeColor = "#FF5733"; // Change the color for the special route
  } else {
    polylineOptions.strokeColor = "#4252E0"; // Default color
  }

  // Set DMS media and event counts
  useEffect(() => {
    if (faultData?.length > 0) {
      let mediaData = [];
      faultData?.forEach((item) => {
        let dmsdata = JSON.parse(item.jsonData);
        if (dmsdata.event === "DMS") {
          let dmsTimeStamp = dmsdata.timestamp;
          let updatedmsTimeStamp = new Date(dmsTimeStamp * 1000);
          mediaData.push({
            dms: dmsdata.data.media,
            dashcam: dmsdata.data.dashcam,
            alert: dmsdata.data.alert_type,
            timestamp: updatedmsTimeStamp.toLocaleString(),
          });
        }
      });

      setMedia(mediaData);
    }
  }, [faultData]);

  // Set optimized path that will eliminate jumping
  useEffect(() => {
    // Calculate distance between two coordinates using Haversine formula
    function getDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = deg2rad(lat2 - lat1);
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    }

    // Specify the maximum distance threshold in kilometers
    const maxDistanceThreshold = 1; // Adjust as needed

    // Specify the tolerance level to control the amount of simplification
    const tolerance = 0.00005;

    // Convert the fetched coordinates to a format that simplify-js accepts
    const simplifiedPath = coordinates.map((coord) => ({
      x: coord.lat,
      y: coord.lng,
    }));
    // Simplify the path using the simplify function
    const simplifiedCoordinates = simplify(simplifiedPath, tolerance).map(
      (coord) => ({ lat: coord.x, lng: coord.y })
    );

    // Filter coordinates based on distance threshold
    const filteredCoordinates = [simplifiedCoordinates[0]];
    for (let i = 1; i < simplifiedCoordinates.length; i++) {
      const prevCoord = simplifiedCoordinates[i - 1];
      const currCoord = simplifiedCoordinates[i];

      const distance = getDistance(
        prevCoord.lat,
        prevCoord.lng,
        currCoord.lat,
        currCoord.lng
      );
      if (distance <= maxDistanceThreshold) {
        filteredCoordinates.push(currCoord);
      }
    }

    setPath(filteredCoordinates);
  }, [tripData, coordinates]);

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  // Summary tab
  const SummaryContent = () => (
    <div className="sm-max:-ml-32">
      <dl className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2 sm:gap-y-2 sm-max:grid-cols-2 lg:gap-x-8">
        <TripInfoItem
          title="Distance"
          icon={<RiPinDistanceLine className="text-orange-600" />}
          value={distance ? distance + " KM" : "--"}
        />
        <TripInfoItem
          title="Duration"
          icon={<MdAccessTime className="text-gray-700" />}
          value={duration ? formatDuration(parseInt(duration)) : "--"}
        />
        <TripInfoItem
          title="Average Speed"
          icon={<MdSpeed className="text-blue-600" />}
          value={avgSpd ? avgSpd + " Kmph " : "--"}
        />
        <TripInfoItem
          title="Max Speed"
          icon={<MdSpeed className="text-blue-600" />}
          value={maxSpd ? maxSpd + " Kmph " : "--"}
        />
        <TripInfoItem
          title="Source"
          value={startAddress + " " + startTime}
          icon={<MdLocationPin className="text-green-600" />}
        />
        <TripInfoItem
          title="Destination"
          value={endAddress + " " + endTime}
          icon={<MdLocationPin className="text-red-600" />}
        />
      </dl>
    </div>
  );

  // DMS tab
  const DMSContent = () => (
    <>
      <div className="cas">
        <div className="opt1 flex gap-4">
          <div className="opt2  w-[50%]">
            <div className=" opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="TRIP_START"
                  onChange={handlecheckbox}
                  name="TRIP_START"
                  checked={checkboxes?.TRIP_START}
                  disabled={tripStartAlert === 0}
                />
                <label
                  htmlFor="TRIP_START"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  Trip Start
                </label>
              </div>
              <div className="flex-shrink-0">
                {tripStartAlert === 0 ? (
                  <Badge
                    value={tripStartAlert}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={tripStartAlert} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="OVERSPEEDING"
                  onChange={handlecheckbox}
                  name="OVERSPEEDING"
                  checked={checkboxes?.OVERSPEEDING}
                  disabled={dmsoverSpd === 0}
                />
                <label
                  htmlFor="CAScheckboxId4"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  Overspeed
                </label>
              </div>
              <div className="flex-shrink-0">
                {dmsoverSpd === 0 ? (
                  <Badge
                    value={dmsoverSpd}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={dmsoverSpd} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="DROWSINESS"
                  onChange={handlecheckbox}
                  name="DROWSINESS"
                  checked={checkboxes?.DROWSINESS}
                  disabled={drowsiness === 0}
                />
                <label
                  htmlFor="DROWSINESS"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  Drowsiness
                </label>
              </div>
              <div className="flex-shrink-0">
                {drowsiness === 0 ? (
                  <Badge
                    value={drowsiness}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={drowsiness} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="NO_DRIVER"
                  onChange={handlecheckbox}
                  name="NO_DRIVER"
                  checked={checkboxes?.NO_DRIVER}
                  disabled={noDriver === 0}
                />
                <label
                  htmlFor="CAScheckboxId5"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  No Driver
                </label>
              </div>
              <div className="flex-shrink-0">
                {noDriver === 0 ? (
                  <Badge
                    value={noDriver}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={noDriver} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="DISTRACTION"
                  onChange={handlecheckbox}
                  name="DISTRACTION"
                  checked={checkboxes?.DISTRACTION}
                  disabled={distraction === 0}
                />
                <label
                  htmlFor="CAScheckboxId3"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  Distraction
                </label>
              </div>
              <div className="flex-shrink-0">
                {distraction === 0 ? (
                  <Badge
                    value={distraction}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={distraction} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="CAMERA_TAMPERING"
                  onChange={handlecheckbox}
                  name="CAMERA_TAMPERING"
                  checked={checkboxes.CAMERA_TAMPERING}
                  disabled={cameraTampering === 0}
                />
                <label
                  htmlFor="CAScheckboxId5"
                  className="ml-2 w-[7vw] dark:text-white"
                >
                  Camera Tampering
                </label>
              </div>
              <div className="flex-shrink-0">
                {cameraTampering === 0 ? (
                  <Badge
                    value={cameraTampering}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={cameraTampering} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // Set videos for DMS
  // const dmsIframes = [].concat(...filterMarker).map((data, index) => {
  //   // const checkDriveUrl = "drive.google.com";
  //   // const isDriveUrl = data.dms.includes(checkDriveUrl);
  //   const dashLink = data.dashcam;
  //   const dashCamVid = dashLink?.startsWith("/var/www/html/media/")
  //     ? dashLink.substring(20)
  //     : dashLink;
  //   let dmsContentData = null;
  //   if (data.event === "DMS") {
  //     const fileExtension = data.media.split(".").pop().toLowerCase();
  //     dmsContentData = (
  //       <div className="group relative" key={index}>
  //         <div className="card">
  //           <div className="aspect-h-1 aspect-w-1 lg:aspect-none lg:h-50 mt-1 w-full overflow-hidden rounded-md bg-gray-200">
  //             {fileExtension === "jpg" ? (
  //               <img
  //                 src={`${process.env.REACT_APP_S3_URL}/${data.media}`}
  //                 alt=""
  //                 srcset=""
  //               />
  //             ) : (
  //               <video
  //                 className="h-full w-full object-cover object-center lg:h-full lg:w-full"
  //                 width="100%"
  //                 controls
  //                 key={data.media}
  //               >
  //                 <source
  //                   src={`${process.env.REACT_APP_S3_URL}/${data.media}`}
  //                   type="video/mp4"
  //                 ></source>
  //                 Your browser does not support the video tag.
  //               </video>
  //             )}

  //             {data.dashcam && (
  //               <>
  //                 <video
  //                   className="h-full w-full object-cover object-center lg:h-full lg:w-full"
  //                   width="100%"
  //                   controls
  //                   key={dashCamVid}
  //                 >
  //                   <source
  //                     src={`${process.env.REACT_APP_S3_URL}/${dashCamVid}`}
  //                     type="video/mp4"
  //                   ></source>
  //                   Your browser does not support the video tag.
  //                 </video>
  //               </>
  //             )}
  //           </div>
  //           <div className="flex">
  //             <h3 className="text-gray-700">
  //               {data?.speed > 0 && data.alert_type === "NO_DRIVER"
  //                 ? "Camera Tampering"
  //                 : data.alert_type}
  //             </h3>
  //             <img
  //               src={data?.icon}
  //               alt={data.alert_type + index}
  //               className="img-fluid mx-2"
  //             />
  //           </div>
  //           <p className="mt-1 flex items-center text-sm text-gray-500">
  //             <IoIosSpeedometer />
  //             &nbsp; {data?.speed} Kmph
  //           </p>
  //           <p className="mt-1 flex items-center text-sm text-gray-500">
  //             <FaClock />
  //             &nbsp; {data?.content}
  //           </p>
  //         </div>
  //       </div>
  //     );
  //   }
  //   return dmsContentData;
  // });

  // Set videos for DMS
  // const dmsALCMedias = [].concat(...filterMarker).map((data, index) => {
  //   let mediaEventTitle = "";
  //   let dmsContentData = null;
  //   if (data.event === "ALC") {
  //     if (data.title === 0) {
  //       mediaEventTitle = "Fail";
  //     }
  //     if (data.title === 1) {
  //       mediaEventTitle = "Pass";
  //     }
  //     if (data.title === 3) {
  //       mediaEventTitle = "Timeout";
  //     }
  //     // if (data.title === 6) {
  //     //   mediaEventTitle = "Non Zero Speed";
  //     // }
  //     dmsContentData = (
  //       <div className="group relative" key={index}>
  //         <div>
  //           <div className="flex">
  //             <h3 className="text-gray-700">{mediaEventTitle}</h3>
  //             <img
  //               src={data?.icon}
  //               alt={data.alert_type + index}
  //               className="img-fluid mx-2"
  //             />
  //           </div>
  //           <p className="mt-1 text-sm text-gray-500">{data?.content}</p>
  //         </div>
  //         <div className="aspect-h-1 aspect-w-1 lg:aspect-none lg:h-50 w-full overflow-hidden rounded-md bg-gray-200">
  //           {data.vid ? (
  //             <video
  //               className="h-full w-full object-cover object-center lg:h-full lg:w-full"
  //               width="100%"
  //               controls
  //               key={data.vid}
  //             >
  //               <source
  //                 src={`${process.env.REACT_APP_S3_URL}/${data.vid}`}
  //                 type="video/mp4"
  //               ></source>
  //               Your browser does not support the video tag.
  //             </video>
  //           ) : (
  //             <p>
  //               <small>{null}</small>
  //             </p>
  //           )}

  //           {data.img ? (
  //             <img
  //               src={`${process.env.REACT_APP_S3_URL}/${data.img}`}
  //               alt="dms"
  //             />
  //           ) : (
  //             <p>
  //               <small>Image Unavailable!</small>
  //             </p>
  //           )}
  //         </div>
  //       </div>
  //     );
  //   }
  //   return dmsContentData;
  // });

  // DMS pop-up marker data
  const [visible, setVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [dashCamVideo, setDashCamVideo] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoContent, setVideoContent] = useState("");
  const [videoSpeed, setVideoSpeed] = useState("");
  const [videoAlert, setVideoAlert] = useState("");
  const [videoSeverity, setVideoSeverity] = useState("");
  const [videoBAC, setVideoBAC] = useState("");
  const [dmsImg, setDMSImg] = useState("");

  // DMS pop-up marker video data
  function handleDMSVideoShow(
    url,
    dashcamVid,
    title,
    content,
    speed,
    alert_type,
    severity
  ) {
    const checkDriveUrl = "drive.google.com";
    if (url.includes(checkDriveUrl)) {
      setVideoUrl(url);
    } else {
      setVideoUrl(`${process.env.REACT_APP_S3_URL}/${url}`);
    }

    if (dashcamVid) {
      const dashCamVideo = dashcamVid?.startsWith("/var/www/html/media/")
        ? dashcamVid.substring(20)
        : dashcamVid;
      if (dashCamVideo.includes(checkDriveUrl)) {
        setDashCamVideo(dashCamVideo);
      } else {
        setDashCamVideo(`${process.env.REACT_APP_S3_URL}/${dashCamVideo}`);
      }
    }

    setVideoTitle(title);
    setVideoContent(content);
    setVideoSpeed(speed);
    setVideoAlert(alert_type);
    setVideoSeverity(severity);
    setVisible(true);
  }

  // DMS Alchohol pop-up marker video data
  function handleDMSALCVideoShow(
    url,
    dmsimg,
    title,
    content,
    speed,
    alert_type,
    bac
  ) {
    if (url && title !== 3) {
      setVideoUrl(`${process.env.REACT_APP_S3_URL}/${url}`);
    }
    if (dmsimg) {
      setDMSImg(`${process.env.REACT_APP_S3_URL}/${dmsimg}`);
    }

    if (title === 1) {
      let vidTitle = "Test Pass";
      setVideoTitle(vidTitle);
    }
    if (title === 0) {
      let vidTitle = "Test Fail";
      setVideoTitle(vidTitle);
    }
    if (title === 3) {
      let vidTitle = "Timeout";
      setVideoTitle(vidTitle);
    }
    // if (title === 6) {
    //   let vidTitle = "Non Zero Speed";
    //   setVideoTitle(vidTitle);
    // }

    setVideoContent(content);
    setVideoSpeed(speed);
    setVideoAlert(alert_type);
    setVideoBAC(bac);
    setVisible(true);
  }

  // Handle event checkbox
  const handlecheckbox = (e) => {
    const { value, name, checked } = e.target;
    // console.log(e.target);
    // Create a mapping object for custom attributes
    const customAttributes = {
      AUTOMATIC_BRAKING: {
        AUTOMATIC_BRAKING: "BRK",
        ACCIDENT_SAVED: "BRK",
        ACCIDENT_SAVED_6: "BRK", // If needed, you can specify different values for ACCIDENT_SAVED with value 6
      },
      ACCIDENT_SAVED: { ACCIDENT_SAVED: "BRK" },
      ACC_CUT: { 16: "ACC" },
      Harsh_Acceleration: { 2: "NTF" },
      Sudden_Braking: { 3: "NTF" },
      Speed_Bump: { 4: "NTF" },
      Lane_Change: { 5: "NTF" },
      Tailgating: { 6: "NTF" },
      WRONG_CVN: { 17: "NTF" },
      Overspeeding: { 7: "NTF" },
      Alarm_2: { 5: "ALM2" },
      Alarm_3: { 5: "ALM3" },
      TRIP_START: { TRIP_START: "DMS" },
      DROWSINESS: { DROWSINESS: "DMS" },
      DISTRACTION: { DISTRACTION: "DMS" },
      OVERSPEEDING: { OVERSPEEDING: "DMS" },
      NO_DRIVER: { NO_DRIVER: "DMS" },
      CAMERA_TAMPERING: { CAMERA_TAMPERING: "DMS" },
      Load: { 34: "LDS" },
      CVN: { 36: "CVN" },
      FLS: { 35: "FLS" },
      ALCFail: { 0: "ALC" },
      ALCPass: { 1: "ALC" },
      ALCTimeout: { 3: "ALC" },
      ALCNonZero: { 6: "ALC" },
    };

    // Get the custom attribute based on the name and value

    const customAttribute = customAttributes[name]
      ? customAttributes[name][value]
      : undefined;

    if (checked) {
      const x = markers.filter(
        (el) => el.title === value && el.event === customAttribute
      );
      setFilterMarker([...filterMarker, x]);
    } else {
      const y = []
        .concat(...filterMarker)
        .filter((el) => !(el.title === value && el.event === customAttribute));
      setFilterMarker([y]);
    }

    // Handle specific actions based on customAttribute if needed
    if (customAttribute === "CVN") {
      setCvnRoute(true);
    }

    // Update checkboxes state
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: !prevCheckboxes[name],
    }));
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const [verifiedResults, setVerifiedResults] = useState({});

  useEffect(() => {
    const processNewImages = async () => {
      const alcData = []
        .concat(...filterMarker)
        .filter(
          (data) => data.event === "ALC" && data?.title === 1 && data.img
        );

      for (const data of alcData) {
        if (!verifiedResults[data.img]) {
          // Avoid duplicate API calls
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_AWS_URL1}/driverVerify`,
              {
                S3URL: `${process.env.REACT_APP_S3_URL}/${data.img}`,
              }
            );

            setVerifiedResults((prevResults) => ({
              ...prevResults,
              [data.img]: response.data.data, // Store response against image src
            }));
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    processNewImages();
  }, [filterMarker]);

  // CAS tab
  const CASContent = () => (
    <>
      {/* CAS */}
      <div className="cas">
        <h4 className="font-semibold">CAS</h4>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="AUTOMATIC_BRAKING"
                  onChange={handlecheckbox}
                  name="AUTOMATIC_BRAKING"
                  checked={checkboxes?.AUTOMATIC_BRAKING}
                  disabled={autoBrk === 0}
                />
                <label className="ml-2 dark:text-white">
                  Automatic Braking
                </label>
              </div>
              <div className="flex-shrink-0">
                {autoBrk === 0 ? (
                  <Badge
                    value={autoBrk}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={autoBrk} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value="ACCIDENT_SAVED"
                  onChange={handlecheckbox}
                  name="ACCIDENT_SAVED"
                  checked={checkboxes?.ACCIDENT_SAVED}
                  disabled={accident === 0}
                />
                <label className="ml-2 dark:text-white">Accident Saved</label>
              </div>
              <div className="flex-shrink-0">
                {accident === 0 ? (
                  <Badge
                    value={accident}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={accident} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      {/* Driver Evaluation */}
      <div className="drive-eval mt-4">
        <h4 className="font-semibold">Driver Evaluation</h4>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={2}
                  onChange={handlecheckbox}
                  name="Harsh_Acceleration"
                  checked={checkboxes?.Harsh_Acceleration}
                  disabled={harshacc === 0}
                />
                <label className="ml-2 dark:text-white">
                  Harsh Acceleration
                </label>
              </div>
              <div className="flex-shrink-0">
                {harshacc === 0 ? (
                  <Badge
                    value={harshacc}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={harshacc} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={4}
                  onChange={handlecheckbox}
                  name="Speed_Bump"
                  checked={checkboxes?.Speed_Bump}
                  disabled={spdBump === 0}
                />
                <label className="ml-2 dark:text-white">Speed Bump</label>
              </div>
              <div className="flex-shrink-0">
                {spdBump === 0 ? (
                  <Badge
                    value={spdBump}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={spdBump} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={5}
                  onChange={handlecheckbox}
                  name="Lane_Change"
                  checked={checkboxes?.Lane_Change}
                  disabled={laneChng === 0}
                />
                <label className="ml-2 dark:text-white">Lane Change</label>
              </div>
              <div className="flex-shrink-0">
                {laneChng === 0 ? (
                  <Badge
                    value={laneChng}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={laneChng} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={3}
                  onChange={handlecheckbox}
                  name="Sudden_Braking"
                  checked={checkboxes?.Sudden_Braking}
                  disabled={suddenBrk === 0}
                />
                <label className="ml-2 dark:text-white">Sudden Braking</label>
              </div>
              <div className="flex-shrink-0">
                {suddenBrk === 0 ? (
                  <Badge
                    value={suddenBrk}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={suddenBrk} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="opt1 flex gap-4">
          {/* <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={6}
                  onChange={handlecheckbox}
                  name="Tailgating"
                  checked={checkboxes?.Tailgating}
                  disabled={tailgating === 0}
                />
                <label
                  htmlFor="CAScheckboxId5"
                  className="ml-2 dark:text-white"
                >
                  Tailgating
                </label>
              </div>
              <div className="flex-shrink-0">
                {tailgating === 0 ? (
                  <Badge
                    value={tailgating}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={tailgating} className="mx-3" />
                )}
              </div>
            </div>
          </div> */}
          <div className="opt2 w-[50%]"></div>
        </div>
        <hr />
      </div>

      {/* Notifications */}
      <div className="noti mt-4">
        <h4 className="font-semibold">Notifications</h4>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={16}
                  onChange={handlecheckbox}
                  name="ACC_CUT"
                  checked={checkboxes.ACC_CUT}
                  disabled={accCutTipper === 0}
                />
                <label className="ml-2 dark:text-white">ACC Cut</label>
              </div>
              <div className="flex-shrink-0">
                {accCutTipper === 0 ? (
                  <Badge
                    value={accCutTipper}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={accCutTipper} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={17}
                  onChange={handlecheckbox}
                  name="WRONG_CVN"
                  checked={checkboxes?.WRONG_CVN}
                  disabled={wrongCvn === 0}
                />
                <label className="ml-2 dark:text-white">Wrong Start</label>
              </div>
              <div className="flex-shrink-0">
                {wrongCvn === 0 ? (
                  <Badge
                    value={wrongCvn}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={wrongCvn} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={34}
                  onChange={handlecheckbox}
                  name="Load"
                  checked={checkboxes?.Load}
                  disabled={load === 0}
                />
                <label className="ml-2 dark:text-white">Load</label>
              </div>
              <div className="flex-shrink-0">
                {load === 0 ? (
                  <Badge
                    value={load}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={load} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={35}
                  onChange={handlecheckbox}
                  name="FLS"
                  checked={checkboxes?.FLS}
                  disabled={fuel === 0}
                />
                <label className="ml-2 dark:text-white">Fuel</label>
              </div>
              <div className="flex-shrink-0">
                {fuel === 0 ? (
                  <Badge
                    value={fuel}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={fuel} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={36}
                  onChange={handlecheckbox}
                  name="CVN"
                  checked={checkboxes?.CVN}
                  disabled={cvn === 0}
                />
                <label className="ml-2 dark:text-white">CVN</label>
              </div>
              <div className="flex-shrink-0">
                {cvn === 0 ? (
                  <Badge
                    value={cvn}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={cvn} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={6}
                  onChange={handlecheckbox}
                  name="Tailgating"
                  checked={checkboxes.Tailgating}
                  disabled={tailgating === 0}
                />
                <label
                  htmlFor="CAScheckboxId5"
                  className="ml-2 dark:text-white"
                >
                  Tailgating
                </label>
              </div>
              <div className="flex-shrink-0">
                {tailgating === 0 ? (
                  <Badge
                    value={tailgating}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={tailgating} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      {/* Speed Governer */}
      <div className="spdGov mt-4">
        <h4 className="font-semibold">Speed Governer</h4>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={7}
                  onChange={handlecheckbox}
                  name="Overspeeding"
                  checked={checkboxes?.Overspeeding}
                  disabled={overspeed === 0}
                />
                <label className="ml-2 dark:text-white">Overspeed</label>
              </div>
              <div className="flex-shrink-0">
                {overspeed === 0 ? (
                  <Badge
                    value={overspeed}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={overspeed} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      {/* Alarm Data */}
      <div className="noti mt-4">
        <h4 className="font-semibold">Alarm Data</h4>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={5}
                  onChange={handlecheckbox}
                  name="Alarm_2"
                  checked={checkboxes?.Alarm_2}
                  disabled={alarm1 === 0}
                />
                <label className="ml-2 dark:text-white">Alarm 2</label>
              </div>
              <div className="flex-shrink-0">
                {alarm1 === 0 ? (
                  <Badge
                    value={alarm1}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={alarm1} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={5}
                  onChange={handlecheckbox}
                  name="Alarm_3"
                  checked={checkboxes?.Alarm_3}
                  disabled={alarm2 === 0}
                />
                <label className="ml-2 dark:text-white">Alarm 3</label>
              </div>
              <div className="flex-shrink-0">
                {alarm2 === 0 ? (
                  <Badge
                    value={alarm2}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={alarm2} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // Set table data when the event checkbox is selected
  const eventTableData = [].concat(...filterMarker)?.map((event, index) => {
    let tableContent = null;
    switch (event.event) {
      case "DMS":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.alert_type}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "BRK":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event === "BRK" ? "Automatic Braking" : "BRK"}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.reason === 0
                ? `${event.title} Due to Collision Avoidance System Brake Duration: ${event.brake_duration} Sec`
                : `${event.title} Due to Sleep Alert Missed`}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "LDS":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event === "LDS" ? "Load" : "LDS"}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              <span>Max Capacity: {event?.max_cap}</span>
              <br />
              <span>Percentage: {event.percent}</span>
              <br />
              <span>Actual: {event?.actual_load}</span>
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "FLS":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event === "FLS" ? "Fuel" : "FLS"}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              <span>Current Fuel: {event.current_fuel}</span>
              <br />
              <span>Percentage: {event.percent}</span>
              <br />
              <span>
                Theft Status: {event.theft_status === 1 ? "Yes" : "No"}
              </span>
              <br />
              <span>Low Fuel: {event.low_fuel === 1 ? "Yes" : "No"}</span>
              <br />
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "CVN":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              --
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "ALC":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.result === 0
                ? "Fail"
                : event.result === 1
                ? "Pass"
                : event.result === 3
                ? "Timeout"
                : // : event.result === 6
                  // ? "Non zero speed"
                  ""}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "NTF":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.reason === 2 ? "Harsh Acceleration" : ""}
              {event.reason === 3 ? "Sudden Braking" : ""}
              {event.reason === 4 ? "Speed Bump" : ""}
              {event.reason === 5 && event.event === "" ? "Lane change" : ""}
              {event.reason === 6 ? "Tailgating" : ""}
              {event.reason === 7 ? "Overspeed" : ""}
              {/* {event.reason === 6 ? "Tailgating" : ""} */}
              {event.reason === 16 ? "ACC Cut due to Tipper ON" : ""}
              {event.reason === 17 ? "Wrong CVN" : ""}
              {event.reason === 5 &&
              (event.event === "ALM2" || event.event === "ALM3") &&
              event.alarm_no !== 0
                ? " ALM " + event.alarm_no
                : ""}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
            {/* {event.reason === 7 &&  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200"></td>} */}
          </tr>
        );
        break;
      case "ALM3":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              --
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "ALM2":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.event}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              --
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      case "ACC":
        tableContent = (
          <tr key={index}>
            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-800 dark:text-gray-200">
              {index + 1}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {/* {event.event} */}
              ACC Cut
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {/* {event.reason}
               */}
              {alerts.map((data, index) => {
                if (index === event.reason) {
                  return <>Acceleration cut due to {data}</>;
                }
              })}
            </td>
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800 dark:text-gray-200">
              {event.content}
            </td>
          </tr>
        );
        break;
      default:
        // Handle the default case (when event.event doesn't match any of the specified cases)
        tableContent = (
          <tr key={index}>
            <td>No data</td>
          </tr>
        );
    }
    return tableContent;
  });

  //dashcam videos
  const dash_video = [].concat(...filterMarker)?.map((event, index) => {
    if (event.event === "BRK" && event.dash_video !== "dash cam video") {
      return (
        <>
          <div className="flex flex-col">
            <video
              className="mt-3 h-full w-full object-cover object-center lg:h-full lg:w-full"
              width="100%"
              controls
              autoPlay={true}
              loop={true}
              key={index}
            >
              <source
                src={`${process.env.REACT_APP_S3_URL}/${event.dash_video}`}
                type="video/mp4"
              ></source>
              Your browser does not support the video tag.
            </video>
            <div className="flex justify-between text-sm">
              <p className="flex items-center">
                <MdSpeed className="text-lg text-green-500" />
                {event.brkspeed}
              </p>
              <p className="flex items-center">
                <IoMdTime className="text-lg text-[#444]" />
                {event.content}
              </p>
            </div>
          </div>
        </>
      );
    }
    return null;
  });

  // Alchohol tab
  const ALCContent = () => (
    <>
      {/* alc */}
      <div className="alc">
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={1}
                  onChange={handlecheckbox}
                  name="ALCPass"
                  checked={checkboxes?.ALCPass}
                  disabled={passAlc === 0}
                />
                <label className="ml-2 dark:text-white">Pass</label>
              </div>
              <div className="flex-shrink-0">
                {passAlc === 0 ? (
                  <Badge
                    value={passAlc}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={passAlc} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={0}
                  onChange={handlecheckbox}
                  name="ALCFail"
                  checked={checkboxes?.ALCFail}
                  disabled={failAlc === 0}
                />
                <label className="ml-2 dark:text-white">Fail</label>
              </div>
              <div className="flex-shrink-0">
                {failAlc === 0 ? (
                  <Badge
                    value={failAlc}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={failAlc} className="mx-3" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opt1 flex gap-4">
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              <div className="flex-shrink-0">
                <Checkbox
                  value={3}
                  onChange={handlecheckbox}
                  name="ALCTimeout"
                  checked={checkboxes?.ALCTimeout}
                  disabled={timeoutAlc === 0}
                />
                <label className="ml-2 dark:text-white">Timeout</label>
              </div>
              <div className="flex-shrink-0">
                {timeoutAlc === 0 ? (
                  <Badge
                    value={timeoutAlc}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={timeoutAlc} className="mx-3" />
                )}
              </div>
            </div>
          </div>
          <div className="opt2 w-[50%]">
            <div className="opt3 my-3 flex justify-between">
              {/* <div className="flex-shrink-0">
                <Checkbox
                  value={6}
                  onChange={handlecheckbox}
                  name="ALCNonZero"
                  checked={checkboxes?.ALCNonZero}
                  disabled={nonZeroAlc === 0}
                />
                <label className="ml-2 dark:text-white">Non Zero Speed</label>
              </div> */}
              {/* <div className="flex-shrink-0">
                {nonZeroAlc === 0 ? (
                  <Badge
                    value={nonZeroAlc}
                    style={{ backgroundColor: "gray", color: "white" }}
                    className="mx-3"
                  />
                ) : (
                  <Badge value={nonZeroAlc} className="mx-3" />
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="page-title pb-4">
            <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
              Ongoing Trip
            </h4>
            <p className="text-gray-700 sm-max:text-left">ID - {trip_id}</p>
          </div>
          <div className="items-top flex justify-end">
            {/* <img src={truckGIF} alt="GIF" className="mx-2 w-[10%]" /> */}
            <div className="mx-2 text-sm">
              <p className="py-1 font-medium text-gray-700 dark:text-white">
                <strong className="text-base">Vehicle Name : </strong>{" "}
                {vehicleDetails.vehicle_name}
              </p>
              <p className="font-medium text-gray-700 dark:text-white">
                <strong className="text-base">Registration No. : </strong>{" "}
                {vehicleDetails.vehicle_reg_number}
              </p>
            </div>
          </div>
        </div>

        <LoadScript googleMapsApiKey="AIzaSyCBU08ZrxmwB_0xlKYf5GJ95yx_dWA2hb4">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
          >
            <Marker position={startPoint} icon={markerIcons.blue} />

            {[].concat(...filterMarker)?.map((marker, index) => (
              <Marker
                key={`${marker.id}-${index}`}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => handleMarkerClick(marker)}
                icon={marker?.icon}
              >
                {selectedMarker === marker && (
                  <InfoWindow
                    position={{ lat: marker.lat, lng: marker.lng }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    {marker.event === "BRK" ? (
                      <div>
                        {marker.reason === 0 ? (
                          <>
                            <div>
                              {marker.title} Due to{" "}
                              <b>Collosion Avoidance System</b>
                              <p className="mb-0">
                                <b>TimeStamp:</b> {marker.content},{" "}
                                <b>Speed:</b> {marker.brkspeed}Kmph
                              </p>
                              <p className="mb-0">
                                <b>Brake Duration:</b> {marker.brake_duration}{" "}
                                Sec,
                                <b>Bypass:</b>{" "}
                                {marker.bypass !== 0 ? "Bypass" : "No Bypass"}
                              </p>
                              <p className="mb-0">
                                <b>On speed:</b> {marker.on_speed},{" "}
                                <b>Off speed:</b> {marker.off_speed}
                              </p>
                              <p className="mb-0">
                                <b>On X-Axis:</b> {marker.on_x_axis.toFixed(2)},{" "}
                                <b>Off X-Axis:</b>{" "}
                                {marker.off_x_axis.toFixed(2)}
                              </p>
                              <p className="mb-0">
                                <b>On Y-Axis:</b> {marker.on_y_axis.toFixed(2)},{" "}
                                <b>Off Y-Axis:</b>{" "}
                                {marker.off_y_axis.toFixed(2)}
                              </p>
                              <p className="mb-0">
                                <b>On TTC:</b> {marker.on_ttc.toFixed(3)},{" "}
                                <b>Off TTC:</b> {marker.off_ttc.toFixed(3)}
                              </p>
                              <p className="mb-0">
                                <b>On_Rel-Velocity:</b> {marker.on_rel_velocity}
                                ,<b>Off_Rel-Velocity:</b>{" "}
                                {marker.off_rel_velocity}
                              </p>
                              <p className="mb-0">
                                <b>On Steering-angle:</b>{" "}
                                {marker.on_steering_angle},
                                <b>Off Steering-angle:</b>{" "}
                                {marker.off_steering_angle}
                              </p>
                              <p className="mb-0">
                                <b>GPS Speed:</b> {marker.speed} Kmph
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              {marker.title} Due to <b>Sleep Alert Missed</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                              <p></p>
                            </div>
                          </>
                        )}
                      </div>
                    ) : marker.event === "DMS" ? (
                      <>
                        <div>
                          <h6>
                            <strong>{marker.title}</strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                          <p className="mb-0">
                            Alert_type: {marker.alert_type}
                          </p>
                          <p className="mb-0">Severity:{marker.severity}</p>
                          <button
                            className="btn-play my-1.5"
                            onClick={() =>
                              handleDMSVideoShow(
                                marker.media,
                                marker.dashcam,
                                marker.title,
                                marker.content,
                                marker.speed,
                                marker.alert_type,
                                marker.severity
                              )
                            }
                          >
                            Play &nbsp; <BsFillPlayCircleFill />
                          </button>
                        </div>
                      </>
                    ) : marker.event === "LDS" ? (
                      <>
                        <div>
                          <h6>
                            <strong>{marker.title === 34 ? "Load" : ""}</strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                          <p className="mb-0">
                            Max Capacity: {marker?.max_cap}
                          </p>
                          <p className="mb-0">Percentage: {marker.percent}</p>
                          <p className="mb-0">Actual: {marker?.actual_load}</p>
                        </div>
                      </>
                    ) : marker.event === "FLS" ? (
                      <>
                        <div>
                          <h6>
                            <strong>{marker.title === 34 ? "Load" : ""}</strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                          <p className="mb-0">
                            Current Fuel: {marker.current_fuel}
                          </p>
                          <p className="mb-0">Percentage: {marker.percent}</p>
                        </div>
                      </>
                    ) : marker.event === "CVN" ? (
                      <>
                        <div>
                          <h6>
                            <strong>{marker.title === 36 ? "CVN" : ""}</strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                        </div>
                      </>
                    ) : marker.event === "ALC" ? (
                      <>
                        <div>
                          <h6>
                            <strong>
                              {marker.title === 0 ? "Test Fail" : ""}
                              {marker.title === 1 ? "Test Pass" : ""}
                              {marker.title === 3 ? "Timeout" : ""}
                              {/* {marker.title === 6 ? "Non Zero Speed" : ""} */}
                            </strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                          {marker.title !== 3 && (
                            <p className="mb-0">
                              BAC: {marker?.BAC?.toFixed(4)}
                            </p>
                          )}
                          <button
                            className="btn-play mt-3"
                            onClick={() =>
                              handleDMSALCVideoShow(
                                marker.vid,
                                marker.img,
                                marker.title,
                                marker.content,
                                marker.speed,
                                marker.BAC
                              )
                            }
                          >
                            Play &nbsp; <BsFillPlayCircleFill />
                          </button>
                        </div>
                      </>
                    ) : marker.event === "ACC" ? (
                      <>
                        <div>
                          <h6>
                            <strong>ACC CUT</strong>
                          </h6>
                          <p className="mb-0">TimeStamp: {marker.content}</p>
                          <p className="mb-0">Speed: {marker.speed}Kmph</p>
                          {/* <p className="mb-0">BAC: {marker.BAC}</p> */}
                          {/* <button
                            className="btn-play mt-3"
                            onClick={() =>
                              handleDMSALCVideoShow(
                                marker.vid,
                                marker.img,
                                marker.title,
                                marker.content,
                                marker.speed,
                                marker.bac
                              )
                            }
                          >
                            Play &nbsp; <BsFillPlayCircleFill />
                          </button> */}
                        </div>
                      </>
                    ) : (
                      <div>
                        <>
                          {marker.reason === 2 ? (
                            <div>
                              <b>Harsh Acceleration</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 3 ? (
                            <div>
                              <b>Sudden Braking</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 4 ? (
                            <div>
                              <b>Speed Bump</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 5 && marker.event === "NTF" ? (
                            <div>
                              <b>Lane change</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 6 ? (
                            <div>
                              <b>Tailgating</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 7 ? (
                            <div>
                              <b>Overspeed</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 16 ? (
                            <div>
                              <b>ACC Cut due to Tipper ON</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 17 ? (
                            <div>
                              <b>Wrong CVN</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">
                                Speed: {marker.speed.toFixed(0)}Kmph
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {marker.reason === 5 &&
                          (marker.event === "ALM2" ||
                            marker.event === "ALM3") &&
                          marker.alarm_no !== 0 ? (
                            <div>
                              <b>Alarm</b>
                              <p className="mb-0">
                                TimeStamp: {marker.content}
                              </p>
                              <p className="mb-0">Speed: {marker.speed}Kmph</p>
                              <p className="mb-0">
                                Alarm No.: {marker.alarm_no}
                              </p>
                              <p className="mb-0">
                                X distance: {marker.x_axis.toFixed(2)} Mtr
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                    )}
                  </InfoWindow>
                )}
              </Marker>
            ))}

            <Polyline
              path={path}
              options={{
                strokeColor: "#4252E0", // Default color
                strokeWeight: 4,
              }}
            />
            <Polyline path={cvnPath ? cvnPath : ""} options={polylineOptions} />
            <Marker
              position={endPoint}
              icon={markerIcons.green}
              // icon={{
              //   url: liveCar,
              //   scaledSize: { width: 50, height: 50 }, // Adjust the width and height
              //   origin: { x: 0, y: 0 },
              //   anchor: { x: 25, y: 50 }, // Adjust the anchor point
              // }}
            />
          </GoogleMap>
        </LoadScript>

        {/* DMS videos pop-ups */}
        <Dialog
          header={videoTitle}
          visible={visible}
          style={{ width: "50vw", height: "fit-content" }}
          onHide={() => setVisible(false)}
        >
          <p className="mb-0">
            <b>Timestamp:</b> {videoContent}
          </p>
          <p className="mb-0">
            <b>Speed:</b> {videoSpeed} KMPH
          </p>
          {/* {videoAlert && (
            <p className="mb-0">
              <b>Alert Type:</b> {videoAlert}
            </p>
          )} */}

          {/* {videoSeverity && (
            <p className="mb-0">
              <b>Severity:</b> {videoSeverity}
            </p>
          )} */}

          {/* {videoBAC && (
            <p className="mb-0">
              <b>BAC:</b> {videoBAC}
            </p>
          )} */}
          <div className="flex justify-center">
            {videoUrl && (
              <div className="w-1/2">
                <video controls className="h-48 w-full">
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <h5 className="text-center text-red-500">DMS Video</h5>
              </div>
            )}

            {dashCamVideo && (
              <div className="w-1/2">
                <video controls className="h-48 w-full">
                  <source src={dashCamVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h5 className="text-center text-red-500">Dashcam Video</h5>
              </div>
            )}

            {dmsImg ? (
              <div className="w-1/2">
                <img src={dmsImg} alt="DMS" className="img-fluid w-full" />
                <h5 className="text-center text-red-500">Image</h5>
              </div>
            ) : (
              <div className="w-1/2">
                <p>{null}</p>
              </div>
            )}
          </div>
        </Dialog>
      </div>

      <div className="lg:max-w-screen mx-auto mt-4 grid w-full grid-cols-1 gap-y-8 rounded-[20px] sm:py-8 sm-max:flex sm-max:flex-col lg:grid-cols-2">
        <div className="rounded-[20px] bg-white dark:bg-navy-700">
          <div>
            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
              <TabPanel
                header="Summary"
                className="font-medium sm-max:ml-32"
                onClick={() => setActiveIndex(0)}
              >
                <SummaryContent />
              </TabPanel>
              {vehicleDetails?.device_type_id?.includes("1") && (
                <TabPanel
                  header="Collision Avoidance"
                  onClick={() => setActiveIndex(1)}
                >
                  <CASContent />
                </TabPanel>
              )}

              {vehicleDetails?.device_type_id?.includes("3") && (
                <TabPanel header="Driver Monitoring" onClick={showVideo}>
                  <DMSContent />
                </TabPanel>
              )}

              {vehicleDetails?.device_type_id?.includes("3") && (
                <TabPanel
                  header="Alcohol Detection"
                  onClick={() => setActiveIndex(3)}
                >
                  <ALCContent />
                </TabPanel>
              )}
            </TabView>
          </div>
        </div>

        {/* Analytics Section */}
        {activeIndex === 0 && (
          <div className={`rounded-[20px] bg-white dark:bg-navy-700`}>
            <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
              <h2 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                Analytics
              </h2>

              <dl className="mb-4 mt-10 grid grid-cols-1 gap-x-6 gap-y-7 sm:grid-cols-2 sm:gap-y-12 lg:gap-x-8">
                <TripInfoItem
                  title="Braking Frequency"
                  value={
                    brakingFreq === 0 ? "--" : `Once in ${brakingFreq} Mins`
                  }
                />

                <TripInfoItem title="Halt" value={haltTime ? haltTime : "--"} />
              </dl>
              <hr />

              <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                <div className="border-gray-200 pt-2 dark:border-cyan-800">
                  <p className="font-medium text-gray-900 dark:text-white">
                    Fuel
                  </p>
                  <Knob
                    value={parseFloat(actualFuel?.toFixed(0))}
                    valueTemplate={"{value}%"}
                    strokeWidth={10}
                    size={70}
                  />
                </div>

                <TripInfoItem title="Load" value={actualLoad + " Kg"} />
              </div>
            </div>
          </div>
        )}

        {/* DMS Media Section */}
        {activeIndex === 2 && (
          <div className="rounded-[20px] bg-white dark:bg-navy-700">
            <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
              <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                DMS Media
              </h2>
              <ScrollPanel style={{ width: "100%", height: "600px" }}>
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
                  {[]
                    .concat(...filterMarker)
                    .filter((data) => data.event === "DMS")
                    .map((data, index) => {
                      const dashLink = data.dashcam;
                      const dashCamVid = dashLink?.startsWith(
                        "/var/www/html/media/"
                      )
                        ? dashLink.substring(20)
                        : dashLink;
                      const fileExtension = data.media
                        .split(".")
                        .pop()
                        .toLowerCase();

                      return (
                        <div key={`dms-${index}`} className="relative">
                          <LazyLoad height={400} offsetVertical={300}>
                            <div className="overflow-hidden rounded-xl bg-gray-50 shadow-sm dark:bg-navy-800">
                              {/* Media Section First */}
                              <div
                                ref={(el) =>
                                  (videoContainerRefs.current[index] = el)
                                }
                                className="flex items-center justify-center text-center"
                              >
                                <div className="relative">
                                  {fileExtension === "jpg" ? (
                                    <div className="aspect-video w-full">
                                      <img
                                        src={`${process.env.REACT_APP_S3_URL}/${data.media}`}
                                        alt={data.alert_type}
                                        className="h-full w-full object-cover"
                                      />
                                    </div>
                                  ) : (
                                    <div className="bg-black aspect-video w-full">
                                      <video
                                        className="h-full w-full object-contain"
                                        controls
                                        key={data.media}
                                        ref={(el) =>
                                          (videoRefs.current[`main-${index}`] =
                                            el)
                                        }
                                        onEnded={() =>
                                          setPlayingPairs((prev) => ({
                                            ...prev,
                                            [index]: false,
                                          }))
                                        }
                                      >
                                        <source
                                          src={`${process.env.REACT_APP_S3_URL}/${data.media}`}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  )}
                                  <div className="bottom-0 left-0 right-0 top-0 flex items-center justify-center">
                                    <button
                                      onClick={() => handlePlayPause(index)}
                                      className="cursor-pointer  rounded-full bg-gray-300 px-1.5 py-0.5 text-blue-600"
                                    >
                                      {playingPairs[index] ? `⏸` : " ►"}
                                    </button>
                                  </div>
                                  {data.dashcam && (
                                    <div className="bg-black mt-1 aspect-video w-full">
                                      <video
                                        className="h-full w-full object-contain"
                                        controls
                                        key={dashCamVid}
                                        ref={(el) =>
                                          (videoRefs.current[`dash-${index}`] =
                                            el)
                                        }
                                        onEnded={() =>
                                          setPlayingPairs((prev) => ({
                                            ...prev,
                                            [index]: false,
                                          }))
                                        }
                                      >
                                        <source
                                          src={`${process.env.REACT_APP_S3_URL}/${dashCamVid}`}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="text-end">
                                <button
                                  onClick={() => toggleFullscreen(index)}
                                  className="mt-0 cursor-pointer rounded bg-blue-500 px-2 py-1 text-xs text-white hover:bg-blue-600"
                                >
                                  <span className="flex items-center">
                                    Fullscreen
                                    <MdFullscreen className="h-4 w-4" />
                                  </span>
                                </button>
                              </div>
                              {/* Text Content Below Media */}
                              <div className="px-2 pb-5">
                                <div className="mb-3 flex items-center justify-between">
                                  <h3 className="flex items-center gap-2 text-lg font-medium text-gray-900 dark:text-white">
                                    {data?.speed > 0 &&
                                    data.alert_type === "NO_DRIVER"
                                      ? "Camera Tampering"
                                      : data.alert_type}
                                    {data?.icon && (
                                      <img
                                        src={data.icon}
                                        alt={data.alert_type}
                                        className="h-5 w-5"
                                      />
                                    )}
                                  </h3>
                                </div>
                                <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-300">
                                  <div className="flex items-center gap-2">
                                    <IoIosSpeedometer className="h-4 w-4" />
                                    <span>{data?.speed} Kmph</span>
                                  </div>
                                  <div className="flex items-center gap-2">
                                    <FaClock className="h-4 w-4" />
                                    <span>{data?.content}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </LazyLoad>
                        </div>
                      );
                    })}
                </div>
              </ScrollPanel>
            </div>
          </div>
        )}

        {/* DMS Alcohol Media Section */}
        {activeIndex === 3 && (
          <div className="rounded-[20px] bg-white dark:bg-navy-700">
            <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
              <h2 className="mb-6 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                DMS Alcohol Media
              </h2>
              <ScrollPanel style={{ width: "100%", height: "600px" }}>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                  {[]
                    .concat(...filterMarker)
                    .filter((data) => data.event === "ALC")
                    .map((data, index) => {
                      const mediaEventTitle =
                        data.title === 0
                          ? "Fail"
                          : data.title === 1
                          ? "Pass"
                          : data.title === 3
                          ? "Timeout"
                          : "";

                      const statusColors = {
                        Fail: "bg-red-100 text-red-800 ring-red-600/20",
                        Pass: "bg-green-100 text-green-800 ring-green-600/20",
                        Timeout:
                          "bg-yellow-100 text-yellow-800 ring-yellow-600/20",
                      };

                      // Dynamic gap class
                      const isImageOnly = data.img && !data.vid;
                      const mediaGapClass = isImageOnly
                        ? "gap-4" // Smaller gap for images
                        : "gap-8"; // Larger gap for mixed content

                      return (
                        <div
                          key={`alc-${index}`}
                          className={`relative ${mediaGapClass}`}
                        >
                          <LazyLoad height={400} offsetVertical={300}>
                            <div className="overflow-hidden rounded-xl bg-gray-50 shadow-sm dark:bg-navy-800">
                              {/* Status Badge */}
                              <div className="absolute left-4 top-4 z-10">
                                <span
                                  className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ${statusColors[mediaEventTitle]} ring-1 ring-inset`}
                                >
                                  {mediaEventTitle}
                                  {data?.icon && (
                                    <img
                                      src={data.icon}
                                      alt={`${mediaEventTitle} icon`}
                                      className="ml-2 h-4 w-4"
                                    />
                                  )}
                                </span>
                              </div>

                              {/* Media Container */}

                              <div className="relative">
                                {/* Video Section - Only for Pass and Fail */}
                                {mediaEventTitle !== "Timeout" && data.vid && (
                                  <div className="bg-black mb-4 aspect-[16/9] w-full">
                                    <video
                                      className="h-full w-full object-contain"
                                      controls
                                    >
                                      <source
                                        src={`${process.env.REACT_APP_S3_URL}/${data.vid}`}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                )}

                                {/* Image Section */}
                                {data.img && (
                                  <div
                                    className={`w-full bg-gray-100 dark:bg-navy-900 ${
                                      mediaEventTitle === "Timeout"
                                        ? "aspect-[4/3]" // Adjusted aspect ratio for timeout images
                                        : "aspect-[16/9]"
                                    } mb-4`}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_S3_URL}/${data.img}`}
                                      alt={`DMS ${mediaEventTitle}`}
                                      className="h-full w-full object-cover"
                                    />
                                    <p
                                      className={`flex items-center gap-1  
                                            ${
                                              verifiedResults[data.img]
                                                ?.status === 1
                                                ? "text-green-600"
                                                : verifiedResults[data.img]
                                                    ?.status === 2
                                                ? "text-red-400"
                                                : "text-blue-700"
                                            }`}
                                    >
                                      <RiVerifiedBadgeFill />
                                      {verifiedResults[data.img]?.status === 1
                                        ? `Driver Verified-${
                                            verifiedResults[data.img]
                                              ?.driverDetails?.name
                                          }`
                                        : verifiedResults[data.img]?.status ===
                                          2
                                        ? "Driver Not Verified"
                                        : "Driver's Face Not Detected"}
                                    </p>
                                  </div>
                                )}

                                {/* Fallback for no media */}
                                {!data.vid && !data.img && (
                                  <div className="flex aspect-[16/9] w-full items-center justify-center bg-gray-100 dark:bg-navy-600">
                                    <div className="text-center">
                                      <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth={2}
                                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        />
                                      </svg>
                                      <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                        Media Unavailable
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* Text Content */}
                              <div className="p-4">
                                <div className="flex items-center justify-between">
                                  <p className="text-sm text-gray-600 dark:text-gray-300">
                                    {data?.content}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </LazyLoad>
                        </div>
                      );
                    })}
                </div>
              </ScrollPanel>
            </div>
          </div>
        )}

        {/* Events table data */}
        <div
          className={`${
            activeIndex === 0 ? "hidden" : ""
          }  rounded-[20px] bg-white dark:bg-navy-700`}
        >
          <div className="events-table mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Events
            </h2>
            <ScrollPanel style={{ width: "100%", height: "350px" }}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 dark:bg-gray-900">
                  <tr>
                    <th
                      scope="col"
                      className="flex items-center px-3 py-3 text-left text-xs font-bold uppercase text-gray-750 dark:text-white"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-750 dark:text-white"
                    >
                      Event
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-750 dark:text-white"
                    >
                      Data
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold uppercase text-gray-750 dark:text-white"
                    >
                      Timestamp
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {eventTableData.length > 0 ? (
                    eventTableData
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4}>No data found!</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </ScrollPanel>
            {autoBrk !== 0 &&
            activeIndex === 1 &&
            checkboxes?.AUTOMATIC_BRAKING === true ? (
              <ScrollPanel>
                <h2 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Dashcam Videos
                </h2>
                <div className="grid grid-cols-2 md:mt-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
                  {dash_video}
                </div>
              </ScrollPanel>
            ) : (
              console.log("err")
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OngoingTripView;
