import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { BsFillDeviceSsdFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { IoIosSpeedometer } from "react-icons/io";
import { TiArrowForward } from "react-icons/ti";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import Maps from "components/skeleton-preloader/Maps";
import Cards from "./components/Cards";

const CONTAINER_STYLE = {
  width: "100%",
  height: "450px",
  border: "2px #8994BC solid",
  borderRadius: "0px 0px 10px 10px",
  zIndex: "0",
};

const Dashboard = () => {
  const [center, setCenter] = useState({ lat: 21.958088, lng: 85.255253 });
  const [zoom, setZoom] = useState(6);
  const [vehicles, setVehicles] = useState([]);
  const [inactiveVehicles, setInactiveVehicles] = useState([]);
  const [parkedVehicles, setParkedVehicles] = useState([]);
  const [selectedMode, setSelectedMode] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [loader, setLoader] = useState(true);
  const token = Cookies.get("token");
  const user_type = Cookies.get("user_type");
  const orgId = Cookies.get("org_id");

  // Local state for limp and accident data
  const [limpData, setLimpData] = useState(
    JSON.parse(localStorage.getItem("limpdata")) || []
  );
  const [acdData, setAcdData] = useState(
    JSON.parse(localStorage.getItem("acddata")) || []
  );

  const getMarkerIcon = (mode) => {
    switch (mode) {
      case 1: // Ongoing trips
        return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
      case 2: // Limp mode
        return "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
      case 3: // Accident mode
        return "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";
      case 4: // Inactive vehicles
        return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
      case 5: // Parked vehicles
        return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      default:
        return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
    }
  };

  useEffect(() => {
    // Fetch ongoing trips
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/dashongoingTrips`,
        { org_id: orgId || "" },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        setLoader(false);
        setVehicles(res.data.data);
        updateCenterAndZoom(res.data.data);
      })
      .catch(() => {
        setLoader(false);
      });

    // Fetch inactive vehicles
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/inactiveDevices`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setInactiveVehicles(res?.data?.data);
        updateCenterAndZoom(res?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    // eslint-disable-next-line

    //Fetch parked vehicles
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/dashAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setParkedVehicles(res?.data?.data);
        updateCenterAndZoom(res?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [orgId, token]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      // showNotification(
      //   "Socket Connected",
      //   "You are now connected to the WebSocket server."
      // );
      console.log("connected");
    });

    vehicles.forEach((vehicle) => {
      const listener = (data) => {
        if (data.tripSummaryDetails[0].event === "LMP") {
          handleLimpMode(data, vehicle);
        } else if (
          data.tripSummaryDetails[0].event === "ACD" &&
          JSON.parse(data.tripSummaryDetails[0].jsonData).data.status === 1
        ) {
          handleAccidentMode(data, vehicle);
        }
        updateVehicleLocation(data);
      };

      socket.on(vehicle.trip_id, listener);
      return () => socket.off(vehicle.trip_id, listener);
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [vehicles]);

  // Handle Limp Mode data
  const handleLimpMode = (data, vehicle) => {
    let myObj = {
      vehicle_reg_number: vehicle.vehicle_reg_number,
      vehicle_name: vehicle.vehicle_name,
      tag: vehicle.tag,
      event: data.tripSummaryDetails[0].event,
      LMP_status: JSON.parse(data.tripSummaryDetails[0].jsonData).data.status,
      LMP_reason: JSON.parse(data.tripSummaryDetails[0].jsonData).data.reason,
      timestamp: data.tripSummaryDetails[0].timestamp,
      lat: data.tripSummaryDetails[0].latitude,
      lng: data.tripSummaryDetails[0].longitude,
      device_id: data.tripSummaryDetails[0].device_id,
      speed: JSON.parse(data.tripSummaryDetails[0].jsonData).data.speed,
      trip_id: vehicle.trip_id,
    };

    let dataArray = JSON.parse(localStorage.getItem("limpdata")) || [];

    // If LMP_status is 0, remove the object from state and localStorage
    if (myObj.LMP_status === 0) {
      dataArray = dataArray.filter(
        (item) => item.device_id !== myObj.device_id
      );
      localStorage.setItem("limpdata", JSON.stringify(dataArray));
      setLimpData((prevData) =>
        prevData.filter((item) => item.device_id !== myObj.device_id)
      );
    } else {
      // Add new data if not a duplicate
      const isDuplicate = dataArray.some(
        (item) => item.device_id === myObj.device_id
      );
      if (!isDuplicate) {
        const updatedDataArray = [...dataArray, myObj];
        localStorage.setItem("limpdata", JSON.stringify(updatedDataArray));
        setLimpData((prevData) => [...prevData, myObj]);
      }
    }
  };

  // Handle Accident Mode data
  const handleAccidentMode = (data, vehicle) => {
    let myObj = {
      vehicle_reg_number: vehicle.vehicle_reg_number,
      vehicle_name: vehicle.vehicle_name,
      tag: vehicle.tag,
      event: data.tripSummaryDetails[0].event,
      ACD_status: JSON.parse(data.tripSummaryDetails[0].jsonData).data.status,
      timestamp: data.tripSummaryDetails[0].timestamp,
      lat: data.tripSummaryDetails[0].latitude,
      lng: data.tripSummaryDetails[0].longitude,
      device_id: data.tripSummaryDetails[0].device_id,
      speed: JSON.parse(data.tripSummaryDetails[0].jsonData).data.speed,
      trip_id: vehicle.trip_id,
    };

    const existingData = localStorage.getItem("acddata");
    const dataArray = existingData ? JSON.parse(existingData) : [];

    // Add new data
    const updatedDataArray = [...dataArray, myObj];
    localStorage.setItem("acddata", JSON.stringify(updatedDataArray));
    setAcdData((prevData) => [...prevData, myObj]);
  };

  const updateVehicleLocation = useCallback((data) => {
    setVehicles((prevVehicles) =>
      prevVehicles.map((vehicle) => {
        if (vehicle.trip_id === data.trip_id) {
          const newLat = data.location_data[0].latitude;
          const newLng = data.location_data[0].longitude;

          const bearing = calculateBearing(
            vehicle.lat,
            vehicle.lng,
            newLat,
            newLng
          );

          return {
            ...vehicle,
            lat: newLat,
            lng: newLng,
            max_spd: data.location_data[0].speed,
            endTime: data.location_data[0].timestamp,
            bearing: bearing,
          };
        } else {
          return vehicle;
        }
      })
    );
  }, []);

  const calculateBearing = (startLat, startLng, endLat, endLng) => {
    const toRadians = (deg) => (deg * Math.PI) / 180;
    const toDegrees = (rad) => (rad * 180) / Math.PI;

    const dLon = toRadians(endLng - startLng);
    const startLatRad = toRadians(startLat);
    const endLatRad = toRadians(endLat);

    const y = Math.sin(dLon) * Math.cos(endLatRad);
    const x =
      Math.cos(startLatRad) * Math.sin(endLatRad) -
      Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(dLon);

    return (toDegrees(Math.atan2(y, x)) + 360) % 360;
  };

  const updateCenterAndZoom = (vehicles) => {
    if (vehicles.length > 0) {
      const latitudes = vehicles.map((vehicle) => parseFloat(vehicle.lat));
      const longitudes = vehicles.map((vehicle) => parseFloat(vehicle.lng));

      const maxLat = Math.max(...latitudes);
      const minLat = Math.min(...latitudes);
      const maxLng = Math.max(...longitudes);
      const minLng = Math.min(...longitudes);

      const avgLat = (maxLat + minLat) / 2;
      const avgLng = (maxLng + minLng) / 2;

      setCenter({ lat: avgLat, lng: avgLng });

      const bounds = new window.google.maps.LatLngBounds();
      vehicles?.forEach((vehicle) => {
        bounds?.extend(
          new window.google.maps.LatLng(
            parseFloat(vehicle.lat),
            parseFloat(vehicle.lng)
          )
        );
      });

      const mapZoom = getBoundsZoomLevel(bounds, { width: 450, height: 450 });
      setZoom(mapZoom);
    }
  };

  const getBoundsZoomLevel = (bounds, mapDim) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 17;

    function latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  const ConvertToDate = (epoch) => {
    const date = new Date(epoch * 1000);
    return date.toLocaleString("en-GB");
  };

  const handleCardClick = (vehicle) => {
    const lat = parseFloat(vehicle.lat);
    const lng = parseFloat(vehicle.lng);

    if (!isNaN(lat) && !isNaN(lng)) {
      setCenter({ lat, lng });
      setZoom(15);
      setSelectedVehicle(vehicle);
    }
  };

  const renderCards = () => {
    const items =
      selectedMode === 4
        ? inactiveVehicles
        : selectedMode === 2
        ? limpData
        : selectedMode === 3
        ? acdData
        : selectedMode === 5
        ? parkedVehicles
        : vehicles;

    return items.map((item) => (
      <div
        className="mb-2.5 flex cursor-pointer flex-col rounded-md bg-[#CBD5E0] hover:bg-[#f0f0f0]"
        onClick={() => handleCardClick(item)}
      >
        <div className="flex items-center justify-between px-2 pt-2">
          <span className="font-semibold">
            {item.vehicle_reg_number || item.device_name}
          </span>
          <span className="rounded-xl bg-gray-700 px-1.5 py-0.5 text-xs text-gray-100">
            {item.tag}
          </span>
        </div>
        <div className="ml-2 border-gray-400 py-0.5">
          <p className="mt-1 flex items-center text-xs text-[#444] ">
            <FiTruck className="mr-1 text-blue-600" /> &nbsp;
            {item.vehicle_name || item.device_name}
          </p>
        </div>
        <div className="border-gray-400 py-0.5">
          <p className="mx-2 mt-1 flex items-center text-xs text-[#444] ">
            <BsFillDeviceSsdFill className="mr-1 text-blue-600" />
            {item.device_id}
          </p>
        </div>
        <div className="flex justify-between">
          {item.startTime && (
            <div className="border-gray-400 py-0.5">
              <p className="mx-2 mt-1 flex items-center text-xs text-[#444] ">
                <FaClock className="mr-1 text-green-600" />
                {ConvertToDate(item.startTime || item.trip_start_time)}
              </p>
            </div>
          )}
          <div className="border-gray-400 pb-2">
            <p className="mx-2 mt-2 flex items-center text-xs text-[#444] ">
              <FaClock className="mr-1 text-yellow-600" />
              {ConvertToDate(
                item.endTime ||
                  item.lastData ||
                  item.timestamp ||
                  item.trip_end_time
              )}
            </p>
          </div>
        </div>
        {selectedMode === 1 && (
          <div className="mr-2 flex justify-between">
            <div className="ml-2 border-gray-400 py-1">
              <p className="mt-1 flex items-center text-xs text-[#444] ">
                <IoIosSpeedometer className="mr-1 text-red-600" />
                {` ${
                  item.max_spd
                    ? `${parseFloat(item.max_spd).toFixed(0)} KMPH`
                    : 0 + " KMPH"
                }`}
              </p>
            </div>

            <Link to={`/trips/ongoing-trip/${item.trip_id}`}>
              <Button className="dark:hover:none border border-gray-400 bg-gray-150 px-1 text-xs shadow-sm dark:bg-gray-700">
                View <TiArrowForward />
              </Button>
            </Link>
          </div>
        )}
      </div>
    ));
  };

  const renderMarkers = () => {
    const items =
      selectedMode === 4
        ? inactiveVehicles
        : selectedMode === 2
        ? limpData
        : selectedMode === 3
        ? acdData
        : selectedMode === 5
        ? parkedVehicles
        : vehicles;

    return items.map((item) => {
      const lat = parseFloat(item?.lat);
      const lng = parseFloat(item?.lng);

      const reasons = [
        { number: 1, title: "Sleep Alert" },
        { number: 2, title: "Over Speed" },
        { number: 3, title: "No Alarm" },
        { number: 4, title: "Speed Pulses Absent" },
        { number: 5, title: "Accelerator Bypassed" },
        { number: 6, title: "Sensor Absent" },
        { number: 7, title: "Gyroscope Absent" },
        { number: 8, title: "HMI Absent" },
        { number: 9, title: "RTC Error" },
        { number: 10, title: "Brake Cylinder Error" },
        { number: 11, title: "TPMS Absent" },
        { number: 12, title: "OBD Absent" },
        { number: 13, title: "RFID Absent" },
        { number: 14, title: "Leddar Absent" },
        { number: 15, title: "IOT Absent" },
        { number: 16, title: "Fuel Board Absent" },
        { number: 17, title: "DD Module Absent" },
        { number: 18, title: "Alcohol Sensor Absent" },
        { number: 19, title: "Temp Sensor Absent" },
        { number: 20, title: "DMS Board Absent" },
        { number: 21, title: "Load Board Absent" },
      ];

      const mapReason = (num) => {
        return reasons.filter((item) => item.number === num);
      };

      if (!isNaN(lat) && !isNaN(lng)) {
        return (
          <Marker
            // key={item.vehicle_id}
            position={{ lat, lng }}
            onClick={() => handleCardClick(item)}
            icon={{
              url: getMarkerIcon(selectedMode), // Set the marker icon dynamically
              scaledSize: new window.google.maps.Size(32, 32), // Adjust size if needed
            }}
          >
            {selectedVehicle &&
              selectedVehicle.vehicle_id === item.vehicle_id && (
                <InfoWindow
                  position={{ lat, lng }}
                  ariaLabel="Info Window"
                  onCloseClick={() => setSelectedVehicle(null)}
                >
                  <div className="pb-3 tracking-wide">
                    <h4 className="px-2">
                      <span className="font-semibold">Regss No.:</span>{" "}
                      {item.vehicle_reg_number}
                    </h4>
                    <p className="px-2">
                      <span className="font-semibold">Name:</span>{" "}
                      {item.vehicle_name}
                    </p>
                    <p className="px-2">
                      <span className="font-semibold">Latitude:</span>{" "}
                      {item.lat}
                    </p>
                    <p className="px-2">
                      <span className="font-semibold">Longitude:</span>{" "}
                      {item.lng}
                    </p>
                    {item.max_spd ? (
                      <p className="px-2">
                        <span className="font-semibold">Speed: </span>
                        {parseFloat(item.max_spd).toFixed(0)} KMPH
                      </p>
                    ) : (
                      <></>
                    )}

                    <p className="px-2">
                      <span className="font-semibold">Updated at: </span>
                      {selectedMode === 4
                        ? ConvertToDate(item.lastData)
                        : selectedMode === 2 || selectedMode === 3
                        ? ConvertToDate(item.timestamp)
                        : selectedMode === 1
                        ? ConvertToDate(item.endTime)
                        : selectedMode === 5
                        ? ConvertToDate(item.trip_end_time)
                        : null}
                    </p>
                    {item.LMP_reason ? (
                      <p className="px-2">
                        <span className="font-semibold">Reason: </span>
                        {mapReason(item.LMP_reason)[0]?.title}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </InfoWindow>
              )}
          </Marker>
        );
      } else {
        console.warn("Invalid coordinates for item:", item);
        return null;
      }
    });
  };

  return (
    <>
      <h4 className="text-dark mb-2 text-xl font-semibold dark:text-white">
        Dashboard
      </h4>
      {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" ? (
        <Cards />
      ) : (
        <div className="flex flex-row gap-4 sm-max:flex-col">
          <div className="w-1/5 rounded-md sm-max:w-full">
            <ScrollPanel className="h-[480px] w-full sm-max:h-[200px]">
              {loader ? (
                <Maps />
              ) : vehicles.length === 0 && selectedMode === 1 ? (
                <p>No live vehicles</p>
              ) : inactiveVehicles.length === 0 && selectedMode === 4 ? (
                <p>No inactive vehicles</p>
              ) : limpData.length === 0 && selectedMode === 2 ? (
                <p>No limp mode alerts</p>
              ) : acdData.length === 0 && selectedMode === 3 ? (
                <p>No accident saved alerts</p>
              ) : parkedVehicles.length === 0 && selectedMode === 5 ? (
                <p>No parked vehicles</p>
              ) : (
                <>{renderCards()}</>
              )}
            </ScrollPanel>
          </div>
          <div className="w-4/5 sm-max:w-full">
            <div className="flex w-full items-center justify-between rounded-t-md bg-gray-400 sm-max:grid sm-max:grid-cols-2 sm-max:gap-2 sm-max:text-xs">
              <button
                className={`flex items-center gap-2 rounded-t-md ${
                  selectedMode === 1 ? "bg-green-600" : "bg-gray-700"
                } px-3 py-1 text-gray-50`}
                onClick={() => setSelectedMode(1)}
              >
                Ongoing Trips
                <span
                  className={`rounded-md ${
                    selectedMode === 1 ? "bg-green-300" : "bg-gray-300"
                  } px-1 text-center text-[0.6rem] font-semibold text-gray-750`}
                >
                  {vehicles?.length}
                </span>
              </button>
              <button
                className={`flex items-center gap-2 rounded-t-md ${
                  selectedMode === 2 ? "bg-green-600" : "bg-gray-700"
                } px-3 py-1 text-gray-50`}
                onClick={() => setSelectedMode(2)}
              >
                Limp Mode
                <span
                  className={`rounded-md ${
                    selectedMode === 2 ? "bg-green-300" : "bg-gray-300"
                  } px-1 text-center text-[0.6rem] font-semibold text-gray-750`}
                >
                  {limpData?.length}
                </span>
              </button>
              <button
                className={`flex items-center gap-2 rounded-t-md ${
                  selectedMode === 3 ? "bg-green-600" : "bg-gray-700"
                } px-3 py-1 text-gray-50`}
                onClick={() => setSelectedMode(3)}
              >
                Accident Saved
                <span
                  className={`rounded-md ${
                    selectedMode === 3 ? "bg-green-300" : "bg-gray-300"
                  } px-1 text-center text-[0.6rem] font-semibold text-gray-750`}
                >
                  {acdData?.length}
                </span>
              </button>
              <button
                className={`flex items-center gap-2 rounded-t-md ${
                  selectedMode === 4 ? "bg-green-600" : "bg-gray-700"
                } px-3 py-1 text-gray-50`}
                onClick={() => setSelectedMode(4)}
              >
                Inactive vehicles
                <span
                  className={`rounded-md ${
                    selectedMode === 4 ? "bg-green-300" : "bg-gray-300"
                  } px-1 text-center text-[0.6rem] font-semibold text-gray-750`}
                >
                  {inactiveVehicles?.length}
                </span>
              </button>
              <button
                className={`flex items-center gap-2 rounded-t-md ${
                  selectedMode === 5 ? "bg-green-600" : "bg-gray-700"
                } px-3 py-1 text-gray-50`}
                onClick={() => setSelectedMode(5)}
              >
                Parked Vehicles
                <span
                  className={`rounded-md ${
                    selectedMode === 5 ? "bg-green-300" : "bg-gray-300"
                  } px-1 text-center text-[0.6rem] font-semibold text-gray-750`}
                >
                  {parkedVehicles?.length}
                </span>
              </button>
            </div>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
              onError={(error) =>
                console.error("Error loading Google Maps API:", error)
              }
            >
              <GoogleMap
                // key={selectedMode}
                mapContainerStyle={CONTAINER_STYLE}
                center={center}
                zoom={zoom}
              >
                {renderMarkers()}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
