import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { AppContext } from "context/AppContext";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { MdStarRate } from "react-icons/md";

const AddFeatureSetRoot = ({ closeDialog, toastRef }) => {
  const [data, setData] = useState({});
  const [fields, setFields] = useState([]);
  const [details, setDetails] = useState({});
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const token = Cookies.get("token");
  const { updateFS } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [publishPopUpVisible, setPublishPopUpVisible] = useState(false);
  const [value, setValue] = useState("");
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "VehicleTypeLookup" },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setVehicleTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const vehicleTypeOptions = () => {
    return vehicleTypes.map((item) => ({
      label: item.vehicleTypeName,
      value: item.vehicleTypeId,
    }));
  };

  const statusOptions = () => {
    const status = [
      { label: "Active", value: 1 },
      { label: "Deactive", value: 2 },
    ];
    return status;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (type === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  //function to restructure data
  function restructureData(data) {
    const restructuredData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const [mainKey, subKey] = key.split("--");
        const value = parseFloat(data[key]);

        if (subKey) {
          restructuredData[subKey] = restructuredData[subKey] || {};
          restructuredData[subKey][mainKey] = value;
        }
      }
    }

    return restructuredData;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (value === "Starkenn@1199") {
      try {
        let structuredData = await restructureData(data);

        let insertData = {
          featureset_name: details.featureset_name,
          featureset_version: details.featureset_version,
          featureset_type: details.featureset_type,
          featureset_status: details.featureset_status,
          featureset_option_for: "CAS",
          featureset_data: JSON.stringify(structuredData),
        };

        axios
          .post(`${process.env.REACT_APP_AWS_URL2}/addFeatureset`, insertData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            updateFS();
            closeDialog();
            setPublishPopUpVisible(false);
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: `Featureset ${insertData.featureset_name} added successfully`,
              life: 3000,
            });
          })
          .catch((err) => {
            toastRef.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });

            setPublishPopUpVisible(false);
          });
      } catch (error) {
        setPublishPopUpVisible(false);
      }
    } else {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: `Password is incorrect`,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/${"CAS"}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        let sortedFields = res.data.data[0].optionsdata.sort((a, b) => {
          return a.featureset_option_index - b.featureset_option_index;
        });
        setFields(sortedFields);
        setLoaded(true);

        let defaultData = res.data.data[0].optionsdata.reduce((acc, el) => {
          let uniqueKey = `${el.featureset_option_name}--${el.featureset_group_by}`;
          acc[uniqueKey] = el.featureset_option_default_value;
          return acc;
        }, {});
        setData(defaultData);
      })
      .catch((err) => console.log(err));
  }, [token]);

  if (!loaded) {
    return <PreloaderList />;
  }
  const closePublishDialog = () => {
    setPublishPopUpVisible(false);
    setValue("");
  };
  return (
    <div>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="rounded-lg bg-gray-200 py-6 dark:bg-gray-900">
        <div className="flex justify-between gap-3 rounded px-5 py-2">
          <div className="flex-1">
            <span className="p-float-label">
              <InputText
                name="featureset_name"
                className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                onChange={handleDetails}
              />
              <label htmlFor="device_id" className="dark:text-gray-300">
                Featureset Name
              </label>
            </span>
          </div>
          <div className="flex-1">
            <span className="p-float-label">
              <InputText
                name="featureset_version"
                className="border py-1.5 pl-2 text-sm"
                onChange={handleDetails}
              />
              <label htmlFor="device_id" className="dark:text-gray-300">
                Featureset Version
              </label>
            </span>
          </div>
        </div>
        <div className="mt-4 flex justify-between gap-3 rounded px-5 py-2">
          <div className="flex-1">
            <span className="p-float-label">
              <Dropdown
                id="featureset_type"
                options={vehicleTypeOptions()}
                optionLabel="label"
                optionValue="value"
                className={`p-dropdown h-9 border text-sm `}
                name="featureset_type"
                onChange={handleDetails}
                value={details.featureset_type}
              />
              <label htmlFor="featureset_type" className="dark:text-gray-300">
                Featureset Type
              </label>
            </span>
          </div>
          <div className="flex-1">
            <span className="p-float-label">
              <Dropdown
                id="featureset_status"
                name="featureset_status"
                options={statusOptions()}
                optionLabel="label"
                optionValue="value"
                className={`p-dropdown h-9 border text-sm `}
                onChange={handleDetails}
                value={details.featureset_status}
              />
              <label htmlFor="featureset_status" className="dark:text-gray-300">
                Status
              </label>
            </span>
          </div>
        </div>
      </div>

      <div className="mt-4">
        {Object.entries(
          fields.reduce((acc, options) => {
            let dropdownOptions = [];
            try {
              if (typeof options.featureset_option_dropmenu === "string") {
                dropdownOptions = JSON.parse(
                  options.featureset_option_dropmenu
                );
                if (!Array.isArray(dropdownOptions)) {
                  throw new Error("Dropdown options is not an array");
                }
              } else if (Array.isArray(options.featureset_option_dropmenu)) {
                dropdownOptions = options.featureset_option_dropmenu;
              } else {
                throw new Error("Dropdown options is not a string or array");
              }
            } catch (error) {
              dropdownOptions = [];
            }

            const groupIndex = options.featureset_option_index;
            if (!acc[groupIndex]) {
              acc[groupIndex] = [];
            }

            acc[groupIndex].push(
              <div key={options.featureset_option_id}>
                {options.featureset_option_input_type === "dropdown" ? (
                  <div className="mt-8">
                    <span className="p-float-label">
                      <Dropdown
                        id={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ]
                        }
                        options={dropdownOptions}
                        className="h-9 w-full border dark:bg-gray-900"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: `${options.featureset_option_name}--${options.featureset_group_by}`,
                              value: e.value,
                            },
                          })
                        }
                        optionLabel="label"
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        className="dark:text-gray-300"
                      >
                        {options.featureset_option_label}
                      </label>
                    </span>
                  </div>
                ) : options.featureset_option_input_type === "radio" ? (
                  <div
                    key={options.featureset_option_id}
                    className="mt-2 w-full"
                  >
                    <label className="text-base text-gray-700  dark:text-gray-300">
                      {options.featureset_option_label}
                    </label>
                    <div className="mt-1">
                      <input
                        type="radio"
                        id={`${options.featureset_option_name}-enable`}
                        name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value="1"
                        checked={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ] === "1"
                        }
                        onChange={handleChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}-enable`}
                        className="dark:text-gray-300"
                      >
                        Enable
                      </label>
                      <input
                        type="radio"
                        id={`${options.featureset_option_name}-disable`}
                        name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value="0"
                        checked={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ] === "0"
                        }
                        onChange={handleChange}
                        style={{ marginLeft: "15px", marginRight: "5px" }}
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}-disable`}
                        className="dark:text-gray-300"
                      >
                        Disable
                      </label>
                    </div>
                  </div>
                ) : (
                  <div key={options.featureset_option_id} className=" w-[1/3]">
                    <div className="mt-8">
                      <span className="p-float-label">
                        <InputText
                          type={options.featureset_option_input_type}
                          name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          // placeholder={options.featureset_option_placeholder}
                          value={
                            data[
                              `${options.featureset_option_name}--${options.featureset_group_by}`
                            ]
                          }
                          onChange={handleChange}
                          className="h-9 w-full border pl-2 dark:bg-gray-900 dark:text-gray-300"
                        />
                        <label
                          htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          className="dark:text-gray-300"
                        >
                          {options.featureset_option_label}
                        </label>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );

            return acc;
          }, {})
        ).map(([groupIndex, elements]) => (
          <div key={groupIndex}>
            <div className="grid grid-cols-3 gap-4 rounded border-[1px] bg-[#E9ECEF] p-3 dark:border-none dark:bg-navy-900 2xl:grid-cols-3">
              {elements}
            </div>
            <br />
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-end px-5">
        <button
          // type="submit"
          onClick={() => {
            setPublishPopUpVisible(true);
          }}
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        >
          Add featureset
        </button>
      </div>
      {/* </form> */}
      <Dialog
        visible={publishPopUpVisible}
        onHide={closePublishDialog}
        header="Confirm Publish"
        footer={
          <div>
            <Button
              label="Publish"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleSubmit}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={closePublishDialog}
            />
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          {/* <MdDeleteOutline className="text-2xl text-blue-400" /> */}
          <span className="text-sm font-semibold">
            Are you sure you want to publish ?
          </span>
          <label htmlFor="password">
            <span className="flex">
              Enter password
              <MdStarRate className="text-[0.5rem] text-red-500" />
            </span>
          </label>
          <InputText
            id="password"
            value={value}
            type="password"
            onChange={(e) => setValue(e.target.value)}
            className="border-2 border-gray-600 pl-2"
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AddFeatureSetRoot;
