import React, { useState, useRef, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import axios from "axios";
import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Preloader from "./Preloader";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { v4 as uuidv4 } from "uuid";
import mainTracker from "../../Tracker";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

const SignIn = () => {
  //TODO complete functions for the cookie use
  function epochToHHMMSS(epochTime) {
    const date = new Date(0); // Create a Date object with the epoch time
    date.setUTCSeconds(epochTime); // Set the date to the provided epoch time
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    date.setMilliseconds(date.getMilliseconds() + ISTOffset); // Adjust for IST
    const hours = date.getUTCHours().toString().padStart(2, "0"); // Get hours (in IST)
    const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Get minutes (in IST)
    const seconds = date.getUTCSeconds().toString().padStart(2, "0"); // Get seconds (in IST)
    return `${hours}:${minutes}:${seconds}`; // Return the formatted time
  }
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based, so add 1 and pad with '0' if needed
    const day = String(date.getDate()).padStart(2, "0");
    const time = epochToHHMMSS(date.getTime());
    return `${year}-${month}-${day} , ${time}`;
  }

  const cookiesFunction = async () => {
    const uuid = uuidv4();
    let data = {
      session_ip: "",
      session_location: "",
      session_creation_time: "",
      session_browser: "",
      session_device_type: "",
      session_id: uuid,
    };

    const ip = await axios.get("https://ipapi.co/json");
    data.session_ip = ip.data.ip;

    data.session_location =
      ip.data.city + " " + ip.data.region + " " + ip.data.country_name;

    const currentDate = new Date();
    data.session_creation_time = formatDateToYYYYMMDD(currentDate);

    data.session_browser = `name : ${browserName} , version : ${browserVersion}`;
    data.session_device_type = `osName : ${osName} , version : ${osVersion}`;

    //storing the cookie here;
    return data;
  };
  //FIXME end of the complete use for the cookie

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] =
    useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showNewPasswordFields, setShowNewPasswordFields] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [emailforotp, setEmailForOTP] = useState("");
  const [otp, setOtp] = useState("");
  const [changepassword, setChangePassword] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);
  const [changingPw, setChangingPw] = useState(false);
  const [showError, setShowError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [pwError, setPwError] = useState(false);
  const [darkmode, setDarkmode] = useState(false);
  const toastRef = useRef();

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkmode");
    localStorage.setItem("last_marker", JSON.stringify([]));
    localStorage.setItem("LIMP", JSON.stringify([]));
    localStorage.setItem("ACD", JSON.stringify([]));
    localStorage.setItem("alerts", JSON.stringify([]));

    if (storedDarkMode === "true") {
      document.body.classList.add("dark");
      setDarkmode(true);
    } else if (!storedDarkMode) {
      document.body.classList.remove("dark");
      setDarkmode(false);
    } else {
      document.body.classList.remove("dark");
      setDarkmode(false);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleVerificationUseronLogin = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/requestEmailResetPassword`,
        { email: email }
      );
      if (response.status === 200) {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Email sent successfully",
          life: 3000,
        });
        // You can add any additional logic or feedback to the user here
      } else {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Failed to send email, login again",
          life: 3000,
        });
        navigate("/signin");
      }
    } catch (err) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Failed to verify login again",
        life: 3000,
      });
      navigate("/signin");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!data.email.trim() || !data.password.trim()) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all the details.",
        life: 3000,
      });
      return;
    }
    if (data.email && data.password) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_AWS_URL1}/loginUser`, data)
        // .post(`${process.env.REACT_APP_API_URL}/login`, data)
        .then(async (res) => {
          const DeviceCookies = await cookiesFunction();
          const token = res.data.data.token;
          const org_id = res.data.data.userData.org_id;
          const user_type = res.data.data.userData.user_type_id;
          const user_id = res.data.data.userData.user_id;
          const first_name = res.data.data.userData.first_name;
          const email_verified = res.data.data.userData.email_verified;
          const session_ip = DeviceCookies.session_ip;
          const session_browser = DeviceCookies.session_browser;
          const session_creation_time = DeviceCookies.session_creation_time;
          const session_device_type = DeviceCookies.session_device_type;
          const session_location = DeviceCookies.session_location;
          const session_id = DeviceCookies.session_id;
          const session_meta = JSON.stringify(DeviceCookies);
          const expirationTime = new Date();
          expirationTime.setDate(expirationTime.getDate() + 1); // Cookie expires in 7 days (1 week)

          Cookies.set("token", token, {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("user_id", user_id, {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("user_type", user_type, {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("first_name", first_name, {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("org_id", org_id, {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("session_data", JSON.stringify(DeviceCookies), {
            expires: expirationTime,
            sameSite: "strict",
          });
          Cookies.set("email_verified", email_verified, {
            expires: expirationTime,
            sameSite: "strict",
          });

          if (email_verified === 0) {
            handleVerificationUseronLogin(data.email);
          } else {
            navigate("/dashboard");
          }
          axios
            .post(
              `${process.env.REACT_APP_AWS_URL1}/createSessions`,
              {
                session_browser,
                session_creation_time,
                session_device_type,
                session_ip,
                session_location,
                session_id,
                session_meta,
              },
              {
                headers: { Authorization: token },
              }
            )
            .then(() => {
              mainTracker(
                JSON.stringify({
                  info: "Login Tries",
                  initial: "login",
                  next: "dashboard",
                  clicked: "login button",
                }),
                1
              );
            })
            .catch((err) => {
              console.log(err);
            });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail:
              err.response?.data?.message ||
              "An error occurred. Please try again later.",
            life: 3000,
          });
        });
    }
  };

  // const handleForgotPasswordClick = () => {
  //   setShowForgotPasswordDialog(true);
  // };
  const handleForgotPasswordNext = async () => {
    try {
      if (!emailforotp.trim()) {
        setShowError(true);
        return;
      }
      setSendingOTP(true);
      const response = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/requestEmailResetPassword`,
        { email: emailforotp }
      );
      if (response.status === 200) {
        setShowOTPInput(false);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "OTP sent successfully",
          life: 3000,
        });
        // You can add any additional logic or feedback to the user here
      } else {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Failed to send OTP",
          life: 3000,
        });
      }
    } catch (error) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Error sending OTP",
        life: 3000,
      });
    } finally {
      setSendingOTP(false);
      setShowForgotPasswordDialog(false);
    }
  };

  const handleOTPSubmit = async () => {
    try {
      if (!otp.trim()) {
        setOtpError(true);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password-otp-verify`,
        { email: emailforotp, otp: otp }
      );
      if (response.status === 200) {
        setOtp("");
        setShowOTPInput(false);
        setShowNewPasswordFields(true);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "OTP verified successfully",
          life: 3000,
        });
      } else {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Failed to send OTP",
          life: 3000,
        });
      }
    } catch (error) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: error.response.data.message || "Error sending OTP",
        life: 3000,
      });
    }
  };

  const handleChangePassword = async () => {
    setChangingPw(true);
    try {
      if (!changepassword.trim() && !confirmNewPassword.trim()) {
        setPwError(true);
        setChangingPw(false);
        return;
      }
      if (changepassword !== confirmNewPassword) {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Passwords do not match",
          life: 3000,
        });
        setChangingPw(false);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password-change`,
        { email: emailforotp, password: changepassword }
      );
      if (response.status === 200) {
        setOtp("");
        setShowOTPInput(false);

        setEmailForOTP("");
        setShowForgotPasswordDialog(false);
        setShowNewPasswordFields(false);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password changed successfully",
          life: 3000,
        });
      } else {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Failed to change password",
          life: 3000,
        });
      }
    } catch (error) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: error.response.data.message || "Error in changing password",
        life: 3000,
      });
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-6 dark:!bg-gray-800 sm:py-12">
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      {loading ? (
        <Preloader />
      ) : (
        <div className="relative py-3 sm:mx-auto sm:max-w-xl ">
          <div
            className="fixed bottom-10 right-10 cursor-pointer justify-end text-gray-600"
            onClick={() => {
              const newMode = !darkmode;
              localStorage.setItem("darkmode", newMode);

              if (newMode) {
                document.body.classList.add("dark");
              } else {
                document.body.classList.remove("dark");
              }

              setDarkmode(newMode);
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-6 w-6 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-6 w-6 text-gray-600 dark:text-white" />
            )}
          </div>
          <div className="absolute inset-0 -skew-y-6 transform bg-gradient-to-r from-blue-300 to-blueSecondary shadow-lg sm:-rotate-6 sm:skew-y-0 sm:rounded-3xl"></div>
          <div className="relative bg-white px-4 py-10 shadow-lg  dark:!bg-gray-750 dark:shadow-2xl sm:rounded-3xl sm:p-20">
            <div className="mx-auto max-w-md">
              <div>
                <h1 className="text-2xl opacity-70 dark:!text-white">
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "500",
                    }}
                  >
                    Welcome To Starkenn Technologies
                  </span>
                </h1>
              </div>

              <div className="divide-y">
                <div className="space-y-8 py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7">
                  <form onSubmit={handleSubmit}>
                    <div className="relative mt-4">
                      <span className="p-float-label">
                        <InputText
                          id="email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          className="peer block w-full appearance-none rounded-t-lg border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 py-2.5 pl-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:!bg-gray-800 dark:!text-white dark:focus:border-blue-500"
                        />
                        <label htmlFor="email">
                          <p className="text-[1rem] dark:text-gray-300">
                            Username
                          </p>
                        </label>
                      </span>
                    </div>
                    <div className="relative my-12">
                      <span className="p-float-label">
                        <InputText
                          autoComplete="off"
                          id="password"
                          name="password"
                          onChange={handleChange}
                          type={showPassword ? "text" : "password"}
                          minLength={6}
                          className="peer block w-full appearance-none rounded-t-lg border-0 border-b-2 border-gray-300 bg-gray-50 py-2.5 pl-2 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:!bg-gray-800 dark:!text-white dark:focus:border-blue-500"
                        />
                        <label
                          htmlFor="password"
                          className="text-[1rem] dark:text-gray-300"
                        >
                          Password
                        </label>
                      </span>

                      <div className="absolute right-2.5 top-3">
                        {showPassword ? (
                          <FaEyeSlash
                            className="h-5 w-5 cursor-pointer  text-gray-500"
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <FaEye
                            className="h-5 w-5 cursor-pointer text-gray-600"
                            onClick={togglePasswordVisibility}
                          />
                        )}
                      </div>
                    </div>

                    <div className="relative">
                      <button
                        type="submit"
                        className="flex items-center rounded-md bg-blueSecondary px-4 py-1 text-white dark:!bg-gray-100 dark:!text-gray-850"
                      >
                        Sign In
                        <BsArrowRightCircleFill className="ml-1" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            visible={showForgotPasswordDialog}
            onHide={() => {
              setShowForgotPasswordDialog(false);
              setShowOTPInput(false);
              setEmailForOTP("");
              setOtp("");
              setOtpError(false);
              setPwError(false);
              setChangePassword("");
              setShowError(false);
              setConfirmNewPassword("");
              setShowNewPasswordFields(false);
            }}
            header={showOTPInput ? "Enter OTP" : "Forgot Password"}
            style={{ width: "35rem", height: "fit-content" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            modal
            className="p-fluid dark:bg-gray-900"
          >
            {showOTPInput ? (
              <div>
                <div className="mt-8">
                  <span className="p-float-label">
                    <InputText
                      keyfilter="pint"
                      id="otp"
                      value={otp}
                      className={`border py-2 pl-2 ${
                        otpError ? "p-invalid" : ""
                      }`}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setOtpError(false);
                      }}
                    />
                    <label htmlFor="otp">OTP</label>
                  </span>
                  {otpError && (
                    <small className="p-error">OTP cannot be empty</small>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="rounded-md bg-blueSecondary px-4 py-1 text-white dark:!bg-gray-100 dark:!text-gray-850"
                    onClick={handleOTPSubmit}
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            ) : showNewPasswordFields ? (
              <div>
                {/* New Password input field */}
                <div className="mt-8">
                  <span className="p-float-label">
                    <InputText
                      id="newPassword"
                      type={showPassword ? "text" : "password"}
                      name="changepassword"
                      className={`border py-2 pl-2 ${
                        pwError ? "p-invalid" : ""
                      }`}
                      value={changepassword}
                      onChange={(e) => {
                        setChangePassword(e.target.value);
                        setPwError(false);
                      }}
                    />
                    <label htmlFor="newPassword">New Password</label>
                  </span>
                  <div className="absolute right-[2.5rem] top-[7.7rem]">
                    {showPassword ? (
                      <FaEyeSlash
                        className="h-5 w-5 cursor-pointer  text-gray-500"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FaEye
                        className="h-5 w-5 cursor-pointer text-gray-600"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                  {pwError && (
                    <small className="p-error">Password cannot be empty</small>
                  )}
                </div>
                {/* Confirm New Password input field */}
                <div className="mt-8">
                  <span className="p-float-label">
                    <InputText
                      id="confirmNewPassword"
                      type={showPassword ? "text" : "password"}
                      className={`border py-2 pl-2 ${
                        pwError ? "p-invalid" : ""
                      }`}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <label htmlFor="confirmNewPassword">
                      Confirm New Password
                    </label>
                  </span>
                  <div className="absolute right-[2.5rem] top-[13.7rem]">
                    {showPassword ? (
                      <FaEyeSlash
                        className="h-5 w-5 cursor-pointer  text-gray-500"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FaEye
                        className="h-5 w-5 cursor-pointer text-gray-600"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                  {pwError && (
                    <small className="p-error">
                      Confirm password cannot be empty
                    </small>
                  )}
                </div>
                {/* Change Password button */}
                <div className="mt-4 text-center">
                  <button
                    className={`relative rounded-md bg-blueSecondary px-4 py-1 text-white dark:!bg-gray-100 dark:!text-gray-850 ${
                      changingPw ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={handleChangePassword}
                    disabled={changingPw}
                  >
                    <div className="flex items-center">
                      {changingPw ? (
                        <div className="flex items-center">
                          Changing Password
                          <div className="ml-2 h-6 w-6 animate-spin rounded-full border-r-2 border-t-2 border-gray-50 dark:border-gray-800"></div>
                        </div>
                      ) : (
                        "Change Password"
                      )}
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="mt-8">
                  <span className="p-float-label">
                    <InputText
                      id="username"
                      name="emailforotp"
                      value={emailforotp}
                      className={`border py-2 pl-2 ${
                        showError ? "p-invalid" : ""
                      }`}
                      onChange={(e) => {
                        setEmailForOTP(e.target.value);
                        setShowError(false);
                      }}
                    />
                    <label htmlFor="username">Email</label>
                  </span>
                  {showError && (
                    <small className="p-error">Please enter an email id.</small>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <button
                    className={`relative rounded-md bg-blueSecondary px-4 py-1 text-white dark:!bg-gray-100 dark:!text-gray-850 ${
                      sendingOTP ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={handleForgotPasswordNext}
                    disabled={sendingOTP}
                  >
                    <div className="flex items-center">
                      {sendingOTP ? (
                        <div className="flex items-center">
                          Sending OTP
                          <div className="ml-2 h-6 w-6 animate-spin rounded-full border-r-2 border-t-2 border-gray-50 dark:border-gray-800"></div>
                        </div>
                      ) : (
                        "Send Email"
                      )}
                    </div>
                  </button>
                </div>
              </div>
            )}
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default SignIn;
