import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";

const RecognitionList = ({ data }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [src, setSrc] = useState("");
  const [deleteVisible, setDeleteVisible] = useState(false);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const viewImage = (e) => {
    setSrc(e.target.src);
    setOpenDialog(true);
  };

  const header = (
    <div className="my-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const openDeleteDialog = () => {
    return () => {
      setDeleteVisible(true);
    };
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <RiDeleteBin6Line
          title="Delete"
          className={`mx-2 cursor-pointer text-red-600`}
          onClick={openDeleteDialog(rowData)}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={openDialog}
        onHide={() => setOpenDialog(false)}
        modal
        style={{ width: "fit-content" }}
        header="Image"
      >
        <img src={src} alt="" />
      </Dialog>
      <DataTable
        value={data}
        paginator
        dataKey="serialNo"
        header={header}
        rows={20}
        removableSort
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={["last_name", "first_name"]}
        emptyMessage="No drivers found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-5 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            paddingTop: "0px",
            paddingLeft: "2rem",
            paddingBottom: "0px",
          }}
        ></Column>
        <Column
          field="first_name"
          header="First Name"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
        <Column
          field="last_name"
          header="Last Name"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
        <Column
          field="S3URL"
          header="Image"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          body={(rowData) => {
            return (
              <>
                <img
                  src={rowData.S3URL}
                  alt=""
                  className="h-4 w-8 hover:cursor-pointer"
                  onClick={viewImage}
                />
              </>
            );
          }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
      </DataTable>
      <Dialog
        visible={deleteVisible}
        onHide={() => {
          setDeleteVisible(false);
        }}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              // onClick={handleDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={() => {
                setDeleteVisible(false);
              }}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete driver?
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default RecognitionList;
