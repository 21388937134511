import React, { useEffect, useState } from "react";
import TripList from "./components/TripList";
import axios from "axios";
import Cookies from "js-cookie";
import { Dropdown } from "primereact/dropdown";
// import { BsGrid, BsListUl } from "react-icons/bs";
// import PreloaderGrid from "components/skeleton-preloader/PreloaderGrid";
// import TripGrid from "./components/TripGrid";

const CompletedTrips = () => {
  const token = Cookies.get("token");
  const user_type = Cookies.get("user_type");
  const orgID = Cookies.get("org_id");
  // const [isListView, setIsListView] = useState(
  //   localStorage.getItem("viewPreference") === "grid" ? false : true
  // );
  const [org, setOrg] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [selectedTrips, setSelectedTrips] = useState("ct");
  const [orderby, setOrderby] = useState("DESC");

  const tripOptions = [
    { name: "Completed Trips", value: "ct" },
    { name: "Ongoing Trips", value: "ot" },
  ];

  const orderbyOptions = [
    { name: "Order by Descending", value: "DESC" },
    { name: "Order by Ascending", value: "ASC" },
  ];

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_AWS_URL1}/organizationDetailsGet`, null, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setOrg(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const orgOptions = () => {
    return org?.map((el) => ({
      label: el.orgName,
      value: el.orgId,
    }));
  };
  useEffect(() => {
    if (user_type !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e") {
      setOrgId(orgID);
    }
  }, [user_type, orgID]);

  // const handleToggleView = () => {
  //   const newView = !isListView;
  //   setIsListView(newView);
  //   // Store the view preference in localStorage
  //   localStorage.setItem("viewPreference", newView ? "list" : "grid");
  // };

  return (
    <>
      <div className="flex justify-between">
        <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
          Trips
        </h4>

        {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" ? (
          <div className="card flex sm-max:grid sm-max:grid-cols-2 sm-max:mt-8 sm-max:text-end gap-2">
            <Dropdown
              value={orgId}
              onChange={(e) => setOrgId(e.value || null)}
              options={orgOptions()}
              optionLabel="label"
              showClear={orgId ? true : false}
              placeholder="Filter by organization"
              className="h-7 w-fit dark:bg-gray-400 dark:text-gray-900"
            />
            <Dropdown
              value={selectedTrips}
              onChange={(e) => setSelectedTrips(e.value || null)}
              options={tripOptions}
              optionLabel="name"
              placeholder="All trips"
              className="mx-2 h-7 w-fit dark:bg-gray-400 dark:text-gray-900"
            />
            <Dropdown
              value={orderby}
              onChange={(e) => setOrderby(e.value || null)}
              options={orderbyOptions}
              optionLabel="name"
              className="mx-2 h-7 w-fit dark:bg-gray-400 dark:text-gray-900"
            />
            {/* <div>
              <button
                className={`${
                  isListView === true
                    ? "list-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "list-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsListUl />
              </button>
              <button
                className={`${
                  isListView === false
                    ? "grid-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "grid-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsGrid />
              </button>
            </div> */}
          </div>
        ) : (
          <div className="flex items-center sm-max:mt-8 sm-max:flex-wrap sm-max:justify-end sm-max:gap-y-2">
            <Dropdown
              value={selectedTrips}
              onChange={(e) => setSelectedTrips(e.value || null)}
              options={tripOptions}
              optionLabel="name"
              placeholder="All trips"
              className="mx-2 h-7 w-fit dark:bg-gray-400 dark:text-gray-900"
            />
            <Dropdown
              value={orderby}
              onChange={(e) => setOrderby(e.value || null)}
              options={orderbyOptions}
              optionLabel="name"
              className="mx-2 h-7 w-fit dark:bg-gray-400 dark:text-gray-900"
            />
            {/* <div>
              <button
                className={`${
                  isListView === true
                    ? "list-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "list-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsListUl />
              </button>
              <button
                className={`${
                  isListView === false
                    ? "grid-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "grid-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsGrid />
              </button>
            </div> */}
          </div>
        )}
      </div>
      {/* {!isListView && (
        <div className="opacity-100 transition-opacity duration-500">
          {loaded ? (
            <PreloaderGrid />
          ) : (
            <div className="mt-1">
              <TripGrid data={data} trips={selectedTrips} />
            </div>
          )}
        </div>
      )} */}
      {/* {isListView && ( */}
      <div className="opacity-100 transition-opacity duration-500">
        <div className="mt-1">
          <TripList orgId={orgId} orderby={orderby} trips={selectedTrips} />
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default CompletedTrips;
