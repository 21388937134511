import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { Tag } from "primereact/tag";
import { MdDeleteOutline } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import * as XLSX from "xlsx";

const ReportList = ({ data, updateData }) => {
  console.log(updateData);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const token = Cookies.get("token");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const toastRef = useRef(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="mb-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const openDeleteDialog = (rowData) => {
    setSelectedReport(rowData);
    setDeleteDialogVisible(true);
  };

  const formatDate = (timestamp, reportType) => {
    if (!timestamp) return "--";

    try {
      // For Scheduled reports (type "1") - timestamp is in milliseconds
      if (reportType === "1") {
        const date = new Date(Number(timestamp));
        if (isNaN(date.getTime()) || date.getFullYear() === 1970) {
          return "--";
        }
        return date.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      }
      // For Generated reports (type "0") - timestamp needs multiplication by 1000
      else {
        const date = new Date(timestamp * 1000);
        if (isNaN(date.getTime()) || date.getFullYear() === 1970) {
          return "--";
        }
        return date.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      }
    } catch (error) {
      console.error("Error formatting date:", error);
      return "--";
    }
  };

  const formatExcelData = (rawData) => {
    // Format the data into a structure suitable for Excel
    const formattedData = rawData.map((item) => {
      // Convert timestamps to UTC dates
      const fromDateUTC = new Date(Number(item.tripStart) * 1000).toUTCString();
      const toDateUTC = new Date(Number(item.tripEnd) * 1000).toUTCString();

      return {
        "Vehicle Number": item.VehicleReg || "--",
        "From Date": fromDateUTC,
        "To Date": toDateUTC,
        Distance: item.totalDistance || "0",
        "Max Speed (km/h)": item.maxSpd || "0",
        "Avg Speed (km/h)": item.avgSpd || "0",
        Duration: item.totalDuration || "0",
        "Alarm 2": item.ALM2 || "0",
        "Alarm 3": item.ALM3 || "0",
        "Harsh Acceleration": item.HRA || "0",
        "Lane Change": item.LCH || "0",
        "Speed Bump": item.SPB || "0",
        "Sudden Brake": item.SUB || "0",
        Tailgating: item.TAL || "0",
        "CAS Overspeed": item.CAO || "0",
        "Alcohol Fail": item.ALCF || "0",
        "Alcohol Pass": item.ALCP || "0",
        "Alcohol Timeout": item.ALCT || "0",
        "Wrong Start": item.WRS || "0",
        Drowsiness: item.DROW || "0",
        Distraction: item.DIS || "0",
        "No Driver": item.NODR || "0",
        "Trip Start": item.TS || "0",
        "DMS Overspeed": item.DMSO || "0",
        "Accident Saved": item.ASV || "0",
        "Automatic Braking": item.AUB || "0",
      };
    });

    return formattedData;
  };

  const createExcelFile = (formattedData, reportTitle) => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create worksheet with the formatted data
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Set column widths
    const colWidths = [
      { wch: 15 }, // Vehicle Number
      { wch: 20 }, // Date
      { wch: 40 }, // Location
      { wch: 15 }, // Speed
      { wch: 15 }, // Status
      { wch: 15 }, // Distance
      { wch: 15 }, // Duration
    ];
    ws["!cols"] = colWidths;

    // Add header styling
    const headerRange = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!ws[address]) continue;
      ws[address].s = {
        fill: { fgColor: { rgb: "4F81BD" } },
        font: { color: { rgb: "FFFFFF" }, bold: true },
        alignment: { horizontal: "center" },
      };
    }

    // Add the worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Report Data");

    return wb;
  };

  const handleDownloadExcel = async (rowData) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_AWS_URL1}/getReportsData/${rowData.vehicles}`,
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: {
          fromDate: rowData.from_date,
          toDate: rowData.to_date,
        },
      });

      if (!response.data || !response.data.data) {
        throw new Error("No data received from the server");
      }

      // Format the data
      const formattedData = formatExcelData(response.data.data);

      // Create Excel file
      const workbook = createExcelFile(formattedData, rowData.title);

      // Generate filename
      const fromDate = new Date(rowData.from_date * 1000)
        .toISOString()
        .split("T")[0];
      const toDate = new Date(rowData.to_date * 1000)
        .toISOString()
        .split("T")[0];
      const filename = `Vehicle_Report_${fromDate}_to_${toDate}.xlsx`;

      // Write the file and trigger download
      XLSX.writeFile(workbook, filename);

      // Show success message
      if (toastRef.current) {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Report downloaded successfully",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      if (toastRef.current) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to download the report",
          life: 3000,
        });
      }
    }
  };

  const getScheduleTypeInfo = (type, reportType) => {
    // Return "--" if it's a generated report (type "0")
    if (reportType === "0") {
      return {
        label: "--",
        classes: "text-gray-500",
      };
    }

    // For scheduled reports (type "1"), show the schedule type
    switch (type) {
      case "1":
        return {
          label: "Daily",
          classes: "bg-purple-100 text-purple-700",
        };
      case "2":
        return {
          label: "Weekly",
          classes: "bg-amber-100 text-amber-700",
        };
      case "3":
        return {
          label: "Monthly",
          classes: "bg-teal-100 text-teal-700",
        };
      default:
        return {
          label: "--",
          classes: "text-gray-500",
        };
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => openDeleteDialog(rowData)}
          className="mx-2 cursor-pointer text-red-600"
        />
        {rowData.reports_type === "0" && (
          <>
            <FaRegEye
              title="View"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BASE_URL}/report/${rowData.vehicles}/${rowData.from_date}/${rowData.to_date}`
                )
              }
              className="cursor-pointer text-gray-700"
            />
            <BsDownload
              title="Download"
              onClick={() => handleDownloadExcel(rowData)}
              className="mx-2 cursor-pointer text-blue-600"
            />
          </>
        )}
      </div>
    );
  };

  const DeleteDeviceDialog = ({ visible, onHide, report_uuid }) => {
    const handleConfirmDelete = async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_AWS_URL2}/deleteReports/${report_uuid}`,
          {},
          {
            headers: { Authorization: token }, // Make sure the authorization header is correctly formatted
          }
        );

        onHide();
        if (res.status === 200) {
          // Report deleted successfully
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: `Report ${selectedReport.title} deleted successfully!`,
            life: 3000,
          });
          updateData(
            data.filter(
              (report) => report.report_uuid !== selectedReport.report_uuid
            )
          );
          onHide(); // Close the dialog or take any other required action
        } else {
          throw new Error("Delete failed");
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleConfirmDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {selectedReport?.title}?
          </span>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        removableSort
        value={data}
        dataKey="report_uuid"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reports"
        filterDisplay="menu"
        filters={filters}
        globalFilterFields={[
          "title",
          "reports_schedule_type",
          "reports_type",
          "report_created_at",
        ]}
        emptyMessage="No reports found."
        header={header}
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        ></Column>
        {/* <Column
          field="r_id"
          header="Report ID"
          sortable
          className="border-b dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", border: "none !important" }}
        ></Column> */}

        <Column
          field="title"
          header="Report Title"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="report_created_at"
          header="Generate Date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
          body={(rowData) => {
            if (rowData.report_created_at) {
              const date = new Date(rowData.report_created_at);
              const formattedDate = `${String(date.getDate()).padStart(
                2,
                "0"
              )}-${String(date.getMonth() + 1).padStart(
                2,
                "0"
              )}-${date.getFullYear()}`;
              const formattedTime = `${String(date.getHours()).padStart(
                2,
                "0"
              )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
                date.getSeconds()
              ).padStart(2, "0")}`;
              return (
                <Tag
                  className="mr-2 bg-gray-200 text-gray-800"
                  icon="pi pi-calendar"
                  style={{
                    width: "fit-content",
                    height: "25px",
                  }}
                  value={formattedDate + " " + formattedTime}
                />
              );
            } else {
              return "--";
            }
          }}
        ></Column>
        <Column
          field="from_date"
          header="From date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          body={(rowData) =>
            formatDate(rowData.from_date, rowData.reports_type)
          }
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>

        <Column
          field="to_date"
          header="To date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          body={(rowData) => formatDate(rowData.to_date, rowData.reports_type)}
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="reports_type"
          header="Report Type"
          body={(rowData) => (
            <span
              className={`rounded-md px-2 py-1 text-sm font-medium ${
                rowData.reports_type === "1"
                  ? "bg-green-100 text-green-700"
                  : "bg-blue-100 text-blue-700"
              }`}
            >
              {rowData.reports_type === "1" ? "Scheduled" : "Generated"}
            </span>
          )}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "7rem", padding: "5px" }}
        />
        <Column
          field="reports_schedule_type"
          header="Schedule Type"
          body={(rowData) => {
            const { label, classes } = getScheduleTypeInfo(
              rowData.reports_schedule_type,
              rowData.reports_type // Pass the report type to check if it's scheduled
            );
            console.log(label);
            return (
              <span
                className={`inline-flex items-center rounded-md px-2.5 py-1 text-sm font-medium ${classes}`}
              >
                {label}
              </span>
            );
          }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ width: "10rem", padding: "5px" }}
        />
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "6rem", padding: "5px" }}
        ></Column>
      </DataTable>
      <DeleteDeviceDialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        report_uuid={selectedReport ? selectedReport.report_uuid : null}
      />
    </>
  );
};

export default ReportList;
