import React, { useRef } from "react";
import ReactApexChart from "react-apexcharts";

const GraphLoad = ({ data }) => {
  console.log("data::", data);
  const chartRef = useRef();

  // Check if data is available and valid
  const isDataAvailable = () => {
    return (
      data &&
      data.load &&
      Array.isArray(data.load) &&
      data.load.length > 0 &&
      data.time &&
      Array.isArray(data.time) &&
      data.time.length > 0 &&
      data.load.length === data.time.length
    );
  };

  const formatXAxisDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const formatDisplayDate = (timestamp) => {
    const date = new Date(timestamp);
    return date
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
      .replace(",", "");
  };

  const formatISOTimestamp = (timestamp) => {
    return new Date(timestamp).toISOString().split(".")[0];
  };

  const downloadCSV = () => {
    if (chartRef.current && chartRef.current.chart) {
      const csvData = data.time.map((time, index) => ({
        // raw_timestamp: time,
        formatted_timestamp: formatDisplayDate(time),
        iso_timestamp: formatISOTimestamp(time),
        load: data.load[index],
      }));

      //this is a graph of Load

      // Create CSV content with headers
      const csvContent = [
        ["Formatted Timestamp", "ISO Timestamp", "Load"],
        ...csvData.map((row) => [
          // row.raw_timestamp,
          row.formatted_timestamp,
          row.iso_timestamp,
          row.load,
        ]),
      ]
        .map((row) => row.join(","))
        .join("\n");

      // Create and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `load_data_${formatISOTimestamp(new Date()).split("T")[0]}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  // If data is not available, render a message
  if (!isDataAvailable()) {
    return (
      <div className="flex h-[350px] items-center justify-center text-gray-500">
        Data is not available in the selected date range
      </div>
    );
  }

  const options2 = {
    series: [
      {
        name: "Load",
        data: data.load,
      },
    ],
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          pan: false,
        },
      },
      height: 350,
      type: "area",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF1654"],
    stroke: {
      width: [4],
    },
    xaxis: {
      type: "datetime",
      categories: data.time,
      labels: {
        formatter: function (value) {
          return formatXAxisDate(value);
        },
        style: {
          fontSize: "12px",
        },
      },
      tickAmount: 6,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FF1654",
        },
        labels: {
          style: {
            colors: "#FF1654",
          },
          formatter: function (val) {
            return `${val.toFixed(1)}T`; // Format to 1 decimal and append "Liters"
          },
        },
        title: {
          text: "Load",
          style: {
            color: "#FF1654",
          },
        },
      },
    ],

    tooltip: {
      enabled: true,
      x: {
        formatter: function (value) {
          return formatDisplayDate(value); // Keep full date and time in tooltip
        },
      },
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)}`;
        },
        title: {
          formatter: () => "Load: ",
        },
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  return (
    <div>
      <button
        className="flex items-center rounded-md border-[1px] border-gray-700 bg-white px-2 py-0.5 dark:bg-gray-800 dark:text-gray-100"
        onClick={downloadCSV}
      >
        Download CSV
      </button>
      <ReactApexChart
        ref={chartRef}
        options={options2}
        series={options2.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default GraphLoad;
