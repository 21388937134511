import React, { useContext, useEffect, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import axios from "axios";
import Cookies from "js-cookie";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { AppContext } from "context/AppContext";
import { FiLoader } from "react-icons/fi";
import { Calendar } from "primereact/calendar";

const Schedule = ({ close }) => {
  const [vehicles, setVehicles] = useState([]);
  const token = Cookies.get("token");
  const user_uuid = Cookies.get("user_uuid");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [titleValid, setTitleValid] = useState(true);
  const [vehicleValid, setVehicleValid] = useState(true);
  const [contactValid, setContactValid] = useState(true);
  const toastRef = useRef(null);
  const [contacts, setContacts] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const repeat_types = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const [repeatType, setRepeatType] = useState(null);
  const [repeatTypeValid, setRepeatTypeValid] = useState(true);

  const { updateReports } = useContext(AppContext);

  const getScheduleMessage = (type) => {
    switch (parseInt(type)) {
      case 1:
        return "Report will be generated and sent daily at 9:00 AM";
      case 2:
        return "Report will be generated and sent every Monday at 9:00 AM";
      case 3:
        return "Report will be generated and sent on the 1st of every month at 9:00 AM";
      default:
        return "Please select a repeat type to see schedule details";
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    setTitleValid(title.trim() !== "");
    setVehicleValid(!!selectedVehicle);
    setContactValid(!!selectedContacts);
    setRepeatTypeValid(!!repeatType);

    if (!title.trim() || !selectedVehicle || !selectedContacts || !repeatType) {
      toastRef.current.show({
        severity: "warn",
        summary: "Fill Required Fields",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
      return;
    }

    setSubmitted(true);

    const selectedRepeatType = repeat_types.find(
      (type) => type.value === parseInt(repeatType)
    );

    // Set fromDate and toDate as "--"
    const fromDate = "--";
    const toDate = "--";

    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/insertReport`,
        {
          title: title,
          fromDate: fromDate,
          toDate: toDate,
          vehicle_id: selectedVehicle,
          events: [],
          contact: selectedContacts,
          reports_type: 1,
          report_schedule_type: selectedRepeatType.value,
          user_uuid: user_uuid,
        },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        updateReports();
        setTimeout(() => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: "Report scheduled successfully" || res.data?.message,
            life: 1000,
          });
        }, 2000);
        setTimeout(() => {
          close();
        }, 3000);
      })
      .catch((error) => {
        setSubmitted(false);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.message || "Failed to schedule report",
          life: 3000,
        });
      });
  };

  const customItemTemplate = (option) => {
    return (
      <div className="py-1">
        {option.vehicle_name} [{option.vehicle_reg_number}]
      </div>
    );
  };

  const selectedValueTemplate = (option) => {
    if (option) {
      return (
        <div>
          {option.vehicle_name} [{option.vehicle_reg_number}]
        </div>
      );
    }
    return <span>Select a vehicle</span>;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getContact`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setContacts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid]);

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="rounded-lg bg-white p-6 shadow-md">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="text-right">
            <span className="text-xs text-red-500">
              All fields are required *
            </span>
          </div>

          <div className="space-y-2">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700 dark:text-gray-200"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleValid(e.target.value.trim() !== "");
              }}
              className={`w-full rounded-md border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white ${
                !titleValid ? "border-red-500" : "border-gray-300"
              }`}
            />
            {!titleValid && (
              <p className="text-xs text-red-500">Title is required</p>
            )}
          </div>

          <div className="grid gap-6 md:grid-cols-2">
            <div className="space-y-2">
              <label
                htmlFor="vehicle"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Select Vehicle
              </label>
              <Dropdown
                id="vehicle"
                value={selectedVehicle}
                onChange={(e) => {
                  setSelectedVehicle(e.value);
                  setVehicleValid(!!e.value);
                }}
                options={vehicles}
                optionLabel="vehicle_name"
                optionValue="vehicle_id"
                placeholder="Select a vehicle"
                className={`w-full border dark:bg-gray-800 ${
                  !vehicleValid ? "!border-red-500" : "!border-gray-300"
                }`}
                itemTemplate={customItemTemplate}
                valueTemplate={selectedValueTemplate}
                filter
                filterBy="vehicle_name,vehicle_reg_number"
                filterPlaceholder="Search vehicles..."
                showClear
                style={{
                  borderRadius: "0.375rem",
                }}
                panelClassName="rounded-md border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700"
                inputClassName="w-full rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white"
                filterInputClassName="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
              />
              {!vehicleValid && (
                <p className="text-xs text-red-500">Vehicle is required</p>
              )}
            </div>

            <div className="space-y-2">
              <label
                htmlFor="contacts"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Select Recipients
              </label>
              <select
                id="contacts"
                value={selectedContacts}
                onChange={(e) => {
                  setSelectedContacts(e.target.value);
                  setContactValid(!!e.target.value);
                }}
                className={`w-full rounded-md border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white ${
                  !contactValid ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Select a recipient</option>
                {contacts
                  ?.filter((contact) => contact.contact_type === "Email")
                  .map((contact) => (
                    <option
                      key={contact.contact_id}
                      value={contact.contact_info}
                    >
                      {contact.contact_name}
                    </option>
                  ))}
              </select>
              {!contactValid && (
                <p className="text-xs text-red-500">Recipient is required</p>
              )}
            </div>

            <div className="space-y-2">
              <label
                htmlFor="repeatType"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Repeat Type
              </label>
              <select
                id="repeatType"
                value={repeatType}
                onChange={(e) => {
                  setRepeatType(e.target.value);
                  setRepeatTypeValid(!!e.target.value);
                }}
                className={`w-full rounded-md border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-white ${
                  !repeatTypeValid ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Select repeat type</option>
                {repeat_types.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              {!repeatTypeValid && (
                <p className="text-xs text-red-500">Repeat type is required</p>
              )}
            </div>

            {repeatType && (
              <div className="col-span-2 space-y-2">
                <div className="rounded-md bg-gray-100 p-4 dark:bg-gray-700">
                  <div className="flex items-center space-x-2">
                    <svg
                      className="h-5 w-5 text-blue-500"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <h3 className="text-sm font-medium text-gray-700 dark:text-gray-200">
                      Schedule Information
                    </h3>
                  </div>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                    {getScheduleMessage(repeatType)}
                  </p>
                  <div className="mt-3 text-xs text-gray-800 dark:text-gray-400">
                    <strong className="text-red-700 dark:text-gray-200">
                      Note :
                    </strong>
                    The schedule will continue indefinitely until you disable or
                    delete the scheduled report.
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              disabled={submitted}
              className={`inline-flex items-center rounded-lg px-4 py-2 text-sm font-medium text-white transition-colors
                ${
                  submitted
                    ? "cursor-not-allowed bg-blue-300"
                    : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                }`}
            >
              {submitted ? (
                <>
                  <svg
                    className="mr-2 h-4 w-4 animate-spin"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Scheduling...
                </>
              ) : (
                "Schedule"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Schedule;
