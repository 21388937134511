import React, { useState, useEffect, useRef } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
// import { BsArrowBarUp } from "react-icons/bs";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
// import { IoMdNotificationsOutline } from "react-icons/io";
import Sidebar from "components/sidebar_admin";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Toast } from "primereact/toast";
import { FaEdit, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { FiCheck, FiLoader } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import logo from "../../assets/img/logo.png";
import { Message } from "primereact/message";

const Navbar = ({ onOpenSidenav }) => {
  const [darkmode, setDarkmode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModeColor, setEditModeColor] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [pwerr, setPwerr] = useState(false);
  const navigate = useNavigate();
  const first_name = Cookies.get("first_name");
  const user_type = Cookies.get("user_type_id");
  const token = Cookies.get("token");
  const toastRef = useRef(null);
  const toastErr = useRef(null);
  const [userDataforProfile, setUserDataforProfile] = useState({});
  const [userDataforProfile2, setUserDataforProfile2] = useState({});

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkmode");

    if (storedDarkMode === "true") {
      document.body.classList.add("dark");
      setDarkmode(true);
    } else if (!storedDarkMode) {
      document.body.classList.remove("dark");
      setDarkmode(false);
    } else {
      document.body.classList.remove("dark");
      setDarkmode(false);
    }
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AWS_URL1}/getUserProfile`,
          {
            headers: { Authorization: token },
          }
        );
        let responseGot = response.data.data[0];
        setUserDataforProfile(responseGot);
        setUserDataforProfile2(responseGot);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (token) {
      fetchUserProfile();
    }
  }, [token]);

  useEffect(() => {
    setUserDataforProfile2({});
    setUserDataforProfile2(userDataforProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenSidenav = () => {
    if (isMobile) {
      setSidebarOpen(!sidebarOpen);
      onOpenSidenav();
    }
  };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("user_type");
    Cookies.remove("user_uuid");
    Cookies.remove("first_name");

    localStorage.clear();
    navigate("/signin");
  };

  const handleInputChange = (fieldName, value) => {
    setUserDataforProfile2({
      ...userDataforProfile2,
      [fieldName]: value,
    });
  };

  const toggleResetPasswordDialog = () => {
    setResetPasswordData("");
    setShowPassword(false);
    setPwerr(false);
    setResetPasswordVisible(!resetPasswordVisible);
  };
  const handleResetPasswordInputChange = (fieldName, value) => {
    setResetPasswordData({
      ...resetPasswordData,
      [fieldName]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPasswordSubmit = () => {
    setIsChanging(true);
    if (
      !resetPasswordData.oldPassword ||
      !resetPasswordData.newPassword ||
      !resetPasswordData.confirmPassword
    ) {
      setPwerr(true);
      toastErr.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please fill in all password fields",
        life: 3000,
      });
      setIsChanging(false);
      return;
    }
    if (resetPasswordData.confirmPassword !== resetPasswordData.newPassword) {
      toastErr.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Passwords do not match",
        life: 3000,
      });
      setIsChanging(false);
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/changePassword`,
        {
          oldpass: resetPasswordData.oldPassword,
          newpass: resetPasswordData.newPassword,
          confirmpass: resetPasswordData.confirmPassword,
        },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        toastErr.current.show({
          severity: "success",
          summary: "Success",
          detail: res.data.message,
          life: 3000,
        });

        setResetPasswordData("");
        toggleResetPasswordDialog();
      })
      .catch((err) => {
        toastErr.current.show({
          severity: "warn",
          summary: "Warning",
          detail: err.response.data.message || "Error in changing password",
          life: 3000,
        });
        setIsChanging(false);
      });
  };

  const handleSubmit = () => {
    setIsUpdating(true);
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/updateUserProfileData`,
        {
          first_name: userDataforProfile2.first_name,
          last_name: userDataforProfile2.last_name,
        },
        { headers: { authorization: token } }
      )
      .then((res) => {
        if (res.status === 200) {
          toastErr.current.show({
            severity: "success",
            summary: "Success",
            detail: "Profile updated successfully",
            life: 3000,
          });
          fetchUserProfile();
        } else {
          toastErr.current.show({
            severity: "danger",
            summary: "Danger",
            detail: "Profile update failed !",
            life: 3000,
          });
        }

        setDialogVisible(false);
        setEditMode(false);
        setEditModeColor(false);
        setIsUpdating(false);
      })
      .catch((err) => {
        // Handle error, show an error toast, etc.
        toastErr.current.show({
          severity: "warn",
          summary: "Warning",
          detail: err.response.data.message,
          life: 3000,
        });
        setIsUpdating(false);
      });

    // const fetchUserProfile = async () => {

    // };
  };
  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AWS_URL1}/getUserProfile`,
        {
          headers: { Authorization: token },
        }
      );
      let responseGot = response.data.data[0];
      setUserDataforProfile(responseGot);
      setUserDataforProfile2(responseGot);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  return (
    <>
      <Dialog
        visible={dialogVisible}
        onHide={() => {
          setDialogVisible(false);
          setEditMode(false);
          setEditModeColor(false);
          setIsUpdating(false);
        }}
        style={{ width: "40rem", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Profile Details"
        modal
        footer={
          editMode ? (
            <div>
              <button
                className={`p-button-primary rounded px-3 py-2 dark:bg-gray-150 dark:font-bold dark:text-blue-800 ${
                  isUpdating
                    ? "cursor-not-allowed bg-blue-200 text-blue-500"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                } `}
                disabled={isUpdating}
                onClick={handleSubmit}
              >
                {isUpdating ? (
                  <>
                    Updating...{" "}
                    <FiLoader className="inline-block animate-spin" />
                  </>
                ) : (
                  <>
                    Update <FiCheck className="inline-block" />
                  </>
                )}
              </button>
            </div>
          ) : null
        }
        className="p-fluid dark:bg-gray-900"
      >
        <div className="text-center">
          <HiOutlineUserCircle className="pi pi-user h-12 w-12  text-gray-400 dark:text-white" />
          <br />
          <p className="py-3 text-xl font-semibold">
            👋 Hey, welcome {userDataforProfile.first_name} !
          </p>
          <hr />
        </div>
        <div className="flex justify-end gap-4">
          <p
            className={`mt-4 cursor-pointer text-right text-sm ${editModeColor}`}
            onClick={() => {
              if (editMode) {
                setEditMode(false);
                setEditModeColor("");
              } else {
                setEditMode(true);
                setEditModeColor("text-green-600");
              }
            }}
          >
            <FaEdit className="mb-1 inline-block" />
            Edit profile
          </p>
          <p
            className="mt-4 cursor-pointer text-right text-sm text-red-400"
            onClick={toggleResetPasswordDialog}
          >
            <FaLock className="mb-1 inline-block" /> Change Password
          </p>
        </div>
        {editMode ? (
          <Message
            className="mt-4 text-xs"
            text="Only first name and last name can be updated !"
          />
        ) : (
          <></>
        )}

        <div className="p-fluid mt-4">
          <div className="flex justify-between">
            <div className="card justify-content-center mr-1 mt-5 flex-auto">
              <span className="p-float-label">
                <InputText
                  id="first_name"
                  name="first_name"
                  value={userDataforProfile2?.first_name}
                  onChange={(e) =>
                    handleInputChange("first_name", e.target.value)
                  }
                  disabled={!editMode}
                  className="border py-2 pl-2"
                  // className={!editedCustomerData.first_name ? "border-red-600" : ""}
                />
                <label htmlFor="first_name">First Name</label>
              </span>
            </div>
            <div className="card justify-content-center ml-1 mt-5 flex-auto">
              <span className="p-float-label">
                <InputText
                  id="last_name"
                  name="last_name"
                  value={userDataforProfile2?.last_name || ""}
                  onChange={(e) =>
                    handleInputChange("last_name", e.target.value)
                  }
                  disabled={!editMode}
                  className="border py-2 pl-2"
                  // className={!editedCustomerData.last_name ? "border-red-600" : ""}
                />
                <label htmlFor="last_name">Last Name</label>
              </span>
            </div>
          </div>
          <div className="mx-auto mt-8">
            <span className="p-float-label">
              <InputText
                id="email"
                type="email"
                name="email"
                value={userDataforProfile2?.email || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.email ? "border-red-600" : ""}
              />
              <label htmlFor="email">Email</label>
            </span>
          </div>
          <div className="mx-auto mt-8">
            <span className="p-float-label">
              <InputText
                id="company_name"
                name="company_name"
                value={userDataforProfile2?.org_name || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.company_name ? "border-red-600" : ""}
              />
              <label htmlFor="company_name">Company Name</label>
            </span>
          </div>
          <div className="mx-auto mb-3 mt-8">
            <span className="p-float-label">
              <InputText
                id="phone"
                keyfilter="pint"
                name="phone"
                value={userDataforProfile2?.contact_info || ""}
                disabled
                className="border py-2 pl-2"
                // className={
                //   isValidPhoneNumber(editedCustomerData?.phone || "")
                //     ? ""
                //     : "border-red-600"
                // }
              />
              <label htmlFor="phone">Contact Number</label>
            </span>
          </div>
          <div className="mx-auto mt-6">
            <span>Address:</span>
          </div>
          <div className="mx-auto mt-6">
            <span className="p-float-label">
              <InputText
                id="address"
                type="text"
                name="address"
                value={userDataforProfile2?.org_address || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.address ? "border-red-600" : ""}
              />
              <label htmlFor="address">Flat No./ Plot No., Area/Society</label>
            </span>
          </div>
          <div className="mx-auto mt-6">
            <span className="p-float-label">
              <InputText
                id="city"
                type="text"
                name="city"
                value={userDataforProfile2?.org_city || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.city ? "border-red-600" : ""}
              />
              <label htmlFor="city">City</label>
            </span>
          </div>
          <div className="mx-auto mt-6">
            <span className="p-float-label">
              <InputText
                id="state"
                name="state"
                value={userDataforProfile2?.org_state || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.state ? "border-red-600" : ""}
              />
              <label htmlFor="state">State</label>
            </span>
          </div>
          <div className="mx-auto mt-6">
            <span className="p-float-label">
              <InputText
                id="pincode"
                keyfilter="pint"
                name="pincode"
                value={userDataforProfile2?.org_pincode || ""}
                disabled
                className="border py-2 pl-2"
                // className={!editedCustomerData.pincode ? "border-red-600" : ""}
              />
              <label htmlFor="pincode">Pincode</label>
            </span>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={resetPasswordVisible}
        onHide={toggleResetPasswordDialog}
        style={{ width: "30rem", height: "fit-content" }}
        header="Change Password"
        modal
        footer={
          <div>
            <button
              className={`p-button-primary rounded px-3 py-2 dark:bg-gray-150 dark:font-bold dark:text-blue-800 ${
                isChanging
                  ? "cursor-not-allowed bg-blue-200 text-blue-500"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              } `}
              disabled={isChanging}
              onClick={handleResetPasswordSubmit}
            >
              {isChanging ? (
                <>
                  Changing Password...{" "}
                  <FiLoader className="inline-block animate-spin" />
                </>
              ) : (
                <>
                  Change Password <FiCheck className="inline-block" />
                </>
              )}
            </button>
          </div>
        }
        className="p-fluid dark:bg-gray-900"
      >
        <div className="p-fluid mt-8">
          <span className="p-float-label">
            <InputText
              id="currentPassword"
              type={showPassword ? "text" : "password"}
              name="oldPassword"
              onChange={(e) => {
                handleResetPasswordInputChange("oldPassword", e.target.value);
                setPwerr(false);
              }}
              className={`border py-2 pl-2 ${pwerr ? "border-red-600" : ""}`}
            />
            <label htmlFor="currentPassword">Current Password</label>
          </span>
          <div className="absolute right-[2.8rem] top-[7.7rem]">
            {showPassword ? (
              <FaEyeSlash
                className="h-5 w-5 cursor-pointer  text-gray-500"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <FaEye
                className="h-5 w-5 cursor-pointer text-gray-600"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          {pwerr && (
            <small className="p-error">Old password cannot be empty.</small>
          )}
        </div>
        <div className="p-fluid mt-8">
          <span className="p-float-label">
            <InputText
              id="newPassword"
              name="newPassword"
              onChange={(e) => {
                handleResetPasswordInputChange("newPassword", e.target.value);
                setPwerr(false);
              }}
              minLength={6}
              className={`border py-2 pl-2 ${pwerr ? "border-red-600" : ""}`}
            />
            <label htmlFor="newPassword">New Password</label>
          </span>
          {pwerr && (
            <small className="p-error">New password cannot be empty.</small>
          )}
        </div>
        <div className="p-fluid mt-8">
          <span className="p-float-label">
            <InputText
              id="confirmPassword"
              name="confirmPassword"
              onChange={(e) => {
                handleResetPasswordInputChange(
                  "confirmPassword",
                  e.target.value
                );
                setPwerr(false);
              }}
              className={`border py-2 pl-2 ${pwerr ? "border-red-600" : ""}`}
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
          </span>
          {pwerr && (
            <small className="p-error">Confirm password cannot be empty.</small>
          )}
        </div>
      </Dialog>

      <Toast ref={toastRef} position="top-right" />
      <Toast ref={toastErr} position="top-right" />
      <nav className="sticky top-0 z-10 flex flex-row flex-wrap items-center justify-between bg-white p-2 backdrop-blur-xl dark:!bg-navy-800">
        <div className="ml-[6px]">
          <div className="flex">
            <img src={logo} className="ml-16 w-[150px] py-1" alt="" />
          </div>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white"></p>
        </div>

        <div className="relative mt-[3px] flex h-[35px] w-[355px] flex-grow items-center justify-end rounded-full bg-white px-2 py-2 dark:!bg-navy-800  dark:shadow-none sm-max:justify-between md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[165px] xl:gap-2">
          {isMobile && (
            <span
              className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
              onClick={handleOpenSidenav}
            >
              <FiAlignJustify className="h-5 w-5" />
            </span>
          )}

          <div
            className={`mr-4 transform cursor-pointer text-gray-600 transition duration-300 hover:scale-105 `}
            onClick={() => {
              const newMode = !darkmode;
              localStorage.setItem("darkmode", newMode);

              if (newMode) {
                document.body.classList.add("dark");
              } else {
                document.body.classList.remove("dark");
              }

              setDarkmode(newMode);
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-5 w-5 hover:text-yellow-400 dark:text-white" />
            ) : (
              <RiMoonFill className="h-5 w-5 hover:text-gray-600 dark:text-white" />
            )}
          </div>

          {/* Profile & Dropdown */}
          {user_type !== "1" ? (
            <Dropdown
              button={
                <HiOutlineUserCircle className="pi pi-user h-6 w-6 cursor-pointer text-gray-400 dark:text-white" />
              }
              children={
                <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        👋 Hey, {userDataforProfile.first_name}
                      </p>
                    </div>
                  </div>
                  <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
                  <div className="flex flex-col p-4">
                    <p
                      href="/profile"
                      className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                      onClick={() => setDialogVisible(true)}
                    >
                      Profile Settings
                    </p>
                    <button
                      onClick={handleLogout}
                      className="mt-3 text-start text-sm font-medium text-red-500 hover:text-red-500"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              }
              classNames={"py-2 top-8 -left-[180px] w-max"}
            />
          ) : (
            <Dropdown
              button={
                <HiOutlineUserCircle className="pi pi-user h-6 w-6 cursor-pointer text-gray-400 dark:text-white" />
              }
              children={
                <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        👋 Hey, {first_name}
                      </p>
                    </div>
                  </div>
                  <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                  <div className="flex flex-col p-4">
                    <button
                      onClick={handleLogout}
                      className="text-start text-sm font-medium text-red-500 hover:text-red-500"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              }
              classNames={"py-2 top-8 -left-[180px] w-max"}
            />
          )}
        </div>
        {isMobile && <Sidebar open={sidebarOpen} onClose={handleOpenSidenav} />}
      </nav>
    </>
  );
};

export default Navbar;
