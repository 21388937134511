// import React, { useState } from "react";
// import { FaChartLine, FaChartBar, FaFileAlt, FaEye } from "react-icons/fa";
// import backgroundImage from "../../../assets/img/reports.png";
// import axios from "axios";
// import Cookies from "js-cookie";

// const Reports = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const org_id = Cookies.get("org_id");

//   const openDashboard = async () => {
//     setIsLoading(true);
//     setError(null);

//     try {
//       const orgResponse = await axios.get(
//         `https://5n6zcgmq7a.execute-api.ap-south-1.amazonaws.com/dev/getOrgName/${org_id}`
//       );

//       const orgName = orgResponse.data.data.org_name;
//       const encodedOrgName = encodeURIComponent(orgName);

//       const dashboardResponse = await axios.get(
//         "https://5n6zcgmq7a.execute-api.ap-south-1.amazonaws.com/dev/getQuickSightReport"
//       );

//       const dashboardUrl = `${dashboardResponse.data.data}#p.Organization=${encodedOrgName}`;
//       window.open(dashboardUrl, "_blank", "noopener,noreferrer");
//     } catch (err) {
//       setError("Failed to load the dashboard. Please try again.");
//       console.error("Error fetching QuickSight URL:", err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const features = [
//     {
//       title: "Real-time Analytics",
//       description: "Monitor your key metrics as they happen",
//       icon: <FaChartLine className="h-8 w-8 text-orange-400" />,
//     },
//     {
//       title: "Custom Reports",
//       description: "Generate detailed insights for your organization",
//       icon: <FaFileAlt className="h-8 w-8 text-orange-300" />,
//     },
//     {
//       title: "Data Visualization",
//       description: "Interactive charts and graphs for better understanding",
//       icon: <FaChartBar className="h-8 w-8 text-orange-500" />,
//     },
//   ];

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-900 via-orange-900 to-gray-900">
//       <div className="container mx-auto px-4 py-12">
//         <header className="mb-12 text-center">
//           <h1 className="mb-4 text-4xl font-bold text-white md:text-5xl lg:text-6xl">
//             Analytics Dashboard
//           </h1>
//           <p className="text-xl text-orange-200">
//             Unlock the power of your data with our comprehensive analytics tools
//           </p>
//         </header>

//         <main className="relative rounded-3xl bg-white/10 p-8 backdrop-blur-lg">
//           <div
//             className="absolute inset-0 bg-cover bg-center opacity-5"
//             style={{ backgroundImage: `url(${backgroundImage})` }}
//           />

//           <div className="relative z-10">
//             <div className="mb-12 text-center">
//               <div className="mb-8 inline-flex h-24 w-24 items-center justify-center rounded-full bg-gradient-to-br from-orange-500 to-orange-600 p-4 shadow-lg">
//                 <FaEye className="h-12 w-12 text-white" />
//               </div>

//               <h2 className="mb-4 text-3xl font-bold text-white md:text-4xl">
//                 Welcome to Your Analytics Hub
//               </h2>

//               <p className="mx-auto mb-8 max-w-2xl text-lg text-orange-100">
//                 Access real-time insights, comprehensive reports, and data
//                 visualization tools to make informed decisions for your
//                 business.
//               </p>

//               <button
//                 className="group relative inline-flex items-center justify-center overflow-hidden rounded-full bg-gradient-to-r from-orange-500 to-orange-600 p-0.5 text-lg font-semibold text-white transition-all duration-300 hover:from-orange-600 hover:to-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-900 disabled:cursor-not-allowed disabled:opacity-70"
//                 onClick={openDashboard}
//                 disabled={isLoading}
//               >
//                 <span className="relative flex items-center gap-2 rounded-full px-8 py-3.5 transition-all duration-300 ease-in-out group-hover:bg-opacity-0">
//                   {isLoading ? (
//                     <>
//                       <div className="h-5 w-5 animate-spin rounded-full border-b-2 border-white"></div>
//                       <span>Loading Dashboard...</span>
//                     </>
//                   ) : (
//                     <>
//                       <FaChartLine className="text-xl" />
//                       <span>Open Analytics Dashboard</span>
//                     </>
//                   )}
//                 </span>
//               </button>

//               {error && (
//                 <div className="animate-fade-in mt-6 rounded-lg bg-red-500/10 p-4 text-red-400">
//                   <p className="text-sm">{error}</p>
//                 </div>
//               )}
//             </div>

//             <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
//               {features.map((feature, index) => (
//                 <div
//                   key={index}
//                   className="flex flex-col items-center rounded-xl bg-white/5 p-6 text-center transition-all duration-300 hover:bg-orange-900/20 hover:shadow-lg"
//                 >
//                   <div className="mb-4 rounded-full bg-gray-800 p-3">
//                     {feature.icon}
//                   </div>
//                   <h3 className="mb-2 text-xl font-semibold text-white">
//                     {feature.title}
//                   </h3>
//                   <p className="text-orange-200">{feature.description}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default Reports;
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReportsList from "./components/ReportsList";
import { Dialog } from "primereact/dialog";
import Schedule from "./components/Schedule";
import Generate from "./components/Generate";
import { FaCalendarPlus, FaChartLine, FaFileContract } from "react-icons/fa";
import Cookies from "js-cookie";
import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { AppContext } from "context/AppContext";
import Quick from "./components/Quick";

const Reports = () => {
  const [isGenerateDialogVisible, setIsGenerateDialogVisible] = useState(false);
  const [isScheduleDialogVisible, setIsScheduleDialogVisible] = useState(false);
  const [isQuickDialogVisible, setIsQuickDialogVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const token = Cookies.get("token");
  const { reports } = useContext(AppContext);
  const fetchData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getReportsAll`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const formattedData = res.data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
        }));
        setLoaded(true);
        setData(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, reports]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openDialog1 = () => {
    setIsGenerateDialogVisible(true);
  };

  const closeDialog1 = () => {
    setIsGenerateDialogVisible(false);
  };

  const openDialog2 = () => {
    setIsScheduleDialogVisible(true);
  };

  const closeDialog2 = () => {
    setIsScheduleDialogVisible(false);
  };

  const openDialog3 = () => {
    setIsQuickDialogVisible(true);
  };

  const closeDialog3 = () => {
    setIsQuickDialogVisible(false);
  };

  return (
    <div>
      <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
        Reports
      </h4>
      <div className="flex gap-2">
        <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog1}
        >
          <FaChartLine />
          &nbsp;Generate
        </button>
        <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog2}
        >
          <FaCalendarPlus className="mr-2 inline-block text-xl" />
          Schedule
        </button>
        {/* <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog3}
        >
          <FaFileContract className="mr-2 inline-block text-xl" />
          Quick
        </button> */}
      </div>
      {loaded ? (
        <ReportsList data={data} updateData={setData} />
      ) : (
        <div className="mt-6">
          <PreloaderList />
        </div>
      )}
      <Dialog
        visible={isGenerateDialogVisible}
        onHide={closeDialog1}
        style={{ width: "45%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Generate close={closeDialog1} />
      </Dialog>
      <Dialog
        visible={isScheduleDialogVisible}
        onHide={closeDialog2}
        style={{ width: "40rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Schedule close={closeDialog2} />
      </Dialog>
      <Dialog
        visible={isQuickDialogVisible}
        onHide={closeDialog3}
        style={{ width: "40rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Quick close={closeDialog3} />
      </Dialog>
    </div>
  );
};

export default Reports;
