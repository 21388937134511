import axios from "axios";
import Cookies from "js-cookie";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect } from "react";

const GeofenceAlerts = () => {
  const token = Cookies.get("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getGeofenceAlerts`, {
        headers: { Authorization: token },
      })
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);
  return (
    <>
      <DataTable
        removableSort
        // value={tag ? data.filter((item) => item.tag === tag) : data}
        dataKey="vehicle_id"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} vehicles"
        filterDisplay="menu"
        // filters={filters}
        // globalFilterFields={[]}
        emptyMessage="No alerts found."
        // header={header}
      >
        <Column
          key="serialNo"
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 2rem",
          }}
        ></Column>
        <Column
          key="vehicle_name"
          field="vehicle_name"
          header="Time"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
        <Column
          field="vehicle_reg_number"
          header="Type"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
      </DataTable>
    </>
  );
};

export default GeofenceAlerts;
