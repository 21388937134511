import axios from "axios";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import AlertTriggerList from "./components/AlertTriggerList";
import { Toast } from "primereact/toast";
import { FiPlus } from "react-icons/fi";
import { MultiSelect } from "primereact/multiselect";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { Button } from "primereact/button";
import { AppContext } from "context/AppContext";

const Triggers = () => {
  const token = Cookies.get("token");
  const org_id = Cookies.get("org_id");
  const user_uuid = Cookies.get("user_id");
  const [visible, setVisible] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [vehiData, setVehiData] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [triggersData, setTriggersData] = useState([]);
  const [geofenceData, setGeofenceData] = useState([]);
  const [inacTriggersdata, setInacTriggersData] = useState([]);
  const [addData, setAddData] = useState([]);
  const [inacData, setInacData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const toastRef = useRef(null);
  const [anyChange, setAnyChange] = useState(false);
  const [geoData, setGeoData] = useState([]);
  const { trigger } = useContext(AppContext);

  const openDialog = () => {
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
    setAddData([]);
    setConfirmDialog(false);
    setInacData([]);
    setFormErrors({});
  };

  const validateForm = () => {
    let errors = {};

    if (!addData.trigger_type) {
      errors.trigger_type = "Trigger type is required";
    }

    if (!addData.trigger_name) {
      errors.trigger_name = "Trigger name is required";
    }

    if (!addData.trigger_description) {
      errors.trigger_description = "Trigger description is required";
    }

    if (!addData.vehicle_id) {
      errors.vehicle_id = "Vehicle name is required";
    }

    if (!addData.phone) {
      errors.phone = "Contact number is required";
    }

    if (!addData.email) {
      errors.email = "Email ID is required";
    }

    return errors;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehiData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid, anyChange]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getContact`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setContactsData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid, anyChange]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllAlertTriggers`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const data1 = res.data.data.filter(
          (item) => item.GeofenceData.trigger_type !== "Geofencing"
        );

        const data2 = res.data.data.filter(
          (item) => item.GeofenceData.trigger_type === "Geofencing"
        );

        const formatedData1 = data1.map((item, ind) => ({
          ...item,
          serialNo: ind + 1,
          trigger_name: item.GeofenceData.trigger_name,
          trigger_type: item.GeofenceData.trigger_type,
          phone: item.GeofenceData.phone,
          email: item.GeofenceData.email,
          trigger_status: item.GeofenceData.trigger_status,
          trigger_sub: item.GeofenceData.trigger_sub,
          vehicle_name: item.GeofenceData.vehicle_name,
          vehicle_id: item.GeofenceData.vehicle_id,
          vehicle_reg_number: item.GeofenceData.vehicle_reg_number,
          Geofence_name: item.GeofenceData.Geofence_name,
          geofence_id: item.GeofenceData.geofence_id,
        }));
        const formatedData2 = data2.map((item, ind) => ({
          ...item,
          serialNo: ind + 1,
          trigger_name: item.GeofenceData.trigger_name,
          trigger_type: item.GeofenceData.trigger_type,
          phone: item.GeofenceData.phone,
          email: item.GeofenceData.email,
          trigger_status: item.GeofenceData.trigger_status,
          trigger_sub: item.GeofenceData.trigger_sub,
          vehicle_id: item.GeofenceData.vehicle_id,
          vehicle_reg_number: item.GeofenceData.vehicle_reg_number,
          Geofence_name: item.GeofenceData.Geofence_name,
          geofence_id: item.GeofenceData.geofence_id,
        }));
        setLoaded(false);
        setTriggersData(formatedData1);
        setGeofenceData(formatedData2);
        // console.log("trigger", triggersData);
      })
      .catch(() => {
        setLoaded(false);
      });
  }, [token, user_uuid, refresh, anyChange]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getGeofenceData`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setGeoData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getInactiveDevices`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const formatedData = res.data.data.map((item, ind) => ({
          ...item,
          serialNo: ind + 1,
        }));
        setLoaded(false);
        setInacTriggersData(formatedData);
      })
      .catch(() => {
        setLoaded(false);
      });
  }, [trigger, refresh, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  const handleInacChange = (e) => {
    const { name, value } = e.target;
    setInacData({ ...inacData, [name]: value });
  };

  const openDialogc = () => {
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setConfirmDialog(true);
    } else {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Fill all the required fields",
        life: 3000,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/addAlertTrigger`,
        { ...addData },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        res.data?.validationNum > 0
          ? toastRef.current.show({
              severity: "warn",
              summary: "Failure",
              detail: `Selected ${res?.data?.validationNum} vehicles already have alert triggers!`,
              life: 3000,
            })
          : toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: `Trigger ${addData?.trigger_name} added successfully!`,
              life: 3000,
            });
        closeDialog();
        setRefresh(!refresh);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data?.message
            ? err.response.data?.message
            : "Fill all the required fields",
          life: 3000,
        });
        setConfirmDialog(false);
      });
  };

  const handleInacSubmit = (e) => {
    e.preventDefault();

    // Parse the vehicleData string into an array of objects
    const parsedVehicleData = inacData?.vehicleData?.map((vehicle) => {
      const [vehicle_id, vehicle_name] = vehicle.split("8u");
      return { vehicle_id, vehicle_name };
    });

    // Construct the request body with the formatted vehicleData
    const requestBody = {
      org_id: org_id,
      time: inacData.time,
      vehicleData: JSON.stringify(parsedVehicleData), // Corrected here
      whatsapp_number: inacData.whatsapp_number,
      inactive_status: inacData.inactive_status,
    };

    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/addInactiveDevices`,
        {
          ...requestBody,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        res.data.validationNum > 0
          ? toastRef.current.show({
              severity: "warn",
              summary: "Failure",
              detail: `Selected ${res.data.validationNum} vehicles already have alert triggers!`,
              life: 3000,
            })
          : toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail:
                "Inactive trigger for selected vehicles added successfully!",
              life: 3000,
            });
        closeDialog();
        setVisible(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data?.message
            ? err.response.data?.message
            : "Fill all the required fields",
          life: 3000,
        });
        closeDialog();
      });
  };

  //options
  const triggerOptions = [
    {
      label: "Limp Mode Trigger",
      value: "LMP",
    },
    {
      label: "Accident Trigger",
      value: "ACD",
    },
    {
      label: "Camera Tampering",
      value: "Camera_Tampering",
    },
    // {
    //   label: "Geofencing",
    //   value: "Geofencing",
    // },
    {
      label: "Inactivity Trigger",
      value: "Inactivity_trigger",
    },
  ];

  const modeOptions = [
    {
      label: "IN to OUT",
      value: 1,
    },
    {
      label: "OUT to IN",
      value: 2,
    },
    {
      label: "Both",
      value: 3,
    },
  ];

  const vehiclesOptions = () => {
    return vehiData?.map((el) => ({
      label: el.vehicle_name,
      value: el.vehicle_id,
    }));
  };

  const timeOptions = [
    {
      label: "6 hours",
      value: 6,
    },
    {
      label: "12 hours",
      value: 12,
    },
    {
      label: "24 hours",
      value: 24,
    },
    {
      label: "48 hours",
      value: 48,
    },
    {
      label: "72 hours",
      value: 72,
    },
  ];

  const vehiclesInacOptions = () => {
    return vehiData?.map((el) => ({
      label: el.vehicle_name,
      value: el.vehicle_id + "8u" + el.vehicle_name,
    }));
  };

  const contactsOptions = () => {
    let conatcts = contactsData?.filter(
      (item) => item.contact_type === "Phone"
    );
    return conatcts?.map((el) => ({
      label: el.contact_name,
      value: el.contact_info,
    }));
  };

  const stateOptions = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  const emailOptions = () => {
    let conatcts = contactsData?.filter(
      (item) => item.contact_type === "Email"
    );
    return conatcts?.map((el) => ({
      label: el.contact_name,
      value: el.contact_info,
    }));
  };

  const geofenceOptions = () => {
    let geofence = geoData?.filter((item) => item.geofence_status === "1");
    return geofence?.map((el) => ({
      label: el.name,
      value: el.geofence_id,
    }));
  };

  return (
    <>
      <Toast ref={toastRef} position="top-right" />
      <div>
        <h4 className="text-dark pt-3 text-xl font-bold dark:text-white">
          Alert Triggers
        </h4>
        {/* button to add vehicle */}
        <button
          className="mt-0.5 flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog}
        >
          <FiPlus />
          &nbsp; New Alert Trigger
        </button>

        {/* dialog for adding alert trigger */}
        <Dialog
          visible={visible}
          onHide={closeDialog}
          style={{ width: "40%", height: "fit-content" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Fill the details"
          modal
          className="p-fluid dark:bg-gray-900"
        >
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 xl:grid-cols-2">
            <div className="mt-6">
              <span className="p-float-label">
                <Dropdown
                  id="trigger_type"
                  name="trigger_type"
                  optionLabel="label"
                  optionValue="value"
                  options={triggerOptions}
                  onChange={handleChange}
                  className={`h-8 border ${
                    formErrors.trigger_type ? "border-red-600" : ""
                  }`}
                  value={addData?.trigger_type}
                />
                <label htmlFor="trigger_type" className="dark:text-gray-300">
                  Trigger Type
                </label>
              </span>
              {formErrors.trigger_type && (
                <small className="p-error">{formErrors.trigger_type}</small>
              )}
            </div>

            {addData?.trigger_type === "Inactivity_trigger" ? (
              <div>
                <span className="p-float-label mt-6">
                  <MultiSelect
                    id="vehicle_id"
                    name="vehicleData"
                    optionLabel="label"
                    optionValue="value"
                    options={vehiclesInacOptions()}
                    selectAllLabel="Select All Vehicles"
                    onChange={handleInacChange}
                    className={`h-8 border dark:bg-gray-900 ${
                      formErrors.vehicleData ? "border-red-600" : ""
                    }`}
                    value={inacData?.vehicleData}
                  />
                  <label htmlFor="vehicle_id" className="dark:text-gray-300">
                    Select Vehices
                  </label>
                </span>
                {formErrors.vehicle_id && (
                  <small className="p-error">{formErrors.vehicle_id}</small>
                )}
              </div>
            ) : (
              <div className="mt-6">
                <span className="p-float-label">
                  <MultiSelect
                    id="vehicle_uuid"
                    name="vehicle_id"
                    optionLabel="label"
                    optionValue="value"
                    options={vehiclesOptions()}
                    filter
                    onChange={handleChange}
                    className={`h-8 border dark:bg-gray-900 ${
                      formErrors.vehicle_id ? "border-red-600" : ""
                    }`}
                    value={addData?.vehicle_id}
                  />
                  <label htmlFor="vehicle_uuid" className="dark:text-gray-300">
                    Select Vehicles
                  </label>
                </span>
                {formErrors.vehicle_id && (
                  <small className="p-error">{formErrors.vehicle_id}</small>
                )}
              </div>
            )}
            {addData.trigger_type === "LMP" ||
            addData.trigger_type === "ACD" ||
            addData.trigger_type === "Camera_Tampering" ||
            addData.trigger_type === "Geofencing" ? (
              <>
                <div className="mt-4">
                  <span className="p-float-label">
                    <InputText
                      id="trigger_name"
                      name="trigger_name"
                      onChange={handleChange}
                      className={`border py-1.5 pl-2 text-sm ${
                        formErrors.trigger_name ? "border-red-600" : ""
                      }`}
                    />
                    <label
                      htmlFor="trigger_name"
                      className="dark:text-gray-300"
                    >
                      Trigger Name
                    </label>
                  </span>
                  {formErrors.trigger_name && (
                    <small className="p-error">{formErrors.trigger_name}</small>
                  )}
                </div>
                <div className="mt-4">
                  <span className={"p-float-label"}>
                    <InputText
                      id="trigger_description"
                      name="trigger_description"
                      onChange={handleChange}
                      className={`border py-1.5 pl-2 text-sm ${
                        formErrors.trigger_description ? "border-red-600" : ""
                      }`}
                    />
                    <label
                      htmlFor="trigger_description"
                      className="dark:text-gray-300"
                    >
                      Trigger Description
                    </label>
                  </span>
                  {formErrors.trigger_description && (
                    <small className="p-error">
                      {formErrors.trigger_description}
                    </small>
                  )}
                </div>
                <div className="mt-4">
                  <span className={"p-float-label"}>
                    <Dropdown
                      id="contact_id"
                      name="phone"
                      optionLabel="label"
                      optionValue="value"
                      options={contactsOptions() || []}
                      onChange={handleChange}
                      className={`h-8 border ${
                        formErrors.phone ? "border-red-600" : ""
                      }`}
                      value={addData?.phone}
                    />
                    <label htmlFor="contact_id" className="dark:text-gray-300">
                      Select Contact No.
                    </label>
                  </span>
                  {formErrors.phone && (
                    <small className="p-error">{formErrors.phone}</small>
                  )}
                </div>
                <div className="mt-4">
                  <span className={"p-float-label"}>
                    <Dropdown
                      id="contact_id"
                      name="email"
                      optionLabel="label"
                      optionValue="value"
                      options={emailOptions() || []}
                      onChange={handleChange}
                      className={`h-8 border ${
                        formErrors.email ? "border-red-600" : ""
                      }`}
                      value={addData?.email}
                    />
                    <label htmlFor="contact_id" className="dark:text-gray-300">
                      Select Email ID
                    </label>
                  </span>
                  {formErrors.email && (
                    <small className="p-error">{formErrors.email}</small>
                  )}
                </div>
                {addData?.trigger_type === "Geofencing" && (
                  <>
                    <div className="mt-4">
                      <span className="p-float-label">
                        <Dropdown
                          id="geofence"
                          name="geofence"
                          optionLabel="label"
                          optionValue="value"
                          options={geofenceOptions()}
                          onChange={handleChange}
                          className={`h-8 border`}
                          value={addData?.geofence}
                        />
                        <label
                          htmlFor="geofence"
                          className="dark:text-gray-300"
                        >
                          Geofence Route
                        </label>
                      </span>
                    </div>
                    <div className="mt-4">
                      <span className="p-float-label">
                        <Dropdown
                          id="mode"
                          name="trigger_sub"
                          optionLabel="label"
                          optionValue="value"
                          options={modeOptions}
                          onChange={handleChange}
                          className={`h-8 border`}
                          value={addData?.trigger_sub}
                        />
                        <label
                          htmlFor="geofence"
                          className="dark:text-gray-300"
                        >
                          Geofence Mode
                        </label>
                      </span>
                    </div>
                  </>
                )}
                <div></div>
                <div className="text-right">
                  <button
                    onClick={openDialogc}
                    className="rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600 "
                  >
                    Add Trigger
                  </button>
                </div>
              </>
            ) : addData.trigger_type === "Inactivity_trigger" ? (
              <>
                <div className="mt-4">
                  <span className={"p-float-label"}>
                    <InputText
                      id="whatsapp_number"
                      name="whatsapp_number"
                      onChange={handleInacChange}
                      className="border py-1.5 pl-2 text-sm"
                    />
                    <label
                      htmlFor="whatsapp_number"
                      className="dark:text-gray-300"
                    >
                      Whatsapp Number
                    </label>
                  </span>
                </div>
                <div className="mt-4">
                  <span className={"p-float-label"}>
                    <Dropdown
                      id="time"
                      name="time"
                      optionLabel="label"
                      optionValue="value"
                      options={timeOptions}
                      onChange={handleInacChange}
                      className={`h-8 border ${
                        formErrors.time ? "border-red-600" : ""
                      }`}
                      value={inacData?.time}
                    />
                    <label htmlFor="contact_id" className="dark:text-gray-300">
                      Time Interval
                    </label>
                  </span>
                  {formErrors.phone && (
                    <small className="p-error">{formErrors.phone}</small>
                  )}
                </div>
                <div className="mt-4">
                  <span className="p-float-label">
                    <Dropdown
                      id="inactive_status"
                      name="inactive_status"
                      options={stateOptions}
                      optionLabel="label"
                      optionValue="value"
                      className="p-dropdown h-8 border"
                      value={inacData?.inactive_status}
                      onChange={handleInacChange}
                    />
                    <label
                      htmlFor="inactive_status"
                      className="dark:text-gray-300"
                    >
                      Status
                    </label>
                  </span>
                </div>

                <div className="mt-12 text-right">
                  <button
                    onClick={handleInacSubmit}
                    className="ml-auto rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600 "
                  >
                    Add Trigger
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Dialog>
        <Dialog
          visible={confirmDialog}
          onHide={closeDialog}
          style={{ width: "40%", height: "fit-content" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Fill the details"
          modal
          className="p-fluid dark:bg-gray-900"
          footer={
            <div>
              <Button
                label="Create"
                className="mr-2 bg-green-500 px-2 py-1 text-xs text-white hover:bg-green-400 dark:hover:bg-green-500 dark:hover:text-white"
                onClick={handleSubmit}
              />
              <Button
                label="Cancel"
                className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
                onClick={closeDialog}
              />
            </div>
          }
        >
          <span className="text-sm font-semibold">
            Are you sure you want to create {addData?.trigger_name}?
          </span>
        </Dialog>
        <div>
          {loaded ? (
            <PreloaderList />
          ) : (
            <AlertTriggerList
              triggersData={triggersData}
              geofenceData={geofenceData}
              contactsData={contactsData}
              vehiData={vehiData}
              inacData={inacTriggersdata}
              setAnyChange={setAnyChange}
              anyChange={anyChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Triggers;
