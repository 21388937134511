import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar_admin";
import Footer from "components/footer/Footer";
import { routes_admin } from "routes.js";
import TopButton from "./scrolltop";
import Cookies from "js-cookie";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");
  const token = Cookies.get("token");

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 1200);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update current route based on pathname
  useEffect(() => {
    const activeRoute = routes_admin.find(
      (route) => location.pathname.indexOf(route.path) !== -1
    );
    if (activeRoute) {
      setCurrentRoute(activeRoute.name);
    }
  }, [location.pathname]);

  // Redirect if no token
  useEffect(() => {
    if (!token) {
      alert("You are logged out. Please sign in again..");
      window.location.href = `${process.env.REACT_APP_BASE_URL}/signin`;
    }
  }, [token]);

  // Set document direction
  useEffect(() => {
    document.documentElement.dir = "ltr";
  }, []);

  const getActiveNavbar = (routes) => {
    const activeRoute = routes.find(
      (route) => location.pathname.indexOf(route.path) !== -1
    );
    return activeRoute ? activeRoute.secondary : false;
  };

  const getRoutes = (routes_admin) =>
    routes_admin.map((prop) => (
      <Route path={`/${prop.path}`} element={prop.component} key={prop.path} />
    ));

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-Primary dark:!bg-navy-900">
        {/* Main Content */}
        <Navbar
          onOpenSidenav={() => setOpen(true)}
          brandText={currentRoute}
          secondary={getActiveNavbar(routes_admin)}
          {...rest}
        />
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[100px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <div className="mx-auto mb-auto h-full min-h-[84vh] p-2 pt-3 md:pr-2">
              <Routes>
                {getRoutes(routes_admin)}
                <Route
                  key="default"
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <TopButton />
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
